const zh_CN = {
  // Global
  g_siteName: "追踪物流",
  g_siteSlogan: "低价，准时，便捷",

  g_error: "错误",
  g_queueEmpty: "空伫列",
  g_cartEmpty: "空购物车",
  g_noTracking: "无追踪资讯",
  g_addToCart: "加入购物车",
  g_removeFromCart: "从购物车移除",
  g_generateCommercialInvoice: "商业发票",
  g_generatePackingInvoice: "包装清单",
  g_noData: "无资料",
  g_dateCreated: "创建日期",
  g_dateUpdated: "状况更新日期",
  g_fbaFee: "雅马逊物流费",
  g_currency: "币种",
  g_checkout: "结帐",
  g_checkout_again: "再次结帐",
  g_checkingOut: "结帐中",
  g_status: "状态",
  g_name: "名称",
  g_success: "确认",
  g_info: "资讯",
  g_danger: "错误",
  g_warning: "警告",
  g_days: "天",
  g_hours: "小时",
  g_minutes: "分钟",
  g_seconds: "秒",

  g_status_calculating: "计算中",
  g_status_incomplete: "资讯不完整",
  g_status_inactive: "未启动",
  g_status_active: "启动",
  g_status_new: "新建",
  g_status_working: "处理中",
  g_status_estimated: "已估价",
  g_status_confirmed: "已确认估价",
  g_status_voided: "已废止估价",
  g_status_downloaded: "已下载",
  g_status_unpaid: "尚未付款",
  g_status_paid: "已付款",
  g_status_shipped: "已寄送",
  g_status_inTransit: "运送中",
  g_status_delivered: "已送达",
  g_status_error: "错误",
  g_status_cancelled: "已取消",
  g_status_closed: "已关闭",
  g_status_deleted: "已删除",
  g_status_checkedIn: "已收货",
  g_status_receiving: "收货中",

  g_previousText: "上一页",
  g_nextText: "下一页",
  g_loadingText: "载入中",
  g_noDataText: "无资料",
  g_pageText: "页",
  g_ofText: "之",
  g_rowsText: "列",
  g_pageJumpText: "跳至页",
  g_rowsSelectorText: "每页列数",

  g_length: "长",
  g_width: "宽",
  g_height: "高",
  g_deep: "深",

  // Menu
  m_inventory: "库存物件",
  m_shipment: "寄送商品",
  m_order: "寄送订单",
  m_member: "会员专区",
  m_member_updateInfo: "更新个人资讯",
  m_member_provideAuthToken: "提供雅马逊授权码",
  m_member_paymentInfo: "付款资讯",
  m_language: "语言",
  m_language_english: "英文",
  m_language_traditionalChinese: "繁体中文",
  m_language_simplifiedChinese: "简体中文",
  m_language_japanese: "日文",
  m_language_korean: "韩文",
  m_login: "登入",
  m_logout: "登出",
  m_signup: "注册",
  m_syncAmazon: "与雅马逊同步",
  m_syncAmazonInventory: "雅马逊库存",
  m_syncAmazonShipment: "雅马逊货件",

  // Login/Logout/Signup
  l_email: "电子邮件",
  l_password: "密码",
  l_login: "登入",
  l_loggingIn: "登入中",
  l_confirmPassword: "确认密码",
  l_signup: "注册",
  l_signingUp: "注册中",
  l_signup_helpBlock: "请检查邮件以取得认证码",
  l_confirmationCode: "请输入认证码",
  l_verify: "认证",
  l_verifying: "认证中",
  l_firstName: "名字",
  l_lastName: "姓氏",
  l_phone: "电话或手机",

  l_error_invalidPasswordException: "密码不符合规格，密码必须有特殊字元。",
  l_error_usernameExistsException: "此邮件已经注册过。",
  l_error_userNotFoundException: "用户不存在。",
  l_error_notAuthorizedException: "用户或密码不正确",
  l_error_codeMismatchException: "确认码不正确，请重试。",
  l_error_dismiss: "确定",
  l_error_unknownError: "未知的错误",

  // Home
  hm_label_inventoryStatistics: "库存状态",
  hm_label_shipmentStatistics: "货件状态",
  hm_label_quantityStatistics: "出货状态",
  hm_label_quantityByMonthStatistics: "每月出货状态",
  hm_label_quantityByMonth: "每月出货数量",
  hm_label_quantity: "出货数量",
  hm_label_quantityReceived: "已收到",
  hm_label_quantityShipped: "已寄送",

  // Inventory
  inv_image: "图片",
  inv_sku: "SKU",
  inv_condition: "状况",
  inv_productName: "商品名称",
  inv_asin: "ASIN",
  inv_inStockQuantity: "可售",
  inv_totalQuantity: "总共",
  inv_feePreview: "费用预览",
  inv_price: "价格",
  inv_shipping: "配送费",
  inv_lowestPrice: "最低价",
  inv_includes: "包括",
  inv_createShipmentPlanfromSelectedItems: "创建发货单",
  inv_creatingShipmentPlanfromSelectedItems: "创建发货单",
  inv_itemsInQueue: "伫列中商品",
  inv_syncInventoryWithMWS: "与雅马逊库存同步",
  inv_syncingInventoryWithMWS: "与雅马逊库存同步中",
  inv_provideInformationForItemsToBeShipped: "请提供要寄送物品的资讯",
  inv_numberOfItems: "件数",
  inv_itemsPerBox: "每盒中件数",
  inv_itemsCondition: "物品状况",
  inv_addToQueue: "加入伫列",
  inv_reset: "重置",
  inv_addItemsBeforeCreatingShipmentPlan: "在创建发货单前请先加入物品到伫列",
  inv_createTooltip: "请先加入要寄送的商品",
  inv_amount: "总数",
  inv_condition_newItem: "新品",
  inv_condition_newWithWarranty: "保固新品",
  inv_condition_newOEM: "代工新品",
  inv_condition_newOpenBox: "开箱新品",
  inv_condition_usedLikeNew: "二手近新品",
  inv_condition_usedVeryGood: "二手状况很好",
  inv_condition_usedGood: "二手状况好",
  inv_condition_usedAcceptable: "二手状况可接受",
  inv_condition_usedPoor: "二手状况差",
  inv_condition_usedRefurbished: "二手翻新",
  inv_condition_collectibleLikeNew: "Collectible Like New",
  inv_condition_collectibleVeryGood: "收藏品状况很好",
  inv_condition_collectibleGood: "收藏品状况好",
  inv_condition_collectibleAcceptable: "收藏品状况可接受",
  inv_condition_collectiblePoor: "收藏品状况差",
  inv_condition_refurbishedWithWarranty: "保固翻新品",
  inv_condition_refurbished: "翻新品",
  inv_condition_club: "Club",

  // Shipment
  shp_shipmentId: "货件编号",
  shp_shipmentPlan: "入库计划",
  shp_msku: "MSKU",
  shp_shipping: "雅马逊寄送费", 
  shp_ourCharges: "我们的收费",
  shp_received: "已收到",
  shp_shipped: "已寄送",
  shp_destination: "目的地",
  shp_address: "地址",
  shp_itemsInCart: "购物车内容",
  shp_trackingInfo: "追踪货物资讯",
  shp_price: "价格",
  shp_total: "总额",
  shp_oilPrice: "燃油附加费",
  shp_boxUnit: "箱",
  shp_netWeight: "净重",
  shp_grossWeight: "毛重",
  shp_itemWeightUnit: "公斤",
  shp_estimateTooltip: "雅马逊费用预估大概会花<strong><font color='red'>50</font></strong>秒",
  shp_countdownTooltip_head: "您可以在",
  shp_countdownTooltip_tail: "内取消运送以避免被收费。",

  shp_provideCartonInformation: "请提供纸箱资讯",
  shp_shippingMethod: "寄送方法",
  shp_shippingCarrier: "货运公司",
  shp_shippingService: "寄送服务",
  shp_amazonPartneredCarrier: "与雅马逊合作的货运公司",
  shp_spd: "小包裹运送",
  shp_spd_desc: "我要寄送独立箱子",
  shp_ltl: "零担运送",
  shp_ltl_desc: "我要运送小于150磅的栈板",
  shp_ftl: "整车运送",
  shp_ftl_desc: "我要运送大于150磅的栈板",

  shp_ups: "UPS",
  shp_ups_desc: "优比速",
  shp_fedex: "FedEx",
  shp_fedex_desc: "联邦快递",
  shp_usps: "USPS",
  shp_usps_desc: "美国邮政署",
  shp_dhl: "DHL",
  shp_dhl_desc: "DHL",

  shp_srv_dhl: "DHL",
  shp_srv_dhl_desc: "最快速，大约花两天时间送达。",
  shp_srv_flight: "空运",
  shp_srv_flight_desc: "最划算，大约花三到五天送达。",
  shp_srv_ship: "海运",
  shp_srv_ship_desc: "最便宜，大约花三周送达。",

  shp_addBox: "加入纸箱",
  shp_estimateAmazonPrice: "1. 预估雅马逊费用",
  shp_estimatingAmazonPrice: "预估中",
  shp_confirmAmazonPrice: "2. 确认雅马逊费用",
  shp_confirmingAmazonPrice: "确认中",
  shp_voidAmazonPrice: "取消雅马逊费用",
  shp_voidingAmazonPrice: "取消中",
  shp_downloadShippingLabel: "3. 下载寄件标签",
  shp_downloadingShippingLabel: "下载中",

  shp_box: "纸箱",
  shp_cartonInfo: "纸箱资讯",
  shp_boxDimension: "纸箱长宽高",
  shp_boxWeight: "纸箱重量",
  shp_fomula: "可计价重量 x 单价 = 总价",
  shp_fomula2: "重量 x 单价 = 总价",
  shp_unit: "单位",
  shp_dimensionUnit: "长宽高单位",
  shp_weightUnit: "重量单位",
  shp_itemsInThisBox: "箱中的物品",
  shp_itemUnit: "商品单位",
  shp_addItemsToThisBox: "加入物品至箱中",
  shp_itemsInThisBox: "箱中物品",
  shp_perBox: "每箱",
  shp_amount: "总箱数",
  shp_addItems: "加入物品",
  shp_unitPrice: "单价",
  shp_totalPrice: "总价",

  shp_statusCancelled: "取消寄送",
  shp_statusShipped: "标为已寄送",
  shp_statusCancelledMarking: "取消中",
  shp_statusShippedMarking: "标示中",

  shp_syncShipmentWithMWS: "与雅马逊件同步",
  shp_syncingShipmentWithMWS: "与雅马逊件同步中",

  shp_error_invalidRequestException: "不合法的操作",
  shp_error_shipmentInLockedStatus: "货件在锁定状态",

  shp_tracking_time: "时间",
  shp_tracking_location: "地点",
  shp_tracking_description: "说明",
  shp_tracking_number: "追踪号码",
  shp_tracking_eta: "预计抵达时间",
  shp_tracking_destination: "目的地",

  // Invoice
  ivc_downloadInvoice:"下载商业发票", 
  ivc_downloadingInvoice:"生成商业发票中",

  // Packing List
  ivc_downloadPackingList:"下载包装清单", 
  ivc_downloadingPackingList:"生成包装清单中",

  // Order
  ord_status: "状态",
  ord_orderId: "订单号码",
  ord_contents: "内容",
  ord_grossChargeableWeight: "总重/可计价总重",
  ord_numOfPieces: "件数",
  ord_price: "总价",
  ord_hawbCarrier: "提单号码",
  ord_destination: "目的地",
  ord_senderInformation: "寄件者资讯",
  ord_hubInformation: "转运中心资讯",

  // Member
  mbr_save: "储存",
  mbr_saving: "储存中",
  mbr_reset: "重置",
  mbr_resetting: "重置中",
  mbr_generalInformation: "基本资讯",
  mbr_email: "帐号电子邮件",
  mbr_backupEmail: "备用电子邮件",
  mbr_password: "帐号密码",
  mbr_lineId: "Line帐号",
  mbr_wechatId: "微信帐号",
  mbr_companyInformation: "公司资讯",
  mbr_company: "公司名称",
  mbr_companyIdNumber: "统一编号",
  mbr_firstName: "负责人名",
  mbr_lastName: "负责人姓",
  mbr_cell: "手机号码",
  mbr_phone: "电话号码",
  mbr_fax: "传真号码",
  mbr_address: "公司地址",
  mbr_city: "城市",
  mbr_stateCode: "省份",
  mbr_zipCode: "邮递区号",
  mbr_countryCode: "国家",

  mbr_amazonMarketplaceAuthenticationToken: "雅马逊授权",
  mbr_sellerID: "卖家帐号",
  mbr_token: "授权码",
  mbr_noteTitle: "关于授权码",
  mbr_noteLine1: "雅马逊授权码必须每年更新，且这个步骤是必须的。请到卖家中心：",
  mbr_noteLine2: "设置 > 用户权限 > 授权某个开发人员",
  mbr_noteLine3: "来取得授权码。请用以下开发者讯息来申请授权码：",
  mbr_developerName: "开发商名称",
  mbr_developerID: "开发者ID",
  mbr_dismiss: "确定",
  mbr_close: "关闭",
  mbr_modalTitle: "授权码储存成功",
  mbr_modalLine1: "雅马逊卖家ID与授权码储存成功",
  mbr_modalLine2: "授权码必须每年更新。请用下面的开发者ID来申请授权码。",

  mbr_paymentInformation: "付款资讯",
  mbr_creditCard: "信用卡号",
  mbr_creditCardName: "信用卡名称",
  mbr_creditCardPostalCode: "信用卡邮递区号",
  mbr_creditCardPhone: "信用卡电话",

  // App
  app_updateGeneralInfo: "请更新基本资讯",
  app_updateMwsInfo: "请提供您的卖家ID和授权码",
  app_udpatePaymentInfo: "请提供付款资讯",

  app_error_userNotAuthenticated: "使用者尚未认证/登入",

  // Home
  hm_fixItNow: "现在就修正",

  // FrontPage
  fp_globalShippingTitle: "电商库存快速入库服务",
  fp_globalShippingContent: "我们的目标是帮助客户把商品快速的运送到电商的库存站点内，并且提供整合一站式的服务管理电商库存",
  fp_smartInventoryTitle: "智慧型库存管理",
  fp_smartInventoryContent: "我们可以同步雅马逊的库存，并且自动产生捕获要求，我们会帮你整合国际物流与雅马逊物流",
  fp_tryItOutTitle: "现在就试试？",
  fp_tryItOutContent: "如有任何问题欢迎与我们联络",
  fp_signUp: "立马注册",
  fp_login: "已有帐号？立马登入",
};

export default zh_CN;
