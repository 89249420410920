import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import checkboxHOC from "react-table/lib/hoc/selectTable";

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Row,
  Col,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
} from "react-bootstrap";

import { API } from "aws-amplify";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {
  checkNullS,
  checkNullN,
  getStaticData,
  getShipmentList,
  getInventoryList,
  deleteKeyShipment,
  updateShipment,
  getShipment,
  makeid,
} from "../utils";

import TextTruncate from 'react-text-truncate';

const CheckboxTable = checkboxHOC(ReactTable);

class CartonInfoTable extends Component {
  constructor(props) {
    super(props);
    console.log("prices:", this.props.prices);
    let dunit = "cm";
    let wunit = "kg";
    let unitSet = false;
    let cartons = this.props.entry.cartons;
    if (!unitSet && cartons && cartons.length > 0) {
      if (cartons[0]['dunit'] === "inches") {
        dunit = "in";
      }
      if (cartons[0]['wunit'] === "pounds") {
        wunit = "lb";
      }
      unitSet = true;
    }
    if (cartons && cartons.length > 0) {
      cartons.forEach(carton => {
        const oldPrice = this.calculatePrice(this.props.entry.service, carton);
      });
    }
    const items = this.props.entry.items;
    let addItems = {
      sku: items[0].sku,
      quantityShipped: 0,
      quantityInCase: 1,
      unitPrice: 0,
      totalPrice: 0,
      itemUnit: "pcs",
      currency: "USD",
      length: 0,
      width: 0,
      height: 0,
    };
    this.state = {
      entry: this.props.entry,
      inventoryMap: this.props.inventoryMap,
      dunit,
      wunit,
      addItems,
    };
    this.getCartonInfoColumns = this.getCartonInfoColumns.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.prices.service !== this.props.prices.service) {
      const entry = this.props.entry;
      let cartons = entry.cartons;
      if (cartons) {
        cartons.forEach(carton => {
          this.calculatePrice(entry.service, carton);
        });
        this.setState({ entry });
      }
    }
  }

  // Rendering
  getAddItemsCount = (row) => {
    const cartons = this.state.entry.cartons;
    if (cartons) {
      const carton = cartons[row.index];
      const items = carton.items;
      if (items) {
        return items.length;
      }
      return 0;
    } 
    return 0;
  }

  getAddedItemsList = (row) => {
    let o = [];
    const inventoryMap = this.state.inventoryMap;
    const cartons = this.state.entry.cartons;
    if (cartons) {
      const carton = cartons[row.index];
      const items = carton.items;
      if (items) {
        items.forEach((item, index) => {
          const entry = inventoryMap.get(item.sku);
          const header = `${item.sku} (${item.quantityInCase}/box) x ${item.quantityShipped} ${item.itemUnit}`;
          o.push(<ListGroupItem key={`item.sku.${index}`} header={header}><Row><Col sm={11}><TextTruncate line={1} truncateText="…" text={entry.productName} /></Col><Col sm={1}><Button id={`item.${row.index}.${index}`} bsStyle="danger" bsSize="xsmall" onClick={event => this.handleRemoveItemsClick(event, row, index)} style={{position: "absolute", bottom: 0}}><Glyphicon glyph="remove" aria-hidden="true" /></Button></Col></Row></ListGroupItem>);
        });
      }
    }
    return o;
  }

  getItemsSelectOption = (row) =>{
    const items = this.state.entry.items;
    const inventoryMap = this.state.inventoryMap;
    let o = [];
    items.forEach((item, index) => {
      const key = `${index}`;
      const entry = inventoryMap.get(item.sku);
      const productInfo = (entry && entry.productName) ? entry.productName : "";
      o.push(<option key={key} value={item.sku}>{item.sku}{productInfo}</option>);
    });
    return o;
  }

  showItemsProgress = () => {
    const entry = this.state.entry;
    const items = entry['items'];
    let o = [];
    let oo = [];
    let ooo = [];
    items.forEach((item, index) => {
      let shipped = 0;
      const cartons = entry['cartons'];
      if (cartons) {
        cartons.forEach((item2, index2) => {
          const cartonItems = item2['items'];
          if (cartonItems) {
            cartonItems.forEach(item3 => {
              if (item.sku === item3.sku) {
                shipped += parseInt(item3.quantityInCase, 10) * parseInt(item3.quantityShipped, 10);
              }
            });
          }
        });
      }
      const percentage = Math.round(shipped * 10000 / parseFloat(item.quantityShipped))/100;
      o.push(<Col key={item.sku} sm={4}><Label>{item.sku} x{item.quantityShipped}</Label><ProgressBar now={percentage} label={`${percentage}% (${shipped}/${item.quantityShipped})`} /></Col>);
    });
    if (items.length % 3 !== 0) {
      for (let i = items.length % 3; i < 3; i++) {
        o.push(<Col key={`${i}`} sm={4}></Col>);
      }
    }

    let append = [];
    o.forEach((item, index) => {
      append.push(item);
      if (index % 3 === 2) {
        oo.push(append);
        append = [];
      }
    });

    oo.forEach((item, index) => {
      if (index % 3 === 0) {
        ooo.push(<Row key={item} className="mb-4">{item}</Row>);
      }
    });
    return ooo;
  }

  // Handle Actions
  handleRemoveBoxClick = (event, row) => {
    let entry = this.state.entry;
    let cartons = entry['cartons'];
    const idx = parseInt(event.currentTarget.id.split("box")[1], 10);
    cartons.splice(idx, 1);
    let info = {
      cartons,
      estimate: 0,
      partneredEstimate: 0,
      transportEstimated: false,
    };
    entry.estimate = 0;
    entry.partneredEstimate = 0;
    entry.transportEstimated = false;
    updateShipment(entry.shipmentId, info);
    this.setState({ entry });
    this.props.updateCartonInfo(this.props.index, entry);
  }

  handleRemoveItemsClick = (event, row, index) => {
    let entry = this.state.entry;
    let cartons = entry['cartons'];
    let carton = cartons[row.index];
    let items = carton['items'];
    items.splice(index, 1);
    let info = {
      cartons,
      estimate: 0,
      partneredEstimate: 0,
      transportEstimated: false,
    };
    entry.estimate = 0;
    entry.partneredEstimate = 0;
    entry.transportEstimated = false;
    updateShipment(entry.shipmentId, info);
    this.setState({ entry });
    this.props.updateCartonInfo(this.props.index, entry);
  }

  calculatePrice = (type, carton) => {
    let cuft = 0.0;
    let cbm = 0.0;
    let volume = 0.0;
    if (carton.dunit === "centimeters") {
      cuft = parseFloat(carton['length']) * parseFloat(carton['width']) * parseFloat(carton['height']) * 0.0000353;
      volume = parseFloat(carton['length']) * parseFloat(carton['width']) * parseFloat(carton['height']) / 6000;
    } else {
      cuft = parseFloat(carton['length']) * parseFloat(carton['width']) * parseFloat(carton['height']) /1728;
      volume = parseFloat(carton['length']) * parseFloat(carton['width']) * parseFloat(carton['height']) / 366;
    }
    cbm = cuft / 35.315;
    cuft = Math.round(cuft * 10000) / 10000;
    cbm = Math.round(cbm * 10000) / 10000;
    volume = Math.round(volume * 10000) / 10000;
    carton['cuft'] = cuft;
    carton['cbm'] = cbm;
    carton['volume'] = volume;

    const minWeight = parseFloat(this.props.prices.minWeight);
    let weight = 0;
    let chargableUnit = "";
    let coefficient = carton['wunit'] === "pounds" ? 0.45359237 : 1;
    console.log("coefficient:", coefficient);
    if (type === "SHP") {
      // Use cbm
      weight = (carton['cbm'] >= parseFloat(carton['weight']) * coefficient) ? carton['cbm'] : parseFloat(carton['weight']) * coefficient;
      weight = weight >= minWeight ? weight : minWeight;
      weight = Math.ceil(weight);
      chargableUnit = weight >= minWeight ? "kg" : "cbm";
    } else {
      // Use volume or weight
      weight = (carton['volume'] >= parseFloat(carton['weight']) * coefficient) ? carton['volume'] : parseFloat(carton['weight']) * coefficient;
      chargableUnit = "kg";
    }

    let range = 0;
    let price = 0;
    const prices = this.props.prices.prices;
    for (let i = prices.length - 1; i >= 0; i--) {
      range = parseFloat(prices[i].range);
      price = parseFloat(prices[i].price);
      console.log(`${i} weight:${weight} ? range:${range}, price:${price}`);
      if (weight >= range) {
        break;
      }
    }
    const oldPrice = carton['price'];
    carton['chargableWeight'] = weight;
    carton['chargableUnit'] = chargableUnit;
    carton['unitPrice'] = price * parseFloat(this.props.exchangeRates.price);
    //carton['price'] = Math.round(weight * price * 100) / 100;
    carton['price'] = Math.floor(weight * price * parseFloat(this.props.exchangeRates.price));
    //console.log(`OLD: ${oldPrice} -> NEW: ${carton["price"]}`);
    return oldPrice;
  }

  handleCartonInfoChange = (event, row) => {
    const entry = this.state.entry;
    console.log("prices:", this.props.prices);
    let cartons = entry['cartons'];
    let info = {};
    if (cartons) {
      let carton = cartons[row.index];
      if (!carton) {
        carton = {};
        cartons[row.index] = carton;
      }
      carton[event.target.id] = event.target.value;
      const oldPrice = this.calculatePrice(entry.service, carton);
      entry.estimate = Math.round((entry.estimate - oldPrice + carton['price']) * 100) / 100;
      info = {
        estimate: entry.estimate,
        estimateCurrency: entry.estimateCurrency,
        cartons,
      }
    } else {
      let carton = {};
      carton[event.target.id] = event.target.value;
      cartons = [];
      cartons[row.index] = carton;
    }
    entry['cartons'] = cartons;
    entry['dateUpdated'] = Date.now();
    const info2 = {
      dateUpdated: entry.dateUpdated,
      cartons: entry.cartons,
      partneredEstimate: 0,
    };
    const updatedInfo = { ...info, ...info2 };
    updateShipment(entry.shipmentId, updatedInfo);
    entry.partneredEstimate = 0;
    entry.transportEstimated = false;
    this.setState({ entry });
    this.props.updateCartonInfo(this.props.index, entry);
  }

  handleBoxChange = (event, row) => {
    const addItems = this.state.addItems;
    if (event.target.name) {
      addItems[event.target.name] = event.target.value;
    } else {
      addItems[event.target.id] = event.target.value;
    }
    addItems['totalPrice'] = addItems['quantityInCase'] * addItems['quantityShipped'] * addItems['unitPrice'];
    this.setState({ addItems });

    let entry = this.state.entry;
    entry['dateUpdated'] = Date.now();
    const info = {
      dateUpdated: entry.dateUpdated,
    };
    updateShipment(entry.shipmentId, info);
    this.setState({ entry });
  }

  handleUnitChange = (event) => {
    const entry = this.state.entry;
    let cartons = entry.cartons;
    if (cartons) {
      cartons.forEach(carton => {
        if (event.target.name === "dunit") {
          if (event.target.value === "cm") {
            carton.dunit = "centimeters";
          } else {
            carton.dunit = "inches";
          }
        } else {
          if (event.target.value === "kg") {
            carton.wunit = "kilograms";
          } else {
            carton.wunit = "pounds";
          }
        }
        const oldPrice = this.calculatePrice(entry.service, carton);
        entry.estimate = Math.round((entry.estimate - oldPrice + carton['price']) * 100) / 100;
      });
      let info = {
        estimate: entry.estimate,
        estimateCurrency: entry.estimateCurrency,
        cartons: cartons,
        partneredEstimate: 0,
        transportEstimated: false,
      }
      updateShipment(entry.shipmentId, info);
      entry.partneredEstimate = 0;
      entry.transportEstimated = false;
      this.props.updateCartonInfo(this.props.index, entry);
    }
    this.setState({ [event.target.name]: event.target.value, entry });
    const info = {
      cartons: entry.cartons,
    };
    updateShipment(entry.shipmentId, info);
  }

  handleAddItems = (event, row) => {
    const addItems = this.state.addItems;
    const entry = this.state.entry;
    let cartons = entry['cartons'];
    const obj = {
      sku: addItems.sku,
      quantityShipped: parseInt(addItems.quantityShipped, 10),
      quantityInCase: parseInt(addItems.quantityInCase, 10),
      unitPrice: parseFloat(addItems.unitPrice),
      totalPrice: parseFloat(addItems.totalPrice),
      itemUnit: addItems.itemUnit,
      netWeight: parseFloat(addItems.netWeight),
      grossWeight: parseFloat(addItems.grossWeight),
      length: parseFloat(addItems.length),
      width: parseFloat(addItems.width),
      height: parseFloat(addItems.height),
    };
    if (cartons) {
      let carton = cartons[row.index];
      if (carton) {
        let items = carton.items;
        if (items) {
          let found = false;
          items.forEach(item => {
            if (item.sku === addItems.sku) {
              item = obj;
              found = true;
            }
          });
          if (!found) {
            items.push(obj);
          }
        } else {
          items = [obj];
          carton['items'] = items;
        }
      } else {
        carton = [];
        carton['items'] = [obj];
        cartons[row.index] = carton;
      }
    } else {
      const items = [obj];
      cartons = [{
        items: items,
      }];
      entry['cartons'] = cartons;
    }
    this.setState({ entry });
    const info = {
      cartons,
      partneredEstimate: 0,
      transportEstimated: false,
    };
    updateShipment(entry.shipmentId, info);
    entry.partneredEstimate = 0;
    entry.transportEstimated = false;
    this.props.updateCartonInfo(this.props.index, entry);
  }

  handleAddBoxClick = (event) => {
    const entry = this.state.entry;
    let cartons = entry['cartons'];
    if (!cartons) {
      cartons = [];
      entry['cartons'] = cartons;
    }
    cartons.push({
      length: 0.0,
      width: 0.0,
      height: 0.0,
      weight: 0.0,
      cuft: 0.0,
      cbm: 0.0,
      price: 0.0,
      dunit: this.state.dunit === "in" ? "inches" : "centimeters",
      wunit: this.state.wunit === "lb" ? "pounds" : "kilograms",
      items: [],
    });
    entry.dateUpdated = Date.now();

    const items = entry['items'];
    let addItems = this.state.addItems;
    addItems = {
      sku: items[0].sku,
      quantityShipped: 0,
      quantityInCase: 1,
      unitPrice: 0,
      totalPrice: 0,
      currency: "USD",
      itemUnit: "pcs",
      length: 0,
      width: 0,
      height: 0,
    };
    this.setState({ entry, addItems });
  }

  // Validation
  validateItemAmount = (row) => {
    const addItems = this.state.addItems;
    if (addItems.netWeight > 0 &&
        addItems.grossWeight > 0 &&
        addItems.quantityInCase > 0 &&
        addItems.quantityShipped > 0 &&
        addItems.unitPrice > 0 &&
        addItems.sku !== "") {
      return true;
    } else {
      return false;
    }
  }

  validateAddBox = () => {
    const entry = this.state.entry;
    const items = entry['items'];
    let total = 0;
    let totalShipped = 0;
    items.forEach((item, index) => {
      let shipped = 0;
      const cartons = entry['cartons'];
      if (cartons) {
        cartons.forEach((item2, index2) => {
          const cartonItems = item2['items'];
          if (cartonItems) {
            cartonItems.forEach(item3 => {
              if (item.sku === item3.sku) {
                shipped += parseInt(item3.quantityInCase, 10) * parseInt(item3.quantityShipped, 10);
              }
            });
          }
        });
      }
      totalShipped += shipped;
      total += parseInt(item.quantityShipped, 10);
    });
    if (totalShipped < total) {
      return true;
    } else {
      return false;
    }
  }

  // ReactTable
  getCartonInfoData = () => {
    return this.state.entry.cartons;
  }

  getCartonInfoColumns = () => {
    //<div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="shp_box" /></div>
    return [{
      Header: (row) => (
        <Button
          bsStyle="primary"
          disabled={!this.validateAddBox(row)}
          onClick={event => this.handleAddBoxClick(event, row)}
        ><FormattedMessage id="shp_addBox" /></Button>
      ),
      Cell: row => {
        const dunit = this.state.dunit;
        const wunit = this.state.wunit;
        return (
          <div>
            <div>
              <span
                style={{
                  padding: "10px",
                }}
              >#{row.index+1}</span>
              <Button id={"box"+row.index} bsStyle="danger" bsSize="xsmall" onClick={event => this.handleRemoveBoxClick(event, row)} style={{ margin: "8px" }}><Glyphicon glyph="remove" aria-hidden="true" /></Button>
            </div><br/>
            <div>
              <Form componentClass="fieldset" style={{ padding: "5px" }} inline>
                <ControlLabel><FormattedMessage id="shp_dimensionUnit" /></ControlLabel><br />
                <FormGroup controlId="dunit"style={{ padding: "5px 15px 5px 15px" }}>
                  <ButtonGroup bsSize="small">
                    <Button className={dunit === "cm" ? "active" : ""} name="dunit" value="cm" onClick={this.handleUnitChange}>cm</Button>
                    <Button className={dunit === "in" ? "active" : ""} name="dunit" value="in" onClick={this.handleUnitChange}>in</Button>
                  </ButtonGroup>
                </FormGroup><br />
                <ControlLabel><FormattedMessage id="shp_weightUnit" /></ControlLabel><br />
                <FormGroup style={{ padding: "5px 15px 5px 15px" }}>
                  <ButtonGroup bsSize="small">
                    <Button className={wunit === "kg" ? "active" : ""} name="wunit" value="kg" onClick={this.handleUnitChange}>kg</Button>
                    <Button className={wunit === "lb" ? "active" : ""} name="wunit" value="lb" onClick={this.handleUnitChange}>lb</Button>
                  </ButtonGroup>
                </FormGroup>
              </Form>
            </div>
          </div>
        );
      },
      accessor: "boxId",
      maxWidth: 100,
      minWidth: 100,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="shp_cartonInfo" /></div>
      ),
      Cell: row => {
        let item = this.state.entry;
        let cartons = item.cartons;
        if (cartons === undefined) {
          cartons = [];
        }
        let carton = cartons[row.index];
        if (carton === undefined) {
          carton = {
            dunit: "inches",
            length: 0.0,
            width: 0.0,
            height: 0.0,
            wunit: "pounds",
            weight: 0.0,
            cuft: 0.0,
            cbm: 0.0,
            price: 0.0,
            items: [],
          };
          cartons.push(carton);
        }
        return (
          <div>
            <Form componentClass="fieldset" style={{ padding: "5px" }} inline>
              <ControlLabel><FormattedMessage id="shp_boxDimension" /></ControlLabel><br />
              <FormGroup controlId="length" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl type="number" placeholder="Length" style={{ width: "100px" }} value={carton.length} onChange={event => this.handleCartonInfoChange(event, row)} />
                  <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>{' x '}
              <FormGroup controlId="width" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl type="number" placeholder="Width" style={{ width: "100px" }} value={carton.width} onChange={event => this.handleCartonInfoChange(event, row)} />
                  <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>{' x '}
              <FormGroup controlId="height" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl type="number" placeholder="Height" style={{ width: "100px" }} value={carton.height} onChange={event => this.handleCartonInfoChange(event, row)} />
                  <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                </InputGroup>
              </FormGroup><br />
              <ControlLabel><FormattedMessage id="shp_boxWeight" /></ControlLabel><br />
              <FormGroup controlId="weight" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl type="number" placeholder="Weight" style={{ width: "100px" }} value={carton.weight} onChange={event => this.handleCartonInfoChange(event, row)} />
                  <InputGroup.Addon>{this.state.wunit}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>{' '}
              <FormGroup controlId="volume" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl disabled type="number" placeholder="Volume" style={{ width: "100px" }} value={carton.volume} />
                  <InputGroup.Addon>vol</InputGroup.Addon>
                </InputGroup>
              </FormGroup>{' '}
              <FormGroup controlId="cbm" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl disabled type="number" placeholder="Cube Feet" style={{ width: "100px" }} value={carton.cbm} />
                  <InputGroup.Addon>cbm</InputGroup.Addon>
                </InputGroup>
              </FormGroup><br />
              <ControlLabel style={{ color: "red" }}><FormattedMessage id="shp_fomula" /></ControlLabel>
              <br/>
              <FormGroup controlId="cuft" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl disabled type="number" placeholder="Weight" style={{ width: "80px" }} value={carton.chargableWeight} />
                  <InputGroup.Addon>{carton.chargableUnit}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>
              {' x '}
              <FormGroup controlId="price" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl disabled type="number" placeholder="Price" style={{ width: "80px" }} value={carton.unitPrice} />
                  <InputGroup.Addon>{item.estimateCurrency}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>{' = '}
              <FormGroup controlId="estimate" style={{ padding: "5px 15px 5px 15px" }}>
                <InputGroup>
                  <FormControl disabled type="number" placeholder="Charge" style={{ width: "100px" }} value={carton.price} />
                  <InputGroup.Addon>{item.estimateCurrency}</InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Form>
          </div>
        );
      },
      accessor: "cartonInfo",
      maxWidth: 480,
      minWidth: 480,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="shp_itemsInThisBox" /></div>
      ),
      Cell: row => {
        const addItems = this.state.addItems;
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <Form>
              <Row style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Col sm={8}>
                  <FormGroup controlId="sku" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                    <ControlLabel><FormattedMessage id="shp_addItemsToThisBox" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        componentClass="select"
                        placeholder="Select Item"
                        value={addItems.sku}
                        onChange={(event) => this.handleBoxChange(event, row)}
                      >
                        {this.getItemsSelectOption(row)}
                      </FormControl>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <ControlLabel><FormattedMessage id="shp_itemUnit" /></ControlLabel><br />
                  <FormGroup style={{ padding: "5px 15px 5px 15px" }}>
                    <ButtonGroup bsSize="small">
                      <Button className={addItems.itemUnit === "pcs" ? "active" : ""} name="itemUnit" value="pcs" onClick={this.handleBoxChange}>pcs</Button>
                      <Button className={addItems.itemUnit === "sets" ? "active" : ""} name="itemUnit" value="sets" onClick={this.handleBoxChange}>sets</Button>
                    </ButtonGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup style={{ padding: "5px" }}>
                    <Button
                      style={{ margin: "30px 0px 0px 0px" }}
                      bsSize="small"
                      bsStyle="primary"
                      disabled={!this.validateItemAmount(row)}
                      onClick={(event) => this.handleAddItems(event, row)}
                    ><FormattedMessage id="shp_addItems" /></Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Col sm={2}>
                  <FormGroup controlId="length" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="g_length" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="length"
                        defaultValue={0}
                        value={addItems.length}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup controlId="width" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="g_width" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="width"
                        defaultValue={0}
                        value={addItems.width}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup controlId="height" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="g_height" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="N.W."
                        defaultValue={0}
                        value={addItems.height}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{this.state.dunit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup controlId="netWeight" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="shp_netWeight" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="N.W."
                        defaultValue={0}
                        value={addItems.netWeight}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{this.state.wunit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup controlId="grossWeight" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="shp_grossWeight" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="G.W."
                        defaultValue={0}
                        value={addItems.grossWeight}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{this.state.wunit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Col sm={2}>
                  <FormGroup controlId="quantityInCase" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="shp_perBox" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="per box"
                        defaultValue={0}
                        value={addItems.quantityInCase}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{addItems.itemUnit}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup controlId="quantityShipped" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="shp_amount" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="# of items"
                        defaultValue={0}
                        value={addItems.quantityShipped}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon><FormattedMessage id="shp_boxUnit" /></InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup controlId="unitPrice" style={{ paddingLeft: "5px", paddingRight: "5px", width: "100px" }}>
                    <ControlLabel><FormattedMessage id="shp_unitPrice" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="number"
                        style={{width: "80px"}}
                        placeholder="unit price"
                        defaultValue={0}
                        value={addItems.unitPrice}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{addItems.currency}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup controlId="totalPrice" style={{ paddingLeft: "5px", width: "120px" }}>
                    <ControlLabel><FormattedMessage id="shp_totalPrice" /></ControlLabel>
                    <InputGroup>
                      <FormControl
                        disabled
                        style={{width: "100px"}}
                        type="number"
                        placeholder="total price"
                        defaultValue={0}
                        value={addItems.totalPrice}
                        onChange={event => {this.handleBoxChange(event, row)}}
                      />
                      <InputGroup.Addon>{addItems.currency}</InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ padding: "5px" }}>
                <Col sm={11} style={{borderTop: "1px dashed #aaa", padding: "5px"}}>
                  <strong><FormattedMessage id="shp_itemsInThisBox" />: {this.getAddItemsCount(row)}</strong>
                </Col>
                <Col sm={11}>
                  <ListGroup style={{ padding: "5px" }}>
                    {this.getAddedItemsList(row)}
                  </ListGroup>
                </Col>
              </Row>
            </Form>
          </div>
        );
      },
      accessor: "boxId",
      minWidth: 820,
    }];
  }

  render() {
    return (
      <div>
         <div><h4><Label bsSize="large" bsStyle="default"><FormattedMessage id="shp_provideCartonInformation" /></Label></h4></div>
        {this.showItemsProgress()}
        <ReactTable
          columns={this.getCartonInfoColumns()}
          data={this.getCartonInfoData()}
          minRows={2}
          defaultPageSize={5}
          showPagination={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
};

// For formatMessage
CartonInfoTable.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CartonInfoTable);
