import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import checkboxHOC from "react-table/lib/hoc/selectTable";
import CurrencyFormat from 'react-currency-formatter';

import Moment from 'react-moment';
import 'moment-timezone';

import _ from "lodash";

import LoaderButton from "../components/LoaderButton";
import ErrorModal from "../components/ErrorModal";

import {
  Form,
  FormGroup,
  Button,
  Col,
  ControlLabel,
  FormControl,
  Label,
  HelpBlock,
  Tooltip,
  OverlayTrigger,
  Popover,
  Glyphicon,
} from "react-bootstrap";

import {
  retrieveInventory,
  getInventoryList,
  feeEstimate,
  getStaticData,
  createShipmentPlan,
  updateInventory,
} from "../utils";

import {
  STATUS_INVENTORY,
  COLOR_INVENTORY,
} from "../constants";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

const CheckboxTable = checkboxHOC(ReactTable);

class ListInventory extends Component {
  constructor(props) {
    super(props);
    //const data = this.getData();
    const columns = this.getColumns();
    this.state = {
      data: [],
      columns,
      selection: [],
      selectAll: false,
      isLoading: false,
      itemConditions: [],
      errorShow: false,
    };
    this.handleCreateShipmentPlanClick = this.handleCreateShipmentPlanClick.bind(this);
    this.handleRemoveItemFromQueueClick = this.handleRemoveItemFromQueueClick.bind(this);
    this.handleErrorDismiss = this.handleErrorDismiss.bind(this);
    this.handleErrorShow = this.handleErrorShow.bind(this);
    this.syncInventory = this.syncInventory.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      let [data, staticData] = await Promise.all([
        getInventoryList(),
        getStaticData(),
      ]);
      //let data = await getInventoryList();
      //let staticData = await getStaticData();
      let itemConditions = [];
      staticData.forEach((item) => {
        if (item.name === "ItemConditions") {
          itemConditions = item.data;
        }
      });
      const itemConditionMap = new Map(itemConditions.map(i => [i.id, i]));
      data = this.prepareData(data);
      const dataMap = new Map(data.map(i => [i.sku, i]));
      this.setState({ data, dataMap, itemConditions, itemConditionMap });
    } catch (e) {
      alert("Failed to retrieve data: " + e.message);
      console.log(e);
    };

    this.setState({ isLoading: false });
  }

  prepareData(entries) {
    return entries.map(item => {
      const _id = item.sku;
      let ret = {
        _id,
        ...item
      };
      if (!("quantity" in ret)) {
        ret["quantity"] = 0;
        ret["quantityInCase"] = 1;
      }
      return ret;
    });
  }

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  select = (key) => {
    let selection = [...this.state.selection];
    const len = [...this.state.data].length;
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
    } else {
      selection.push(key);
      this.setState({ selection });
      if (len === selection.length) {
        const selectAll = true;
        this.setState({ selectAll });
      }
    }
  }

  deselect = (key) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
      this.setState({ selection });
      if (selection.length === 0) {
        const selectAll = false;
        this.setState({ selectAll });
      }
    } else {
    }
  }

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected = key => {
    return this.state.selection.includes(key);
  }

  async handleCreateShipmentPlanClick() {
    const data = [...this.state.data];
    const selection = [...this.state.selection];
    let reqItems = [];
    data.forEach((item) => {
      if (selection.includes(item.sku)) {
        const obj = {
          sku: item.sku,
          itemCondition: item.itemCondition,
          quantity: item.quantity,
          quantityInCase: item.quantityInCase,
        }
        reqItems.push(obj);
      }
    });
    this.setState({ isLoading: true });
    await createShipmentPlan(reqItems).then(response => {
      this.setState({ isLoading: false });
      this.handleResetAll();
      this.props.history.push("/shipment/list");
    }).catch(error => {
      console.log("error:", error);
      this.setState({ isLoading: false });
      if (_.has(error, "response.data.error")) {
        const err = error.response.data.error;
        console.log("error:", err);
        this.handleErrorShow(err[0]);
      } else {
        this.handleErrorShow(error);
      }
    });
  }

  logSelection = () => {
    console.log("Selection:", this.state.selection);
  }

  async syncInventory() {
    this.setState({ isSyncing: true });
    const resp = await retrieveInventory();
    //console.log("RESP:", resp);
    let data = this.prepareData(resp);
    //console.log("DATA:", data);
    //console.log("DATA_:", this.state.data);
    /*
    data.forEach(entry => {
      const item = this.state.dataMap.get(entry.sku);
      if (item) {
        Object.assign(entry, item);
      }
    });
    */
    this.setState({ data, isSyncing: false });
  }

  getConditionSelectItems = (row) => {
    const {formatMessage} = this.props.intl;
    const a = [...this.state.itemConditions];
    let o = [];
    a.forEach((item, index, array) => {
      const key = `${row.index}.${index}`;
      let desc = `${formatMessage({ id: item.name })} - ${item.desc}`;
      if (item.desc === formatMessage({ id: item.name })) {
        desc = item.desc;
      }
      let disabled = true;
      if (item.id === this.state.data[row.index].itemCondition) {
        disabled = false;
      }
      o.push(<option key={key} id={item.id} name={item.name} value={item.id} disabled={disabled}>{desc}</option>);
    });
    return o;
  }

  createTooltip = () => {
    return (
      <Tooltip id="tooltip">
        <strong><FormattedMessage id="inv_createTooltip" /></strong>
      </Tooltip>
    );
  }

  validateCreateForm = () => {
    const selection = [...this.state.selection];
    if (selection.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validateItemsForm = (row) => {
    let data = [...this.state.data];
    let item = data[row.index];
    if (item.quantity > 0 && item.quantityInCase > 0) {
      return true;
    } else {
      return false;
    }
  }

  handleSubmit = (row, event) => {
    event.preventDefault();
    const item = [...this.state.data][row.index];
    this.select(item._id);
  }

  handleChange = (row, event) => {
    let data = [...this.state.data];
    let item = data[row.index];
    item[event.target.id] = event.target.value;
    if (event.target.id === "quantity" ||
        event.target.id === "quantityInCase") {
      item[event.target.id] = parseInt(event.target.value, 10);
    } else {
      item[event.target.id] = event.target.value;
    }
    this.setState({ data });
  }

  handleResetAll = () => {
    let data = [...this.state.data];
    const itemConditionMap = this.state.itemConditionMap;
    const selection = [];
    const selectAll = false;
    const reqItems = [];
    data.forEach((item) => {
      item["quantityShipped"] = 0;
      item["quantityInCase"] = 1;
    });
    console.log("data:", data);
    this.setState({ data, selection, selectAll, reqItems });
  }

  handleResetForm = (row) => {
    let data = [...this.state.data];
    let item = data[row.index];
    const itemConditionMap = this.state.itemConditionMap;
    item["quantity"] = 0;
    item["quantityInCase"] = 1;
    data[row.index] = item;
    this.setState({ data });
    this.deselect(item._id);
  }

  handleErrorDismiss() {
    this.setState({ errorShow: false, error: {} });
  }

  handleErrorShow(error) {
    this.setState({ errorShow: true, error });
  }

  renderCartonInfoInput(row) {
    const data = [...this.state.data][row.index];
    return (
      <div style={{ "paddingRight": "100px", "paddingLeft": "100px" }}>
        <Form horizontal onSubmit={(event) => this.handleSubmit(row, event)}>
          <FormGroup>
            <Col smOffset={2} sm={10}>
              <div><h4><Label bsSize="large" bsStyle="default"><FormattedMessage id="inv_provideInformationForItemsToBeShipped" /></Label></h4></div>
            </Col>
          </FormGroup>
          <FormGroup controlId="quantity">
            <Col componentClass={ControlLabel} sm={2}><FormattedMessage id="inv_numberOfItems" /></Col>
            <Col sm={10}>
              <FormControl
                type="number"
                placeholder="# of items"
                onChange={(event) => this.handleChange(row, event)} 
                value={data.quantity}
                defaultValue={0}
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="quantityInCase">
            <Col componentClass={ControlLabel} sm={2}><FormattedMessage id="inv_itemsPerBox" /></Col>
            <Col sm={10}>
              <FormControl
                type="number"
                placeholder="items / box"
                onChange={(event) => this.handleChange(row, event)} 
                value={data.quantityInCase}
                defaultValue={1}
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="itemCondition">
            <Col componentClass={ControlLabel} sm={2}><FormattedMessage id="inv_itemsCondition" /></Col>
            <Col sm={10}>
              <FormControl
                componentClass="select"
                placeholder="Items Condition"
                onChange={(event) => this.handleChange(row, event)}
                value={data.itemCondition}
              >
                {this.getConditionSelectItems(row)}
              </FormControl>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col smOffset={2} sm={10}>
              <Button
                bsSize="small"
                bsStyle="primary"
                disabled={!this.validateItemsForm(row)}
                type="submit"
              ><FormattedMessage id="inv_addToQueue" /></Button>
              <Button
                bsSize="small"
                bsStyle="danger"
                onClick={(event) => this.handleResetForm(row)}
              ><FormattedMessage id="inv_reset" /></Button>
              <HelpBlock><FormattedMessage id="inv_addItemsBeforeCreatingShipmentPlan" /></HelpBlock>
            </Col>
          </FormGroup>
        </Form>
        {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
      </div>
    );
  }

  renderTable() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, columns, selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (state, rowInfo) => {
        if (!rowInfo) {
          return {};
        }
        const selected = this.isSelected(rowInfo.original._id);
        return {
          style: {
            backgroundColor: selected ? "lightgreen" : "inherit",
          }
        };
      }
    };

    return (
      <div>
        <CheckboxTable
          ref={r => (this.checkboxTable = r)}
          data={this.props.isAuthenticated ? data : []}
          columns={columns}
          noDataText="No Data!"
          loadingText="Loading data..."
          defaultPageSize={10}
          minRows={2}
          className="-striped -highlight"
          collapseOnDataChange={false}
          defaultSorted={[
            {
              id: "dateCreated",
              desc: true,
            }
          ]}
          SubComponent={row => {
            return this.renderCartonInfoInput(row);
          }}
          {...checkboxProps}
        />
      </div>
    );
  }

  handleRemoveItemFromQueueClick = (event, row) => {
    const sku = row.value;
    this.deselect(sku);
  }

  showQueueItems = () => {
    const data = [...this.state.data];
    const selection = [...this.state.selection];
    let reqItems = [];
    data.forEach((item) => {
      if (selection.includes(item.sku)) {
        const obj = {
          sku: item.sku,
          itemCondition: item.itemCondition,
          quantity: item.quantity,
          quantityInCase: item.quantityInCase,
        }
        reqItems.push(obj);
      }
    });

    const { formatMessage } = this.props.intl;
    return (
      <Popover
        id="popover-trigger-hover-focus"
        title={formatMessage({ id: "inv_itemsInQueue" })}
        style={{ maxWidth: "650px" }}
      >
        <ReactTable
          columns={this.getQueueColumns()}
          data={reqItems}
          noDataText={formatMessage({ id: 'g_queueEmpty' })}
          minRows={2}
          defaultPageSize={5}
          showPagination={false}
          className="-striped -highlight"
        />
      </Popover>
    );
  }

  getQueueColumns = () => {
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><Glyphicon glyph="tag" aria-hidden="true" /></div>
      ),
      Cell: row => {
        return (
          <div>
            <Button id={"box"+row.index} bsStyle="danger" bsSize="xsmall" onClick={event => this.handleRemoveItemFromQueueClick(event, row)} style={{ margin: "2px" }}><Glyphicon glyph="remove" aria-hidden="true" /></Button>
          </div>
        );
      },
      id: "itemId",
      accessor: d => d.sku,
      maxWidth: 40,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="inv_sku" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "sku",
      accessor: d => d.sku,
      maxWidth: 150,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="inv_condition" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "itemCondition",
      accessor: d => d.itemCondition,
      maxWidth: 150,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="inv_itemsPerBox" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "quantityInCase",
      accessor: d => d.quantityInCase,
      maxWidth: 150,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="inv_amount" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "quantity",
      accessor: d => d.quantity,
      maxWidth: 150,
    }];
  }

  renderErrorAlert() {
    if (this.state.errorShow) {
      return (
        <ErrorModal
          error={this.state.error}
          handleErrorDismiss={this.handleErrorDismiss}
        />
      );
    }
  }

  async updateFeeEstimate(e, row, price) {
    const data = [...this.state.data];
    const item = data[row.index];
    let s = e.target.innerText.replace(/\$/g, '');
    s = s.replace(/,/g, '');
    item[row.column.id] = `${s}|${price}`;
    item['price'] = parseFloat(s);
    if (item['price'] <= 0) {
      return;
    }
    await feeEstimate([{
      sku: item['sku'],
      marketPlaceId: item['marketplaceId'],
      currencyCode: item['listPrice']['currencyCode'] || "USD",
      listingPrice: item['price'],
      shipping: item['shipping'] ? item['shipping'] : 0,
      pintsNumber: 0,
    }]).then(async (response) => {
      console.log("response:", response);
      item['fbaFeePreview'] = response[item.sku]['fbaFeePreview'];
      item['feePreview'] = response[item.sku]['feePreview'];
      item['dateUpdated'] = response[item.sku]['dateUpdated'];
      if ("available" in item && item['available'] > 0) {
        item['status'] = STATUS_INVENTORY.ACTIVE;
      } else {
        item['status'] = STATUS_INVENTORY.INACTIVE;
      }
      await updateInventory(item['sku'], {status: item['status']});
      this.setState({ data });
    }).catch((error) => {
      const err = error.response.data.error;
      console.log("err:", err);
    });
  }

  render() {
    const {formatMessage} = this.props.intl;
    return (
      <div>
        {this.renderErrorAlert()}
        {/*<OverlayTrigger placement="top" overlay={this.createTooltip()}>*/}
          <LoaderButton
            bsStyle="primary"
            onClick={this.handleCreateShipmentPlanClick}
            disabled={!this.validateCreateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text={formatMessage({ id: "inv_createShipmentPlanfromSelectedItems" })}
            loadingText={formatMessage({ id: "inv_creatingShipmentPlanfromSelectedItems" })}
          />
          {/*</OverlayTrigger>*/}
          {/*trigger={['hover', 'focus']}*/}
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          overlay={this.showQueueItems()}
        >
          <Button><FormattedMessage id="inv_itemsInQueue" /> ({this.state.selection.length})</Button>
        </OverlayTrigger>
        {/*
        <Button
          bsStyle="info"
          onClick={this.logSelection}
        >Log Selection</Button>
        */}
        <LoaderButton
          className="pull-right"
          bsStyle="success"
          onClick={this.syncInventory}
          isLoading={this.state.isSyncing}
          text={formatMessage({ id: "inv_syncInventoryWithMWS" })}
          loadingText={formatMessage({ id: "inv_syncingInventoryWithMWS" })}
        />
        {this.renderTable()}
      </div>
    );
  }

  getColumns = () => {
    const { formatMessage } = this.props.intl;
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="g_status" /></div>
      ),
      Cell: row => {
        const entry = [...this.state.data][row.index];
        return (
          <div
            style={{
              color: COLOR_INVENTORY[entry.status.toUpperCase()],
              padding: "5px",
            }}
          ><FormattedMessage id={`g_status_${entry.status}`} /></div>
        );
      },
      accessor: "status",
      maxWidth: 100,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="inv_image" /></div>
      ),
      Cell: (row) => (
        <div><img width={80} src={row.value} alt="Product"/></div>
      ),
      accessor: "image",
      minWidth:120,
      maxWidth: 120,
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="inv_sku" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="inv_condition" /></small></span>
        </div>
      ),
      Cell: (row) => {
        //sku_itemCondition: `${item.sku}|${formatMessage({ id: itemConditionMap.get(item.itemCondition)['name'] })}`,
        const entry = [...this.state.data][row.index];
        const itemConditionMap = this.state.itemConditionMap;
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span style={{"white-space": "pre-wrap"}}>{entry.sku}</span><br/>
            <span style={{ color: "#aeaeae" }}>{formatMessage({id:itemConditionMap.get(entry.itemCondition)['name']})}</span>
          </div>
        );
      },
      accessor: "sku",
      minWidth: 180,
      maxWidth: 180,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="inv_productName" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="inv_asin" /></small></span>
        </div>
      ),
      Cell: (row) => {
        // productName_asin: `${item.productName}|${item.asin}`
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{entry.productName || "--"}</span><br/>
            <span style={{ color: "#aeaeae" }}>{entry.asin || ""}</span>
          </div>
        );
      },
      style: {"whiteSpace": "unset"},
      accessor: "productName",
      width: 500,
      maxWidth: 600,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="g_dateCreated" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_dateUpdated" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index]; 
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateCreated, 10)/1000}</Moment></span><br/>
            <span style={{ color: "#aeaeae" }}><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateUpdated, 10)/1000}</Moment></span>
          </div>
        );
      },
      accessor: "dateCreated",
      width: 160,
      maxWidth: 160,
      sortable: true,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="inv_inStockQuantity" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="inv_totalQuantity" /></small></span>
        </div>
      ),
      Cell: row => {
        const entry = [...this.state.data][row.index];
        const inStockQuantity = isNaN(entry.inStockQuantity) ? 0 : parseInt(entry.inStockQuantity);
        const totalQuantity = isNaN(entry.totalQuantity) ? 0 : parseInt(entry.totalQuantity);
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span
              style={{
                color: inStockQuantity <= 3 ? "#fd3c33" : 
                       inStockQuantity <= 10 ? "#fece42" : "#4372e0",
                padding: "5px",
              }}
            >{inStockQuantity}</span><br/>
            <span style={{ color: "#aeaeae", padding: "5px" }}>{totalQuantity}</span>
          </div>
        );
      },
      accessor: "available",
      maxWidth: 90,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "right", "fontWeight": "bold" }}><FormattedMessage id="inv_feePreview" /></div>
      ),
      Cell: row => {
        // feePreview_fbaFeePreview: `${item.feePreview}|${item.fbaFeePreview}`
        const entry = [...this.state.data][row.index];
        if (entry.feePreview) {
          return (
            <div
              style={{
                color: "#4372e0",
                padding: "5px",
                "textAlign": "right",
              }}
            >
              <span><strong>${isNaN(entry.feePreview) ? "--" : entry.feePreview}</strong> <FormattedMessage id="inv_includes" /></span><br/>
              <span><small>${isNaN(entry.fbaFeePreview) ? "--" : entry.fbaFeePreview} <FormattedMessage id="g_fbaFee" /></small></span>
            </div>
          );
        } else {
          return (
            <div
              style={{
                color: "#4372e0",
                padding: "5px",
                "textAlign": "right",
              }}
            >
              <span>--</span><br/>
              <span style={{ color: "#aeaeae" }}>--</span>
            </div>
          );
        }
      },
      style: {"whiteSpace": "unset"},
      accessor: "feePreview",
      maxWidth: 150,
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "right", "fontWeight": "bold", color: "#0365c0"}}>
          <span><FormattedMessage id="inv_price" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small>+ <FormattedMessage id="inv_shipping" /></small></span>
        </div>
      ),
      Cell: row => {
        // price_shipping: `${item.price}|${item.shipping}`
        const entry = [...this.state.data][row.index];
        let price = entry.price || 0;
        let shipping = entry.shipping || 0;
        return (
          <div
            style={{
              color: "#4372e0",
              padding: "5px",
              "textAlign": "right",
            }}
          ><div
              style={{ backgroundColor: "#fafafa", width: "100%" }}
              contentEditable
              suppressContentEditableWarning
              onBlur={e => {this.updateFeeEstimate(e, row, shipping)}}
              onKeyPress={e => {
                if ((e.which >= 48 && e.which <= 57) || e.which === 46 || e.which === 13) {
                  if (e.which === 13) {
                    e.preventDefault();
                    e.target.blur();
                  } else if (e.target.innerText.length >= 10) {
                    e.preventDefault();
                  }
                  return true;
                } else {
                  e.preventDefault();
                  return false;
                }
              }}
            ><CurrencyFormat quantity={parseFloat(price)} currency="USD" /></div><div style={{ color: "#66666b" }}><small>+ <CurrencyFormat quantity={parseFloat(shipping)} currency="USD" /></small></div>
          </div>
        );
      },
      accessor: "price",
      maxWidth: 180,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "right", "fontWeight": "bold" }}>
          <span><FormattedMessage id="inv_lowestPrice" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small>+ <FormattedMessage id="inv_shipping" /></small></span>
        </div>
      ),
      Cell: row => {
        // lowestPrice_shipping: `${item.lowestPrice}|${item.lowestShipping}`,
        const entry = [...this.state.data][row.index];
        if (entry.lowestPrice) {
          return (
            <div
              style={{
                color: "#4372e0",
                padding: "5px",
                "textAlign": "right",
              }}
            ><CurrencyFormat quantity={parseFloat(entry.lowestPrice)} currency="USD" /><div style={{ color: "#66666b" }}><small>+ <CurrencyFormat quantity={parseFloat(entry.lowestShipping)} currency="USD" /></small></div>
            </div>
          );
        } else {
          return (
            <div
              style={{
                color: "#4372e0",
                padding: "5px",
                "textAlign": "right",
              }}
            >
              <span>--</span><br />
              <span style={{ color: "#aeaeae" }}>--</span>
            </div>
          );
        }
      },
      accessor: "lowestPrice",
      maxWidth: 180,
      sortable: false,
      filterable: false,
    }];
  }
  
  getData = () => {
    const data = [{
      status: "Inactive",
      image: "https://images-na.ssl-images-amazon.com/images/I/41Xp7F8dYQL._SS60_.jpg",
      sku: "PW-1911",
      itemCondition: "New",
      productName: "Precious Moments Ltd Ed Couple Sitting Under Lamp Post Figurine, 143027 …",
      asin: "B00PNM4NQW",
      dateCreated: "2017/10/11 21:41:00",
      statusChangedDate: "2017/10/11 21:41:00",
      available: 17,
      alert: true,
      feePreview: 27.66,
      fbaFeePreview: 10.41,
      price: 134.00,
      shipping: 12.45,
      lowestPrice: 110.12,
      lowestShipping: 1.23,
      actions: "Edit | Delete"
    }, {
      status: "Incomplete",
      image: "https://images-na.ssl-images-amazon.com/images/I/51pOF50C9IL._SS60_.jpg",
      sku: "PW-1911",
      itemCondition: "New",
      productName: "Precious Moments You Make My Heart Float Limited Edition Bisque Porcelain Sculpture, 154017 …",
      asin: "B0187CZCWS",
      dateCreated: "2017/10/11 21:41:00",
      statusChangedDate: "2017/10/11 21:41:00",
      available: 10,
      alert: true,
      feePreview: 27.66,
      fbaFeePreview: 10.41,
      price: 134.00,
      shipping: 12.45,
      lowestPrice: 110.12,
      lowestShipping: 1.23,
      actions: "Edit | Delete"
    }, {
      status: "Active",
      image: "https://images-na.ssl-images-amazon.com/images/I/41uJHifPMbL._SS60_.jpg",
      sku: "PW-1911",
      itemCondition: "New",
      productName: "Precious Moments, Just Married, Bisque Porcelain Figurine, 103018 …",
      asin: "B00462QYK8",
      dateCreated: "2017/10/11 21:41:00",
      statusChangedDate: "2017/10/11 21:41:00",
      available: 1,
      alert: true,
      feePreview: 27.66,
      fbaFeePreview: 10.41,
      price: 134.00,
      shipping: 12.45,
      lowestPrice: 110.12,
      lowestShipping: 1.23,
      actions: "Edit | Delete"
    }];
  
    return data.map(item => {
      //const _id = chance.guid();
      const _id = item.sku;
      return {
        _id,
        ...item
      }
    });
  }
};

// For formatMessage
ListInventory.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ListInventory);
