import React, { Component } from "react";

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Row,
  Col,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
  Modal,
} from "react-bootstrap";

import ShipmentInvoice from "./ShipmentInvoice";

import LoaderButton from "../components/LoaderButton";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {
  getProduct,
} from "../utils";;

class ShipmentInvoiceModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.pdfRef = React.createRef();

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.callback = this.callback.bind(this);

    this.state = {
      show: false,
      isGenerating: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  async handleShow() {
    this.setState({ show: true });
  }

  printInvoice = () => {
    this.setState({ isGenerating: true });
    this.refs.printInvoice.getWrappedInstance().printInvoice(this.callback);
  }

  callback = () => {
    this.setState({ isGenerating: false });
  }

  render() {
    const { entry, hubInfo, inventoryMap } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <span>
        <Button
          className="pull-right"
          bsStyle="primary"
          onClick={this.handleShow}>
          <FormattedMessage id="g_generateCommercialInvoice" />
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>Commerical Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoaderButton
              style={{ marginBottom: "10px", marginLeft: "37px" }}
              bsStyle="primary"
              bsSize="xsmall"
              onClick={this.printInvoice}
              isLoading={this.state.isGenerating}
              text={formatMessage({ id: "ivc_downloadInvoice" })}
              loadingText={formatMessage({ id: "ivc_downloadingInvoice" })}
            />
            <ShipmentInvoice ref="printInvoice" entry={this.props.entry} hubInfo={this.props.hubInfo} memberInfo={this.props.memberInfo} inventoryMap={this.props.inventoryMap} shippingService={this.props.shippingService}/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

// For formatMessage
ShipmentInvoiceModal.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ShipmentInvoiceModal);
