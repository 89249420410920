import { API } from "aws-amplify";
import React from 'react';
import _ from "lodash";

export function getElementsStartsWithId(id) {
  var children = document.body.getElementsByTagName('*');
  var elements = [], child;
  for (var i = 0, length = children.length; i < length; i++) {
    child = children[i];
    if (child.id.substr(0, id.length) == id)
      elements.push(child);
  }
  return elements;
}

export function partition(items, n) {
  var result = _.groupBy(items, function(item, i) {
    return Math.floor(i % n);
  });
  return _.values(result);
}

export function partitionN(items, n) {
  let arrays = [];
  while (items.length > 0)
    arrays.push(items.splice(0, n));
  return arrays;
}

export function checkNullN(obj) {
  return obj ? obj : 0;
}

export function checkNullS(obj) {
  return obj ? obj : "";
}

export function checkNullB(obj) {
  return obj ? obj : false;
}

export function makeid(len) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (var i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function base64toBlob(dataURI, callback) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab]);
  return bb;
}

export function keyValueSwap(obj) {
  return _.transform(obj, function(res, val, key) {
    if(_.isPlainObject(val)) {
      res[key] = keyValueSwap(val);
    } else {
      res[val] = key;
    }
  });
};

export async function update(tableName, info) {
  return API.put("update", `/update/${tableName}`, {
    body: info,
  });
};

export async function getStatisticsData() {
  return API.get("statistics", "/statistics");
};

export async function getStaticData() {
  return API.get("static", "/static");
}

export async function getShipmentList(filters) {
  let str = (filters && filters.length > 0) ? `?filters=${filters.join(",")}` : "";
  return API.get("shipment", `/shipment${str}`);
}

export async function getInventoryList() {
  return API.get("inventory", "/inventory");
}

export async function updateInventory(sku, info) {
  return API.put("update", `/update/inventory?key=sku&value=${sku}`, {
    body: info,
  });
  /*
  return API.put("inventory", `/inventory/${sku}`, {
    body: info,
  });
  */
}

export async function deleteKeyShipment(shipmentId, keys) {
  return API.put("shipmentDeleteKey", `/shipmentDeleteKey/${shipmentId}`, {
    body: keys,
  });
}

export async function updateShipment(shipmentId, info) {
  return API.put("update", `/update/shipment?key=shipmentId&value=${shipmentId}`, {
    body: info,
  });
  /*
  return API.put("shipment", `/shipment/${shipmentId}`, {
    body: info,
  });
  */
}

export async function getShipment(shipmentId) {
  return API.get("shipment", `/shipment/${shipmentId}`);
}

export async function getOrder(orderId) {
  return API.get("order", `/order/${orderId}`);
}

export async function getOrderList() {
  return API.get("order", "/order");
}

export async function updateOrder(orderId, info) {
  return API.put("update", `/update/order?key=poid&value=${orderId}`, {
    body: info,
  });
  /*
  return API.put("order", `/order/${orderId}`, {
    body: info,
  });
  */
}

export async function createOrder(body) {
  return API.post("order", `/order`, {
    body: body,
  });
}

export async function createCustomer(userId, info) {
  return API.post("customer", "/customer", {
    body: info,
  });
};

/*
export async function getMemberInfo(email) {
  return API.get("customerQuery", `/customerQuery/${email}`);
}

export async function getProduct(asin) {
  return API.get("productGet", `/productGet/${asin}`);
};
*/

export async function listMemberInfo() {
  return API.get("customer", "/customer");
}

export async function updateMemberInfo(email, body) {
  /*
  return API.put("customer", `/customer/${email}`, {
    body: body,
  });
  */
  return API.put("update", `/update/customer?key=email&value=${email}`, {
    body: body,
  });
}

export async function retrieveInventory() {
  return API.get("inventoryRetrieve", "/inventoryRetrieve");
}

export async function retrieveShipment() {
  return API.get("shipmentRetrieve", "/shipmentRetrieve");
}

export async function feeEstimate(req) {
  return API.post("inventoryFeeEstimate", "/inventoryFeeEstimate", {
    body: req
  });
}

export async function createShipmentPlan(req) {
  return API.post("shipmentPlan", "/shipmentPlan", {
    body: req
  });
}

export async function submitTransportEstimate(entry) {
  //return API.get("transportEstimaate", `/transportEstimate/${entry.shipmentId}`);
  return API.get("submitTransportEstimate", `/submitTransportEstimate/${entry.shipmentId}`);
}

export async function transportConfirm(entry) {
  return API.get("transportConfirm", `/transportConfirm/${entry.shipmentId}`);
}

export async function transportVoid(entry) {
  return API.get("transportVoid", `/transportVoid/${entry.shipmentId}`);
}

export async function transportGet(entry) {
  return API.get("transportGet", `/transportGet/${entry}`);
}

export async function shippingLabelGenerate(entry) {
  return API.get("transportGenPackageLabels", `/transportGenPackageLabels/${entry.shipmentId}`);
}

export async function shippingLabelGet(entry) {
  return API.get("transportGetPackageLabels", `/transportGetPackageLabels/${entry.shipmentId}`);
}
  
const a1 = ['company', 'companyIdNumber', 'firstName', 'lastName', 'address1', 'city', 'stateCode', 'postalCode', 'countryCode'];
const a2 = ['marketplaceToken', 'sellerId'];
const a3 = ['creditCard', 'creditCardName', 'creditCardNumber'];

export async function updateInboundShipment(shipmentId, info) {
  return API.put("shipment", `/shipment/${shipmentId}`, {
    body: info,
  });
};

export function checkMemberInfoRequired(info) {
  let a1n = a1.indexOf(info);
  let a2n = a2.indexOf(info);
  let a3n = a3.indexOf(info);
  if (a1n > -1 || a2n > -1 || a3n > -1) {
    return <small style={{ color: "red" }}>*</small>;
  }
  return "";
}

export function checkMemberInfoCompleted(memberInfo) {
  let a1b = true;
  a1.forEach((item) => {
    if (!(`${item}` in memberInfo)) {
      a1b = false;
    }
  });
  let a2b = true;
  a2.forEach((item) => {
    if (!(`${item}` in memberInfo)) {
      a2b = false;
    }
  });
  let a3b = true;
  a3.forEach((item) => {
    if (item !== "creditCard" && !(`${item}` in memberInfo)) {
      a3b = false;
    }
  });
  return {
    'app_updateGeneralInfo': a1b,
    'app_updateMwsInfo': a2b,
    'app_udpatePaymentInfo': a3b,
  };
}

