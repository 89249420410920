import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Button,
  Col,
  ControlLabel,
  FormControl,
  Panel,
  Modal,
  Alert,
  Table,
} from "react-bootstrap";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {
  listMemberInfo,
  updateMemberInfo,
  checkMemberInfoRequired,
} from "../utils";

class UpdateAuthToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      show: false,
      warningShow: true,
      memberInfo: {
        sellerId: "",
        marketplaceToken: "",
      },
    };
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.validateSave = this.validateSave.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let memberInfo = await listMemberInfo();
      memberInfo = memberInfo[0];
      this.setState({ memberInfo });
    } catch (e) {
      console.log(e.message);
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    const memberInfo = this.state.memberInfo;
    const body = {
      sellerId: memberInfo.sellerId,
      marketplaceToken: memberInfo.marketplaceToken,
    };
    await updateMemberInfo(memberInfo.email, body);
    this.handleShow();
  }

  async handleReset(event) {
    event.preventDefault();
    let memberInfo = await listMemberInfo();
    memberInfo = memberInfo[0];
    this.setState({ memberInfo });
  }

  handleChange = (event) => {
    //this.setState({
    //  [event.target.id]: event.target.value
    //});
    let memberInfo = this.state.memberInfo;
    memberInfo[event.target.id] = event.target.value;
    this.setState({ memberInfo });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleDismiss() {
    this.setState({ warningShow: false });
  }

  validateSave() {
    const memberInfo = this.state.memberInfo;
    if (("marketplaceToken" in memberInfo && memberInfo.marketplaceToken !== "") &&
        ("sellerId" in memberInfo && memberInfo.sellerId !== "")) {
      return true;
    }
    return false;
  }

  render() {
    const memberInfo = this.state.memberInfo;
    return (
      <div>
        <Form horizontal onSubmit={this.handleSubmit}>
          <Col xs={8} xsOffset={2}>
            <Panel>
              <Panel.Heading><FormattedMessage id="mbr_amazonMarketplaceAuthenticationToken" /></Panel.Heading>
              <Panel.Body>
                <FormGroup controlId="sellerId">
                  <Col componentClass={ControlLabel} xs={3}>
                    {checkMemberInfoRequired("sellerId")}<FormattedMessage id="mbr_sellerID" />
                  </Col>
                  <Col xs={8}>
                    <FormControl type="text" placeholder="Seller ID"
                      onChange={this.handleChange}
                      value={memberInfo.sellerId} />
                  </Col>
                </FormGroup>
                <FormGroup controlId="marketplaceToken">
                  <Col componentClass={ControlLabel} xs={3}>
                    {checkMemberInfoRequired("marketplaceToken")}<FormattedMessage id="mbr_token" />
                  </Col>
                  <Col xs={8}>
                    <FormControl type="text" placeholder="Marketplace Token"
                      onChange={this.handleChange}
                      value={memberInfo.marketplaceToken} />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col xsOffset={3} xs={8}>
                    <Button bsStyle="primary" type="submit"
                      disabled={!this.validateSave()}
                    >
                      <FormattedMessage id="mbr_save" />
                    </Button>
                    <Button bsStyle="danger" onClick={this.handleReset}><FormattedMessage id="mbr_reset" /></Button>
                  </Col>
                </FormGroup>
              </Panel.Body>
            </Panel>
          </Col>
          {this.state.warningShow ? 
            <Col xs={8} xsOffset={2}>
              <Alert bsStyle="warning" onDismiss={this.handleDismiss}>
                <h3><FormattedMessage id="mbr_noteTitle" /></h3>
                <p><FormattedMessage id="mbr_noteLine1" /></p>
                <p><strong><FormattedMessage id="mbr_noteLine2" /></strong></p>
                <p><FormattedMessage id="mbr_noteLine3" /></p>
                <br/>
                <Table responsive>
                  <thead>
                    <tr>
                      <th><FormattedMessage id="mbr_developerName" /></th>
                      <th><FormattedMessage id="mbr_developerID" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ivyliolia</td>
                      <td>5004-1106-7792</td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  <Button onClick={this.handleDismiss}><FormattedMessage id="mbr_dismiss" /></Button>
                </p>
              </Alert>
            </Col>
            : <div></div>
          }
        </Form>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><strong><FormattedMessage id="mbr_modalTitle" /></strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><FormattedMessage id="mbr_modalLine1" /></p>
            <h4><strong>{memberInfo.sellerId}</strong></h4>
            <h4><strong>{memberInfo.marketplaceToken}</strong></h4>
            <hr/>
            <p><FormattedMessage id="mbr_modalLine2" /></p>
            <strong>5004-1106-7792</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}><FormattedMessage id="mbr_close" /></Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

// For formatMessage
UpdateAuthToken.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(UpdateAuthToken);
