import React, { Component } from "react";

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Row,
  Col,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
  Modal,
} from "react-bootstrap";

import ShipmentPackingList from "./ShipmentPackingList";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {
  getProduct,
} from "../utils";;

class ShipmentPackingListModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  async handleShow() {
    this.setState({ show: true });
  }

  render() {
    const { entry, hubInfo, inventoryMap } = this.props;
    return (
      <span>
        <Button
          className="pull-right"
          bsStyle="primary"
          onClick={this.handleShow}>
          <FormattedMessage id="g_generatePackingList" />
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>Packing List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ShipmentPackingList entry={this.props.entry} hubInfo={this.props.hubInfo} memberInfo={this.props.memberInfo} inventoryMap={this.props.inventoryMap} shippingService={this.props.shippingService}/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

// For formatMessage
ShipmentPackingListModal.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ShipmentPackingListModal);
