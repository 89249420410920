const en_US = {
  // Global
  g_siteName: "Track It Shipped",
  g_siteSlogan: "Ship your packages worldwide in low cost.",

  g_error: "Error",
  g_queueEmpty: "Queue Empty",
  g_cartEmpty: "Cart Empty",
  g_noTracking: "No Tracking Info",
  g_addToCart: "Add to Cart",
  g_removeFromCart: "Remove from Cart",
  g_generateInvoice: "Commercial Invoice",
  g_generatePackingList: "Packing List",
  g_noData: "No Data",
  g_dateCreated: "Date Created",
  g_dateUpdated: "Date Updated",
  g_fbaFee: "FBA Fee",
  g_currency: "Currency",
  g_checkout: "Pay",
  g_checkout_again: "Pay Again",
  g_checkingOut: "Cheking Out...",
  g_status: "Status",
  g_name: "Name",
  g_success: "Success",
  g_info: "Info",
  g_danger: "Error",
  g_warning: "Warning",
  g_days: "Days",
  g_hours: "Hours",
  g_minutes: "Minutes",
  g_seconds: "Seconds",

  g_status_calculating: "Calculating",
  g_status_incomplete: "Incomplete",
  g_status_inactive: "Inactive",
  g_status_active: "Active",
  g_status_new: "New",
  g_status_working: "Working",
  g_status_estimated: "Estimated",
  g_status_confirmed: "Confirmed",
  g_status_voided: "Voided",
  g_status_downloaded: "Downloaded",
  g_status_unpaid: "Unpaid",
  g_status_paid: "Paid",
  g_status_shipped: "Shipped",
  g_status_inTransit: "In Transit",
  g_status_delivered: "Delivered",
  g_status_error: "Error",
  g_status_cancelled: "Cancelled",
  g_status_closed: "Closed",
  g_status_deleted: "Deleted",
  g_status_checkedIn: "Checked In",
  g_status_receiving: "Receiving",

  g_previousText: "Previous",
  g_nextText: "Next",
  g_loadingText: "Loading...",
  g_noDataText: "No rows found.",
  g_pageText: "Page",
  g_ofText: "of",
  g_rowsText: "rows",
  g_pageJumpText: "Jump to page",
  g_rowsSelectorText: "rows per page",

  g_length: "Length",
  g_width: "Width",
  g_height: "Height",
  g_deep: "Deep",

  // Menu
  m_inventory: "Your Inventory",
  m_shipment: "Your Shipment",
  m_order: "Your Order",
  m_member: "Member",
  m_member_updateInfo: "Update Info",
  m_member_provideAuthToken: "Provide Auth Token",
  m_member_paymentInfo: "Payment Info",
  m_language: "Language",
  m_language_english: "English",
  m_language_traditionalChinese: "Traditional Chinese",
  m_language_simplifiedChinese: "Simplified Chinese",
  m_language_japanese: "Japanese",
  m_language_korean: "Korean",
  m_login: "Login",
  m_logout: "Logout",
  m_signup: "Signup",
  m_syncAmazon: "Sync with Amazon",
  m_syncAmazonInventory: "Amazon Inventory",
  m_syncAmazonShipment: "Amazon Shipment",

  // Login/Logout/Signup
  l_email: "Email",
  l_password: "Password",
  l_login: "Login",
  l_loggingIn: "Logging in...",
  l_confirmPassword: "Confirm Password",
  l_signup: "Signup",
  l_signingUp: "Signing up...",
  l_signup_helpBlock: "Please check your email for the code.",
  l_confirmationCode: "Confirmation Code",
  l_verify: "Verify",
  l_verifying: "Verifying...",
  l_firstName: "First Name",
  l_lastName: "Last Name",
  l_phone: "Phone Number",

  l_error_invalidPasswordException: "Password did not conform with policy: Password must have symbol characters",
  l_error_usernameExistsException: "An account with the given email already exists.",
  l_error_userNotFoundException: "User does not exist.",
  l_error_notAuthorizedException: "Incorrect username or password.",
  l_error_codeMismatchException: "Invalid verification code provided, please try again.",
  l_error_dismiss: "Dismiss",
  l_error_unknownError: "Unknown",

  // Home
  hm_label_inventoryStatistics: "Inventory Status",
  hm_label_shipmentStatistics: "Shipment Status",
  hm_label_quantityStatistics: "Quantity Shipped",
  hm_label_quantityByMonthStatistics: "Quantity Shipped by Month",
  hm_label_quantityByMonth: "Shipment Quantity per Month",
  hm_label_quantity: "Shipment Quantity",
  hm_label_quantityReceived: "Received",
  hm_label_quantityShipped: "Shipped",

  // Inventory
  inv_image: "Image",
  inv_sku: "SKU",
  inv_condition: "Condition",
  inv_productName: "Product Name",
  inv_asin: "ASIN",
  inv_inStockQuantity: "Available",
  inv_totalQuantity: "Total",
  inv_feePreview: "Fee Preview",
  inv_price: "Price",
  inv_shipping: "Shipping",
  inv_lowestPrice: "Lowest Cost",
  inv_includes: "includes",
  inv_createShipmentPlanfromSelectedItems: "Create Shipment Plan from Selected Items",
  inv_creatingShipmentPlanfromSelectedItems: "創建發貨單中",
  inv_itemsInQueue: "Items in Queue",
  inv_syncInventoryWithMWS: "Sync Inventory with MWS",
  inv_syncingInventoryWithMWS: "Syncing Inventory with MWS",
  inv_provideInformationForItemsToBeShipped: "Provide information for items to be shipped",
  inv_numberOfItems: "Number of items",
  inv_itemsPerBox: "Items per box",
  inv_itemsCondition: "Items Condition",
  inv_addToQueue: "Add to Queue",
  inv_reset: "Reset",
  inv_addItemsBeforeCreatingShipmentPlan: "Add items before creating shipment plan.",
  inv_createTooltip: "Please add items to ship first.",
  inv_amount: "Amount",
  inv_condition_newItem: "New Item",
  inv_condition_newWithWarranty: "New With Warranty",
  inv_condition_newOEM: "New OEM",
  inv_condition_newOpenBox: "New Open Box",
  inv_condition_usedLikeNew: "Used Like New",
  inv_condition_usedVeryGood: "Used Very Good",
  inv_condition_usedGood: "Used Good",
  inv_condition_usedAcceptable: "Used Acceptable",
  inv_condition_usedPoor: "Used Poor",
  inv_condition_usedRefurbished: "Used Refurbished",
  inv_condition_collectibleLikeNew: "Collectible Like New",
  inv_condition_collectibleVeryGood: "Collectible Very Good",
  inv_condition_collectibleGood: "Collectible Good",
  inv_condition_collectibleAcceptable: "Collectible Acceptable",
  inv_condition_collectiblePoor: "Collectible Poor",
  inv_condition_refurbishedWithWarranty: "Refurbished With Warranty",
  inv_condition_refurbished: "Refurbished",
  inv_condition_club: "Club",

  // Shipment
  shp_shipmentId: "Shipment ID",
  shp_shipmentPlan: "Shipment Plan",
  shp_msku: "MSKU",
  shp_shipping: "Shipping", 
  shp_ourCharges: "Our Charges",
  shp_received: "Received",
  shp_shipped: "Shipped",
  shp_destination: "Destination",
  shp_address: "Address",
  shp_itemsInCart: "Items in Cart",
  shp_trackingInfo: "Tracking Information",
  shp_price: "Price",
  shp_total: "Total",
  shp_oilPrice: "Fuel Surcharge",
  shp_boxUnit: "Box",
  shp_netWeight: "Net Wight",
  shp_grossWeight: "Gross Weight",
  shp_itemWeightUnit: "KG",
  shp_estimateTooltip: "Price estimation will take around <strong><font color='red'>50</font></strong> seconds.",
  shp_countdownTooltip_head: "Cancel shipment in ",
  shp_countdownTooltip_tail: " to avoid charges.",

  shp_provideCartonInformation: "Provide carton information",
  shp_shippingMethod: "Shipping Method",
  shp_shippingCarrier: "Shipping Carrier",
  shp_shippingService: "Shipping Service",
  shp_amazonPartneredCarrier: "Amazon-Partnered Carrier",
  shp_spd: "Small Package",
  shp_spd_desc: "I'm shipping individual boxes",
  shp_ltl: "Less than Truck Load",
  shp_ltl_desc: "I'm shipping pallets; shipment at least 150 lb.",
  shp_ftl: "Full Truck Load",
  shp_ftl_desc: "I'm shipping pallets; shipment more than 150 lb.",

  shp_ups: "UPS",
  shp_ups_desc: "United Parcel Service Inc",
  shp_fedex: "Fedex",
  shp_fedex_desc: "Federal Express Corp",
  shp_usps: "USPS",
  shp_usps_desc: "United States Postal Service",
  shp_dhl: "DHL",
  shp_dhl_desc: "DHL Express USA Inc",

  shp_srv_dhl: "DHL",
  shp_srv_dhl_desc: "Fastest, takes about 2 days.",
  shp_srv_flight: "Flight Service",
  shp_srv_flight_desc: "Most Cost Effective, takes about 3 to 5 days.",
  shp_srv_ship: "Ship Service",
  shp_srv_ship_desc: "Cheapest, takes about 3 weeks.",

  shp_addBox: "Add Box",
  shp_estimateAmazonPrice: "1. Estimate Amazon Price",
  shp_estimatingAmazonPrice: "Estimating...",
  shp_confirmAmazonPrice: "2. Confirm Amazon Price",
  shp_confirmingAmazonPrice: "Confirming...",
  shp_voidAmazonPrice: "Void Amazon Price",
  shp_voidingAmazonPrice: "Voiding...",
  shp_downloadShippingLabel: "3. Download Shipping Label",
  shp_downloadingShippingLabel: "Downloading...",

  shp_box: "Box",
  shp_cartonInfo: "Carton Info",
  shp_boxDimension: "Box Dimension",
  shp_boxWeight: "Box Weight",
  shp_fomula: "Chargable Weight x Price = Total Charges",
  shp_fomula2: "Weight x Price = Total Charges",
  shp_unit: "Unit",
  shp_dimensionUnit: "Dimension",
  shp_weightUnit: "Weight",
  shp_itemUnit: "Item",
  shp_itemsInThisBox: "Items in this box",
  shp_addItemsToThisBox: "Add items to this box",
  shp_itemsInThisBox: "Items in this box",
  shp_perBox: "Per Box",
  shp_amount: "Amount",
  shp_addItems: "Add Items",
  shp_unitPrice: "Unit Price",
  shp_totalPrice: "Total Price",

  shp_statusCancelled: "Cancelled",
  shp_statusShipped: "Mark as Shipped",
  shp_statusCancelledMarking: "Cancelling",
  shp_statusShippedMarking: "Marking",

  shp_syncShipmentWithMWS: "Sync with Amazon Shipments",
  shp_syncingShipmentWithMWS: "Syncing with Amazon Shipments",

  shp_error_invalidRequestException: "Invalid Request Exception",
  shp_error_shipmentInLockedStatus: "Shipment is in locked status",

  shp_tracking_time: "Time",
  shp_tracking_location: "Location",
  shp_tracking_description: "Description",
  shp_tracking_number: "Tracking #",
  shp_tracking_eta: "ETA",
  shp_tracking_destination: "Destination",

  // Invoice
  ivc_downloadInvoice:"Download Invoice",
  ivc_downloadingInvoice:"Generating Invoice...",

  // Packing List
  ivc_downloadPackingList:"Download Packing List",
  ivc_downloadingPackingList:"Generating Packing List...",

  // Order
  ord_status: "Status",
  ord_orderId: "Order ID",
  ord_contents: "Contents",
  ord_grossChargeableWeight: "Gross/Chargable Weight",
  ord_numOfPieces: "Number of Pieces",
  ord_price: "Price",
  ord_hawbCarrier: "HAWB/Carrier",
  ord_destination: "Destination",
  ord_senderInformation: "Sender Information",
  ord_hubInformation: "Hub Information",

  // Member
  mbr_save: "Save",
  mbr_saving: "Saving...",
  mbr_reset: "Reset",
  mbr_resetting: "Resetting...",
  mbr_generalInformation: "General Information",
  mbr_email: "Email",
  mbr_backupEmail: "Backup Email",
  mbr_password: "Password",
  mbr_lineId: "Line ID",
  mbr_wechatId: "WeChat ID",
  mbr_companyInformation: "Company Information",
  mbr_company: "Company",
  mbr_companyIdNumber: "Company ID Number",
  mbr_firstName: "First Name",
  mbr_lastName: "Last Name",
  mbr_cell: "Cell",
  mbr_phone: "Phone",
  mbr_fax: "Fax",
  mbr_address: "Address",
  mbr_city: "City",
  mbr_stateCode: "State / Province",
  mbr_zipCode: "Zip Code",
  mbr_countryCode: "Country",

  mbr_amazonMarketplaceAuthenticationToken: "Amazon Marketplace Authentication Token",
  mbr_sellerID: "Seller ID",
  mbr_token: "Token",
  mbr_noteTitle: "Note about Authorization Token",
  mbr_noteLine1: "Amazon Marketplace Authorization Token needs to be renewed every year, and this step is mandatory. Please go to seller central:",
  mbr_noteLine2: "Settings > User Permissions > Authorize a Developer",
  mbr_noteLine3: "to request the new token. Please use following developer ID to request the token:",
  mbr_developerName: "Developer Name",
  mbr_developerID: "Developer ID",
  mbr_dismiss: "Dismiss",
  mbr_close: "Close",
  mbr_modalTitle: "Auth Token Saved!",
  mbr_modalLine1: "Amazon Marketplace seller ID and authorization token saved.",
  mbr_modalLine2: "Token needs to be renewed every year. Please use developer ID below to request the authorization token.",

  mbr_paymentInformation: "Payment Information",
  mbr_creditCard: "Credit Card",
  mbr_creditCardName: "Name",
  mbr_creditCardPostalCode: "Postal Code",
  mbr_creditCardPhone: "Phone",

  // App
  app_updateGeneralInfo: "Please update general information.",
  app_updateMwsInfo: "Please provide seller ID and authorized token.",
  app_udpatePaymentInfo: "Please provide payment information.",

  app_error_userNotAuthenticated: "Error: User is not authenticated.",

  // Home
  hm_fixItNow: "Fix It Now",

  // FrontPage
  fp_globalShippingTitle: "Rapid Inventory Replenishment Service",
  fp_globalShippingContent: "Our goal is to fulfill your needs on shipping packages to inventory hub of global e-commerce sites, and provide integrated service to manage inventory.",
  fp_smartInventoryTitle: "Smart Inventory Management",
  fp_smartInventoryContent: "We can sync Amazon inventory, and create replenishments. We will integrate the international shipments with Amazon shipments.",
  fp_tryItOutTitle: "Try it out NOW!",
  fp_tryItOutContent: "If you have any question, please contact us.",
  fp_signUp: "Sign Up Now!",
  fp_login: "Have an account? Login Now",
};

export default en_US;

