import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import checkboxHOC from "react-table/lib/hoc/selectTable";
import CurrencyFormat from 'react-currency-formatter';

import Moment from 'react-moment';
import 'moment-timezone';

import LoaderButton from "../components/LoaderButton";
import ErrorModal from "../components/ErrorModal";
import CartonInfoTable from "./CartonInfoTable";
import Countdown from "../components/Countdown";
import ShipmentInvoiceModal from "./ShipmentInvoiceModal";
import ShipmentPackingListModal from "./ShipmentPackingListModal";

import FileDownload from "js-file-download";

import Select from 'react-select';

import {
  UpsClient,
} from "shipit";

import _ from "lodash";

import 'react-notifications/lib/notifications.css';

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
  Grid,
  Row,
  Col,
} from "react-bootstrap";

import { API } from "aws-amplify";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import opay_payment from "opay_payment_nodejs";

import Parser from 'html-react-parser';

import DateFormat from "dateformat";

import {
  checkNullS,
  checkNullN,
  getStaticData,
  getShipmentList,
  getInventoryList,
  deleteKeyShipment,
  updateShipment,
  getShipment,
  listMemberInfo,
  updateMemberInfo,
  transportGet,
  createOrder,
  shippingLabelGet,
  shippingLabelGenerate,
  transportVoid,
  transportConfirm,
  submitTransportEstimate,
  getProduct,
  updateInboundShipment,
  retrieveShipment,
  makeid,
} from "../utils";

import {
  STATUS_SHIPMENT,
  COLOR_SHIPMENT,
  STATUS_ORDER,
} from "../constants";

const CheckboxTable = checkboxHOC(ReactTable);

const seconds = (s) => s * 1000;

class ListShipment extends Component {
  static estimateTransportCheckInterval = seconds(5);
  static estimateTransportCheckMaxRetries = 10;

  constructor(props) {
    super(props);
    //const data = this.getData();
    const columns = this.getColumns();
    this.state = {
      data: [],
      columns,
      selection: [],
      selectAll: false,
      isLoading: false,
      shippingMethods: [],
      amazonPartneredCarriers: [],
      shippingServices: [],
      price: 0.0,
      amazonCurrency: "USD",
      tisCurrency: "NTD",
      tisTotalPrice: 0,
      isEstimating: [],
      errorShow: false,
      html: "",
      isShipmentShippedLoading: false,
      isShipmentCancelledLoading: false,
      isSyncing: false,
      selectedFilterOptions: [],
      filterOptions: [],
    };
    this.intervalRefs = [];

    this.checkEstimateStatus = this.checkEstimateStatus.bind(this);
    this.initializeTimer = this.initializeTimer.bind(this);
    this.cancelTimer = this.cancelTimer.bind(this);

    this.handleErrorDismiss = this.handleErrorDismiss.bind(this);
    this.handleErrorShow = this.handleErrorShow.bind(this);

    this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
    this.handleShipmentShippedClick = this.handleShipmentShippedClick.bind(this);
    this.handleShipmentCancelledClick = this.handleShipmentCancelledClick.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const { formatMessage } = this.props.intl;
    try {
      let memberInfo = await listMemberInfo();
      memberInfo = memberInfo[0];
      let selectedFilterOptions = [];
      let shipmentFilter = memberInfo['shipmentFilter'] || [];
      shipmentFilter.forEach(filter => {
        selectedFilterOptions.push({
          value: filter,
          label: formatMessage({id: `g_status_${filter}`}),
        });
      });

      const filterOptions = this.getFilterOptions();
      let [data, inventory, staticData] = await Promise.all([
        getShipmentList(shipmentFilter),
        getInventoryList(),
        getStaticData(),
      ]);
      
      //let data = await getShipmentList(shipmentFilter);
      //let inventory = await getInventoryList();
      //let staticData = await getStaticData();
      let dunit = "cm";
      let wunit = "kg";
      let unitSet = false;
      let shippingMethods = [];
      let amazonPartneredCarriers = [];
      let shippingServices = [];
      let hubInfo = [];
      let oilPrices = {};
      let exchangeRates = {};
      let prices = [];
      let amazonHubInfo = [];
      const priceMap = {
        "DHL": "DHL",
        "Flight": "FLT",
        "Ship": "SHP",
      };
      let price = 0.0;
      staticData.forEach(item => {
        if (item.name === "AmazonPartneredCarrier") {
          amazonPartneredCarriers = item.data;
        } else if (item.name === "ShippingMethods") {
          shippingMethods = item.data;
        } else if (item.name === "ShippingServices") {
          shippingServices = item.data;
        } else if (item.name === "HubInfo") {
          hubInfo = item.data;
        } else if (item.name === "OilPrice") {
          oilPrices = item.data;
          oilPrices = oilPrices[oilPrices.length - 1];
        } else if (item.name === "ExchangeRate") {
          exchangeRates = item.data;
          exchangeRates = exchangeRates[exchangeRates.length - 1];
        } else if (item.name === "AmazonFulfillmentCenterInfo") {
          amazonHubInfo = item.data;
        } else if (item.name === "Price") {
          const prices = item.data;
          if (prices) {
            price = parseFloat(prices[prices.length-1]['price']);
          }
        } else if (item.name.startsWith("Pricing")) {
          const [tag, type] = item.name.split("-");
          const data = item.data;
          if (data) {
            prices[priceMap[type]] = data[data.length-1];
          }
        }
      });
      const oilSurcharge = Math.round(parseFloat(oilPrices.price) * parseFloat(exchangeRates.price));
      const inventoryMap = new Map(inventory.map(i => [i.sku, i]));
      const addItems = [];
      const isEstimating = [];
      const isConfirming = [];
      const isVoiding = [];
      const isDownloadingShippingLabel = [];
      let shipmentIds = [];
      data = data.map(item => {
        if ("transportLabelsGenerated" in item &&
            item.transportLabelsGenerated) {
          if (_.has(item, "transportDetail.packageList.packageStatus") &&
              item.transportDetail.packageList.packageStatus === "DELIVERED") {
          } else {
            shipmentIds.push(item.shipmentId);
          }
        }
        let totalCharges = 0.0;
        const _id = item.shipmentId;
        /*
        if (!item['name']) {
          item['name'] = `Shipment for ${item.shipmentId}`;
        }
        */
        if (!('estimate' in item)) {
          item['estimate'] = this.state.tisTotalPrice;
        }
        if (!('estimateCurrency' in item)) {
          item['estimateCurrency'] = this.state.tisCurrency;
        }
        if (!('shipped' in item)) {
          item['shipped'] = 0;
        }
        if (!('received' in item)) {
          item['received'] = 0;
        }
        if (item.items) {
          item.items.forEach(entry => {
            item['shipped'] += parseInt(entry.quantityShipped);
            item['received'] += parseInt(entry.quantityReceived);
          });
        }
        let cartons = item.cartons;
        if (!unitSet && cartons && cartons.length > 0) {
          if (cartons[0]['dunit'] === "inches") {
            dunit = "in";
          }
          if (cartons[0]['wunit'] === "pounds") {
            wunit = "lb";
          }
          unitSet = true;
        }
        isEstimating.push(false);
        isConfirming.push(false);
        isVoiding.push(false);
        isDownloadingShippingLabel.push(false);
        this.intervalRefs.push(null);
        return {
          _id,
          ...item
        }
      });
      if (shipmentIds && shipmentIds.length > 0) {
        const resp = await transportGet(shipmentIds.join(","));
        data.map(item => {
          const entry = resp[item.shipmentId];
          if (entry) {
            item = {...item, ...entry};
          }
        });
      }
      const dataMap = new Map(data.map(i => [i.shipmentId, i]));
      const amazonHubInfoMap = new Map(amazonHubInfo.map(i => [i.id, i]));
      this.setState({ data, dataMap, addItems, isEstimating, isConfirming, isVoiding, isDownloadingShippingLabel, inventoryMap, shippingMethods, shippingServices, amazonPartneredCarriers, dunit, wunit, price, hubInfo, oilPrices, exchangeRates, prices, memberInfo, amazonHubInfo, amazonHubInfoMap, selectedFilterOptions, filterOptions, oilSurcharge });
    } catch (e) {
      alert("Failed to retrieve data: " + e.message);
      console.log(e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { html } = this.state;
    if (html && html !== "") {
      let form = document.getElementById("_form_aiochk");
      form.submit();
    }
  }

  static counter = 0;

  checkEstimateStatus = async (index, shipmentId) => {
    ListShipment.counter++;
    console.log(`Check Estimate: index=${index}:${shipmentId} (${ListShipment.counter})`);
    let isEstimating = [...this.state.isEstimating];
    if (!isEstimating[index]) {
      isEstimating[index] = true;
      this.setState({ isEstimating });
    }
    const res = await getShipment(shipmentId);
    if ((res && res.transportEstimated) ||
        (res && res.transportEstimateError) ||
        (ListShipment.counter > ListShipment.estimateTransportCheckMaxRetries)) {
      console.log(`Cancel timer (index=${index})`);
      ListShipment.counter = 0;
      const data = [...this.state.data];
      let entry = data[index];
      if (res.transportEstimateError) {
        entry.partnerededEstimate = 0;
        entry.transportEstimated = false;
        //entry.shipmentStatus = STATUS_SHIPMENT.ERROR;
        entry.status = STATUS_SHIPMENT.ERROR;
        //alert(res.transportEstimateError.message);
        this.handleErrorShow(res.transportEstimateError);
        await deleteKeyShipment(shipmentId, ["transportEstimateError"]);
      } else {
        entry.partnerededEstimate = res.partnerededEstimate;
        entry.transportDetail = res.transportDetail;
        entry.transportEstimated = res.transportEstimated;
        //entry.shipmentStatus = STATUS_SHIPMENT.ESTIMATED;
        entry.status = STATUS_SHIPMENT.ESTIMATED;
      }
      entry.transportConfirmed = false;
      entry.transportVoided = false;
      entry.partneredEstimate = res.partneredEstimate;
      entry.partneredEstimateCurrency = res.partneredEstimateCurrency;
      this.setState({ data });
      this.cancelTimer(index);
      let info = {
        partnerededEstimate: entry.partnerededEstimate,
        status: entry.status,
        transportEstimated: entry.transportEstimated,
        transportConfirmed: entry.transportConfirmed,
        transportVoided: entry.transportVoided,
        transportDetail: entry.transportDetail,
      }
      updateShipment(entry.shipmentId, info);
    }
  }

  initializeTimer(index, shipmentId) {
    console.log("Set Interval: " + index + " for " + shipmentId);
    this.intervalRefs[index] = setInterval(() => {this.checkEstimateStatus(index, shipmentId)}, ListShipment.estimateTransportCheckInterval);
  }

  cancelTimer(index) {
    if (this.intervalRefs[index]) {
      clearInterval(this.intervalRefs[index]);
      this.intervalRefs[index] = null;
    }
    let isEstimating = [...this.state.isEstimating];
    if (isEstimating[index]) {
      isEstimating[index] = false;
      this.setState({ isEstimating });
    }
  }

  getShippingMethodsRadioItems = (row) => {
    let data = [...this.state.data];
    let d = data[row.index];
    const a = [...this.state.shippingMethods];
    let o = [];
    a.forEach((item, index, array) => {
      const key = `packageType.${row.index}.${index}`;
      const disabled = item.status !== "enable";
      let style = {};
      if (disabled) {
        style = {
          color: "#aeaeae",
        };
      }
      o.push(<div key={key}><div><Radio checked={item.id === d.packageType} name={key} value={item.id} onChange={this.handleRadioChange} disabled={disabled}><strong style={style}><FormattedMessage id={item.key} /> ({item.id})</strong></Radio></div><div style={{ paddingLeft: "20px" }}><small style={style}><FormattedMessage id={`${item.key}_desc`} /></small></div></div>);
    });
    return o;
  }

  getAmazonPartneredCarriersRadioItems = (row) => {
    const { formatMessage } = this.props.intl;
    let data = [...this.state.data];
    let d = data[row.index];
    const a = [...this.state.amazonPartneredCarriers];
    let o = [];
    a.forEach((item, index, array) => {
      const key = `carrier.${row.index}.${index}`;
      const translate = formatMessage({ id: `${item.key}_desc` });
			const translatedMessage = (item.name !== translate) ? ` (${translate})` : "";
      const disabled = item.status !== "enable";
      let style = {};
      if (disabled) {
        style = {
          color: "#aeaeae",
        };
      }
      o.push(<div key={key}><div><Radio checked={item.id === d.carrier} name={key} value={item.id} onChange={this.handleRadioChange} inline disabled={disabled}><strong style={style}>{item.name}{translatedMessage}</strong></Radio></div><div style={{ paddingLeft: "20px" }}><small style={style}>{item.desc}</small></div></div>);
    });
    return o;
  }

  getShippingServicesRadioItems = (row) => {
    const { formatMessage } = this.props.intl;
    let data = [...this.state.data];
    let d = data[row.index];
    const a = [...this.state.shippingServices];
    let o = [];
    a.forEach((item, index, array) => {
      const key = `service.${row.index}.${index}`;
      const disabled = item.status !== "enable";
      let style = {};
      if (disabled) {
        style = {
          color: "#aeaeae",
        };
      }
      o.push(<div key={key}><div><Radio checked={item.id === d.service} name={key} value={item.id} onChange={this.handleRadioChange} inline disabled={disabled}><strong style={style}><FormattedMessage id={item.key} /></strong></Radio></div><div style={{ paddingLeft: "20px" }}><small style={style}><FormattedMessage id={`${item.key}_desc`} /></small></div></div>);
    });
    return o;
  }

  handleRadioChange = async (e) => {
    const items = e.target.name.split(".");
    const value = e.target.value;
    let data = [...this.state.data];
    let item = data[parseInt(items[1], 10)];
    item[items[0]] = value;
    data[parseInt(items[1], 10)] = item;
    this.setState({ data });

    let info = {};
    info[items[0]] = value;
    await updateShipment(item.shipmentId, info);
  }

  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  select = (key) => {
    let selection = [...this.state.selection];
    const len = [...this.state.data].length;
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
    } else {
      selection.push(key);
      this.setState({ selection });
      if (len === selection.length) {
        const selectAll = true;
        this.setState({ selectAll });
      }
    }
    return selection;
  }

  deselect = (key) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
      this.setState({ selection });
      if (selection.length === 0) {
        const selectAll = false;
        this.setState({ selectAll });
      }
    } else {
    }
    return selection;
  }

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected = key => {
    return this.state.selection.includes(key);
  }

  logSelection = () => {
    console.log("Selection:", this.state.selection);
  }

  validateShippingInfo = (row) => {
    const entry = [...this.state.data][row.index];
    if (entry) {
      if (entry.packageType && entry.carrier &&
          entry.packageType !== "" && entry.carrier !== "") {
        return true;
      }
    }
    return false;
  }

  validateBoxInfo = (row) => {
    const cartons = [...this.state.data][row.index]['cartons'];
    if (cartons) {
      let check = true;
      cartons.forEach(carton => {
        if (carton.length > 0 &&
            carton.width > 0 &&
            carton.height > 0 &&
          carton.weight > 0) {
        } else {
          check = false;
        }
      });
      return check;
    } else {
      return false;
    }
  }

  validateAddBox = (row) => {
    const data = [...this.state.data][row.index];
    const items = data['items'];
    let total = 0;
    let totalShipped = 0;
    items.forEach((item, index) => {
      let shipped = 0;
      const cartons = data['cartons'];
      if (cartons) {
        cartons.forEach((item2, index2) => {
          const cartonItems = item2['items'];
          if (cartonItems) {
            cartonItems.forEach(item3 => {
              if (item.sku === item3.sku) {
                shipped += parseInt(item3.quantityInCase, 10) * parseInt(item3.quantityShipped, 10);
              }
            });
          }
        });
      }
      totalShipped += shipped;
      total += parseInt(item.quantityShipped, 10);
    });
    if (totalShipped < total) {
      return true;
    } else {
      return false;
    }
  }

  isEstimated = (row) => {
    return this.state.data[row.index].transportEstimated;
  }

  isConfirmed = (row) => {
    return this.state.data[row.index].transportConfirmed;
  }

  isVoided = (row) => {
    return this.state.data[row.index].transportVoided;
  }

  isPaid = (row) => {
    const orderStatus = this.state.data[row.index].orderStatus;
    if (orderStatus && orderStatus === STATUS_ORDER.PAID) {
      return true;
    } else {
      return false;
    }
  }

  async handleEstimateAmazonPriceClick(event, row) {
    const data = [...this.state.data];
    let entry = data[row.index];
    const sm = [...this.state.shippingMethods];
    const smMap = new Map(sm.map(i => [i.id, i.value]));
    let isEstimating = [...this.state.isEstimating];
    isEstimating[row.index] = true;
    entry.dateUpdated = Date.now();

    let resetFlag = {
      transportEstimated: false,
    };

    await updateShipment(entry.shipmentId, resetFlag);

    this.setState({ isEstimating, data });
    try {
      await this.provideCartonInfo(entry, smMap);
      await submitTransportEstimate(entry)
      .then((response) => {
        this.initializeTimer(row.index, entry.shipmentId);
      })
      .catch((error) => {
        const err = error.response.data.error;
        let msg = [];
        err.forEach((item) => {
          msg.push(`${item.shipmentId}: ${item.message}`);
        });
        //alert(msg.join("\n"));
        // TODO: Handle multiple errors
        this.handleErrorShow(err[0]);
        isEstimating[row.index] = false;
        entry.transportEstimated = false;
        entry.transportConfirmed = false;
        entry.transportVoided = false;
        this.setState({ data, isEstimating });
      });
    } catch (e) {
      console.log(e);
      isEstimating[row.index] = false;
      entry.transportEstimated = false;
      entry.transportConfirmed = false;
      entry.transportVoided = false;
      this.setState({ data, isEstimating });
    }
    const info = {
      //transportEstimated: entry.transportEstimated,
      transportConfirmed: entry.transportConfirmed,
      transportVoided: entry.transportVoided,
      status: entry.status,
    };
    await updateShipment(entry.shipmentId, info);
  }

  async handleConfirmAmazonPriceClick(event, row) {
    const data = [...this.state.data];
    let entry = data[row.index];
    let isConfirming = [...this.state.isConfirming];
    isConfirming[row.index] = true;
    entry.dateUpdated = Date.now();
    this.setState({ isConfirming, data });
    try {
      await transportConfirm(entry)
      .then(response => {
        isConfirming[row.index] = false;
        entry.transportConfirmed = true;
        entry.transportVoided = false;
        entry.status = STATUS_SHIPMENT.CONFIRMED;
        entry.dateConfirmed = Date.now();
        this.setState({ isConfirming, data });
      })
      .catch(error => {
        const err = error.response.data.error;
        let msg = [];
        err.forEach((item) => {
          msg.push(`${item.shipmentId}: ${item.message}`);
        });
        //alert(msg.join("\n"));
        // TODO: Handle multiple errors
        this.handleErrorShow(err[0]);
        entry.transportConfirmed = false;
        isConfirming[row.index] = false;
        this.setState({ isConfirming });
      });
    } catch (e) {
      console.log(e);
      entry.transportConfirmed = false;
      isConfirming[row.index] = false;
      this.setState({ isConfirming });
    }
    const info = {
      transportEstimated: entry.transportEstimated,
      transportConfirmed: entry.transportConfirmed,
      transportVoided: entry.transportVoided,
      status: entry.status,
      dateConfirmed: entry.dateConfirmed,
    };
    updateShipment(entry.shipmentId, info);
  }

  async handleVoidAmazonPriceClick(event, row) {
    const data = [...this.state.data];
    let entry = data[row.index];
    let isVoiding = [...this.state.isVoiding];
    isVoiding[row.index] = true;
    entry.dateUpdated = Date.now();
    entry.partnerededEstimate = 0;
    entry.dateConfirmed = 0;
    this.setState({ isVoiding, data });
    try {
      await transportVoid(entry);
      isVoiding[row.index] = false;
      entry.transportConfirmed = false;
      entry.transportEstimated = false;
      entry.transportVoided = true;
      entry.status = STATUS_SHIPMENT.VOIDED;
      this.setState({ isVoiding, data });
    } catch (e) {
      console.log(e);
      entry.transportConfirmed = false;
      entry.transportEstimated = false;
      entry.transportVoided = false;
      isVoiding[row.index] = false;
      this.setState({ isVoiding });
    }
    const info = {
      transportEstimated: entry.transportEstimated,
      transportConfirmed: entry.transportConfirmed,
      transportVoided: entry.transportVoided,
      status: entry.status,
      partnerededEstimate: 0,
      dateConfirmed: 0,
    };
    updateShipment(entry.shipmentId, info);

    // Check cart
    if (this.isSelected(entry.shipmentId)) {
      this.deselect(entry.shipmentId);
    }
  }

  async handleDownloadShippingLabelClick(event, row) {
    const data = [...this.state.data];
    let entry = data[row.index];
    let isDownloadingShippingLabel = [...this.state.isDownloadingShippingLabel];
    isDownloadingShippingLabel[row.index] = true;
    entry.dateUpdated = Date.now();

    this.setState({ isDownloadingShippingLabel, data });
    try {
      let resp = await shippingLabelGenerate(entry);
      await shippingLabelGet(entry).then(response => {
        //window.open("data:application/pdf;base64," + response, "_blank");
        const data = new Buffer(response, 'base64');
        const file = new Blob([data], {type: 'application/pdf'});
        FileDownload(file, resp.shippingLabel ? resp.shippingLabel : "Label.pdf");
        //const fileURL = URL.createObjectURL(file);
        //window.open(fileURL);
      }).catch(error => {
        console.log("error:", error);
      });
      isDownloadingShippingLabel[row.index] = false;
      entry.transportConfirmed = true;
      entry.transportEstimated = true;
      entry.transportVoided = false;
      entry.shippingLabelDownloaded = true;
      entry.status = STATUS_SHIPMENT.DOWNLOADED;
      entry.shippingLabel = resp.shippingLabel;
      this.setState({ isDownloadingShippingLabel, data });
    } catch (e) {
      console.log(e);
      entry.transportConfirmed = true;
      entry.transportEstimated = true;
      entry.transportVoided = false;
      entry.shippingLabelDownloaded = false;
      isDownloadingShippingLabel[row.index] = false;
      this.setState({ isDownloadingShippingLabel });
    }
    const info = {
      transportEstimated: entry.transportEstimated,
      transportConfirmed: entry.transportConfirmed,
      transportVoided: entry.transportVoided,
      status: entry.status,
      shippingLabelDownloaded: entry.shippingLabelDownloaded,
      shippingLabel: entry.shippingLabel,
    };
    updateShipment(entry.shipmentId, info);
  }

  async handleAddToCartClick(event, row) {
    event.preventDefault();
    const item = row.original._id;
    let selection = [];
    if (this.isSelected(item)) {
      selection = this.deselect(item);
    } else {
      selection = this.select(item);
    }
    // Calculate total price
    const {data, dataMap} = this.state;
    let tisTotalPrice = 0;
    selection.forEach((key) => {
      const entry = dataMap.get(key);
      tisTotalPrice += parseFloat(entry.estimate);
    });
    tisTotalPrice += this.state.oilSurcharge;
    tisTotalPrice = Math.round(tisTotalPrice * 100) / 100;
    this.setState({ data, tisTotalPrice });
  }

  handleGenerateInvoiceClick = (event, entry) => {
    //this.props.history.push("/shipment/invoice");
  }

  updateCartonInfo = async (index, entry) => {
    const data = [...this.state.data];
    data[index] = entry;
    const info = {
      estimate: entry.estimate,
      estimateCurrency: entry.estimateCurrency,
    };
    await updateShipment(entry.shipmentId, info);
    this.setState({ data });
  }

  provideCartonInfo(entry, smMap) {
    let body = {
      cartons: entry.cartons,
      isPartnered: true, //entry.isPartnered,
      packageType: entry.packageType,//smMap.get(entry.packageType),
      carrier: entry.carrier,
      transportEstimated: entry.transportEstimated ? entry.transportEstimated : false,
      transportConfirmed: entry.transportConfirmed ? entry.transportConfirmed : false,
      transportVoided: entry.transportVoided ? entry.transportVoided : false,
    }
    return updateShipment(entry.shipmentId, body);
  }

  estimateTooltip = (row) => {
    const { formatMessage } = this.props.intl;
    return (
      <Tooltip id={`tip-${row.index}`}>
        <div dangerouslySetInnerHTML={{__html: formatMessage({ id: "shp_estimateTooltip" })}}></div>
      </Tooltip>
    );
  }

  showCountdown = (row, type) => {
    const data = [...this.state.data];
    const entry = data[row.index];
    const timeout = entry.dateConfirmed || 0;
    //const countdown =
    //  <Row className="mb-4">
    //    <Col>
    //      <Countdown date={timeout + 86400000} />
    //    </Col>
    //  </Row>;
    const countdown = <Countdown date={timeout + 86400000} type={type} />;
    const no = <div/>;
    if (timeout > 0) {
      return countdown;
    } else {
      return no;
    }
  }

  renderCartonInfoInput = (row) => {
    const { formatMessage } = this.props.intl;
    //const addItems = [...this.state.addItems][row.index];
    const entry = [...this.state.data][row.index];
    const memberInfo = this.state.memberInfo;
    const shippingServices = this.state.shippingServices;
    let service = {};
    shippingServices.forEach(item => {
      if (item.id === entry.service) {
        service = item;
      }
    });
    /*
    let items = entry.cartons[0].items;
    for (let i = 0; i < 7; i++) {
      items.push({
        itemUnit: "pcs",
        quantityInCase: 1,
        quantityShipped: 5,
        sku: "IJ-0002",
        totalPrice: 100,
        unitPrice: 20,
        netWeight: 1.5,
        grossWeight: 0.5,
      });
      items.push({
        itemUnit: "pcs",
        quantityInCase: 3,
        quantityShipped: 1,
        sku: "IJ-0003",
        totalPrice: 90,
        unitPrice: 30,
        netWeight: 1.5,
        grossWeight: 0.5,
      });
      items.push({
        itemUnit: "sets",
        quantityInCase: 1,
        quantityShipped: 5,
        sku: "IJ-0007",
        totalPrice: 100,
        unitPrice: 20,
        netWeight: 1.5,
        grossWeight: 0.5,
      });
      items.push({
        itemUnit: "sets",
        quantityInCase: 1,
        quantityShipped: 5,
        sku: "IJ-0010",
        totalPrice: 100,
        unitPrice: 20,
        netWeight: 1.5,
        grossWeight: 0.5,
      });
      items.push({
        itemUnit: "pcs",
        quantityInCase: 1,
        quantityShipped: 5,
        sku: "IJ-0020",
        totalPrice: 100,
        unitPrice: 20,
        netWeight: 1.5,
        grossWeight: 0.5,
      });
    }
    */
    const price = 0;
    return (
      <div style={{ "paddingRight": "100px", "paddingLeft": "100px" }}>
        <Form horizontal onSubmit={(event) => this.handleSubmit(row, event)}>
          <Row className="mb-4">
            <Col>
              <div><hr/></div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={9}>
              <OverlayTrigger
                placement="top"
                overlay={this.estimateTooltip(row)}
                delayShow={300}
                delayHide={150}
              >
                <LoaderButton
                  bsStyle="info"
                  disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.validateShippingInfo(row)}
                  onClick={event => this.handleEstimateAmazonPriceClick(event, row)}
                  isLoading={[...this.state.isEstimating][row.index]} 
                  text={formatMessage({ id: "shp_estimateAmazonPrice" })}
                  loadingText={formatMessage({ id: "shp_estimatingAmazonPrice" })}
                />
              </OverlayTrigger>
              {' '}<Glyphicon glyph="chevron-right" aria-hidden="true" />{' '}
              <LoaderButton
                bsStyle="warning"
                disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.validateShippingInfo(row) || !this.isEstimated(row)}
                onClick={event => this.handleConfirmAmazonPriceClick(event, row)}
                isLoading={[...this.state.isConfirming][row.index]} 
                text={formatMessage({ id: "shp_confirmAmazonPrice" })}
                loadingText={formatMessage({ id: "shp_confirmingAmazonPrice" })}
              />
              {' '}<Glyphicon glyph="chevron-right" aria-hidden="true" />{' '}
              <LoaderButton
                bsStyle="success"
                disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.validateShippingInfo(row) || !this.isEstimated(row) || !this.isConfirmed(row)}
                onClick={event => this.handleDownloadShippingLabelClick(event, row)}
                isLoading={[...this.state.isDownloadingShippingLabel][row.index]} 
                text={formatMessage({ id: "shp_downloadShippingLabel" })}
                loadingText={formatMessage({ id: "shp_downloadingShippingLabel" })}
              />
              <LoaderButton
                className="pull-right"
                bsStyle="danger"
                disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.validateShippingInfo(row) || !this.isEstimated(row) || !this.isConfirmed(row)}
                onClick={event => this.handleVoidAmazonPriceClick(event, row)}
                isLoading={[...this.state.isVoiding][row.index]} 
                text={formatMessage({ id: "shp_voidAmazonPrice" })}
                loadingText={formatMessage({ id: "shp_voidingAmazonPrice" })}
              />
              {this.showCountdown(row, "simple")}
            </Col>
            <Col sm={3} style={{ borderLeft: "2px solid #eee"}}>
              <Button
                className="pull-right"
                bsStyle={!this.isSelected(row.original._id) ? "success" : "danger"}
                disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.isEstimated(row) || !this.isConfirmed(row) || !this.isPaid(row)}
                onClick={event => this.handleAddToCartClick(event, row)}
              >{!this.isSelected(row.original._id) ? formatMessage({ id: "g_addToCart" }) : formatMessage({ id: "g_removeFromCart" })}</Button>{' '}<ShipmentPackingListModal entry={entry} hubInfo={this.state.hubInfo[0]} memberInfo={memberInfo} inventoryMap={this.state.inventoryMap} shippingService={service}/><ShipmentInvoiceModal entry={entry} hubInfo={this.state.hubInfo[0]} memberInfo={memberInfo} inventoryMap={this.state.inventoryMap} shippingService={service}/>
              {/*
              <Button
                className="pull-right"
                bsStyle="primary"
                onClick={event => this.handleGenerateInvoiceClick(event, entry)}
              ><FormattedMessage id="g_generateInvoice" /></Button>
              */}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div><br /></div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={4}>
              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3"><FormattedMessage id="shp_shippingService" /></Panel.Title>
                </Panel.Heading>
                <Panel.Body>{this.getShippingServicesRadioItems(row)}</Panel.Body>
              </Panel>
            </Col>
            <Col sm={4}>
              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3"><FormattedMessage id="shp_shippingMethod" /></Panel.Title>
                </Panel.Heading>
                <Panel.Body>{this.getShippingMethodsRadioItems(row)}</Panel.Body>
              </Panel>
            </Col>
            <Col sm={4}>
              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3"><FormattedMessage id="shp_shippingCarrier" /></Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div><strong><FormattedMessage id="shp_amazonPartneredCarrier" /></strong></div>
                    {this.getAmazonPartneredCarriersRadioItems(row)}
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col style={{ padding: "15px" }}>
              <CartonInfoTable index={row.index} entry={this.state.data[row.index]} inventoryMap={this.state.inventoryMap} price={this.state.price} prices={this.state.prices[entry.service]} exchangeRates={this.state.exchangeRates} updateCartonInfo={this.updateCartonInfo}/>
            </Col>
          </Row>
        </Form>
        {/*<pre>{JSON.stringify(isEstimating, null, 2)}</pre>*/}
        {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
      </div>
    );
  }

  // Handle adding to purchase order
  async handleCheckoutClick(event) {
    let data = [...this.state.data];
    const hubInfo = this.state.hubInfo[0];
    const oilPrices = this.state.oilPrices;
    const exchangeRates = this.state.exchangeRates;
    const selection = [...this.state.selection];
    const memberInfo = this.state.memberInfo;
    let shipmentIds = [];
    let contents = [];
    let total = 0;
    let numOfItems = 0;
    let currency = "";
    let grossWeight = 0;
    let chargeableWeight = 0;
    let unit = "";
    let cartItems = [];
    data.forEach((entry) => {
      if (selection.includes(entry.shipmentId)) {
        shipmentIds.push(checkNullS(entry.shipmentId));
        //contents.push(`${checkNullS(entry.shipmentId)}:${checkNullS(entry.msku)}`);
        let content = entry.msku;
        if ("items" in entry) {
          let arr = [];
          entry.items.forEach(item => {
            arr.push(`${item.sku}x${item.quantityShipped}`);
          });
          if (arr.length > 0) {
            content = arr.join(",");
          }
        }
        contents.push(`${entry.shipmentId}:${content} (${entry.estimateCurrency} $${entry.estimate})`);
        total += parseFloat(checkNullN(entry.estimate));
        numOfItems += parseFloat(checkNullS(entry.totalUnits));
        currency = checkNullS(entry.estimateCurrency);
        if (entry.transportDetail) {
          grossWeight += parseInt(checkNullS(entry.transportDetail.packageList.weight.value), 10);
          chargeableWeight += parseInt(checkNullS(entry.transportDetail.packageList.weight.value), 10);
          unit = checkNullS(entry.transportDetail.packageList.weight.unit);
        }
        cartItems.push({
          shipmentId: entry.shipmentId,
          price: entry.estimate,
          currency: entry.estimateCurrency,
          numOfItems: entry.totalUnits,
          items: entry.items,
          transportDetail: entry.transportDetail,
        });
      }
    });

    const oilSurcharge = this.state.oilSurcharge;;
    total += oilSurcharge;
    let po = {
      "poid": makeid(20),
      "timestamp": Date.now(),
      "orderStatus": STATUS_ORDER.UNPAID,
      //"hawb":"695-13214611",
      //"carrier":"BR0028",
      //"destination":"SFO",
      //"date":"2018/07/07",
      "numOfPieces": numOfItems,
      //"contents": checkNullS(item.msku),
      shipmentIds,
      contents,
      "shipperName": checkNullS(memberInfo.company),
      "shipperPhoneCountry": checkNullS(memberInfo.phoneCountry),
      "shipperCell": checkNullS(memberInfo.cell),
      "shipperPhone": checkNullS(memberInfo.phone),
      "shipperFax": checkNullS(memberInfo.fax),
      "shipperAddress1": checkNullS(memberInfo.address1),
      "shipperAddress2": checkNullS(memberInfo.address2),
      "shipperCity": checkNullS(memberInfo.city),
      "shipperStateCode": checkNullS(memberInfo.stateCode),
      "shipperPostalCode": checkNullS(memberInfo.postalCode),
      "shipperCountryCode": checkNullS(memberInfo.countryCode),
      "shipperEmail": checkNullS(memberInfo.email),
      "consigneeName": checkNullS(hubInfo.name),
      "consigneeAttn": checkNullS(hubInfo.attn),
      "consigneeAddress1": checkNullS(hubInfo.address1),
      "consigneeAddress2": checkNullS(hubInfo.address2),
      "consigneeCity": checkNullS(hubInfo.city),
      "consigneeStateCode": checkNullS(hubInfo.stateCode),
      "consigneePostalCode": checkNullS(hubInfo.postalCode),
      "consigneeCountryCode": checkNullS(hubInfo.countryCode),
      "consigneeCell": checkNullS(hubInfo.cell),
      "consigneePhone": checkNullS(hubInfo.phone),
      "consigneeFax": checkNullS(hubInfo.fax),
      "consigneeEmail": checkNullS(hubInfo.email),
      "estimateCurrency": checkNullS(currency),
      "estimate": checkNullN(total),
      oilSurcharge,
      grossWeight,
      chargeableWeight,
      unit,
      oilPrices,
      exchangeRates,
      cartItems,
    };
    /*
    console.log("Auth:", JSON.stringify(Auth, null, 2));
    let session = await Auth.currentSession();
    console.log("session:", JSON.stringify(session, null, 2));
    const r = await Auth.currentAuthenticatedUser().then(
      user => console.log("user:", JSON.stringify(user, null, 2))
    ).catch(
      err => console.log(err)
    );
    */
    
    let base_param = {
      //MerchantTradeNo: po.poid, //請帶20碼uid, ex: f0a0d7e9fae1bb72bc93
      MerchantTradeNo: makeid(20), //請帶20碼uid, ex: f0a0d7e9fae1bb72bc93
      MerchantTradeDate: DateFormat(new Date(), "yyyy/mm/dd HH:MM:ss"), //ex: 2017/02/13 15:45:30
      TotalAmount: `${Math.round(total)}`,
      TradeDesc: `${po.poid}:${po.shipmentIds.join(",")}`,
      ItemName: po.contents.join("#"),
      ReturnURL: 'https://e452j27r56.execute-api.ap-northeast-1.amazonaws.com/prod/opayResult',
      // ChooseSubPayment: '',
      //OrderResultURL: 'http://localhost:3000/order/list',
      // NeedExtraPaidInfo: '1',
      ClientBackURL: 'http://trackitshipped.com/order/list',
      // ItemURL: 'http://item.test.tw',
      //Remark: `TractionNo:${btoa(userId)}`,
      // HoldTradeAMT: '1',
      // StoreID: '',
      // UseRedeem: ''
    };
    let inv_param = {
      //RelateNumber: makeid(30),  //請帶30碼uid ex: SJDFJGH24FJIL97G73653XM0VOMS4K
      // CustomerID: 'MEM_0000001',  //會員編號
      // CustomerIdentifier: '',   //統一編號
      // CustomerName: '測試買家',
      // CustomerAddr: '測試用地址',
      // CustomerPhone: '0123456789',
      // CustomerEmail: 'johndoe@test.com',
      // ClearanceMark: '2',
      // TaxType: '1',
      // CarruerType: '',
      // CarruerNum: '',
      // Donation: '2',
      // LoveCode: '',
      // Print: '1',
      // InvoiceItemName: '測試商品1|測試商品2',
      // InvoiceItemCount: '2|3',
      // InvoiceItemWord: '個|包',
      // InvoiceItemPrice: '35|10',
      // InvoiceItemTaxType: '1|1',
      // InvoiceRemark: '測試商品1的說明|測試商品2的說明',
      // DelayDay: '0',
      // InvType: '07'
    };

    po['paymentParams'] = [base_param];
    po['paymentInvoiceParams'] = [inv_param];
    po['paymentProvider'] = "opay";

    po['paymentTradeNo'] = [base_param.MerchantTradeNo];
    //po['paymentInvNo'] = [inv_param.RelateNumber];  // Turn on when enabling electronic invoice

    po = _.pickBy(po, _.identity);

    createOrder(po).then(response => {
      let create = new opay_payment();
      let html = create.payment_client.aio_check_out_credit_onetime(base_param, inv_param);
      this.setState({ html });
    }).catch(error => {
      console.log("putOrder error:", error);
    });
  }

  handleRemoveItemFromCartClick = (event, row) => {
    const sku = row.value;
    this.deselect(sku);
  }

  validateCheckoutForm = () => {
    const selection = [...this.state.selection];
    if (selection.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  handleShipmentShippedClick = (event) => {
    //this.setShipmentStatus("SHIPPED", "isShipmentShippedLoading");
    this.setShipmentStatus(STATUS_SHIPMENT.SHIPPED, "isShipmentShippedLoading");
  }

  handleShipmentCancelledClick = (event) => {
    //this.setShipmentStatus("CANCELLED", "isShipmentCancelledLoading");
    this.setShipmentStatus(STATUS_SHIPMENT.CANCELLED, "isShipmentCancelledLoading");
  }

  setShipmentStatus = async (status, flag) => {
    let state = {};
    state[flag] = true;
    this.setState(state);
    const selection = [...this.state.selection];
    const {data, dataMap} = this.state;
    let info = {};
    let shipmentIds = [];
    selection.forEach(item => {
      const entry = dataMap.get(item);
      shipmentIds.push(entry.shipmentId);
      info[entry.shipmentId] = {
        shipmentStatus: status,
      };
    });
    const {formatMessage} = this.props.intl;
    await updateInboundShipment(shipmentIds, info).then(response => {
      response.data.forEach(async (item) => {
        let info = {
          //status: STATUS_SHIPMENT[status],
          status,
        }
        await updateShipment(item.shipmentId, info);
        let entry = dataMap.get(item.shipmentId);
        entry.status = status;
        entry.shipmentStatus = status;
        state[flag] = false;
        state['data'] = data;
        this.setState(state);
        this.deselect(item.shipmentId);
      });
    }).catch((error) => {
      if (_.has(error, "response.data.data[0].message")) {
        let err = {
          code: error.response.data.data[0].code,
          message: error.response.data.data[0].message,
        };
        if (err.code === "InvalidRequestException") {
          err.code = formatMessage({id:"shp_error_invalidRequestException"});
        }
        if (err.message.includes("locked status")) {
          err.message = formatMessage({id:"shp_error_shipmentInLockedStatus"});
        }
        this.handleErrorShow(err);
      } else {
        this.handleErrorShow(error);
      }
    });
  }

  validateShipmentShippedForm = () => {
    return this.validateShipmentStatus([STATUS_SHIPMENT.WORKING]);
  }

  validateShipmentCancelledForm = () => {
    return this.validateShipmentStatus([STATUS_SHIPMENT.WORKING,STATUS_SHIPMENT.SHIPPED]);
  }

  validateShipmentStatus = (status) => {
    const selection = [...this.state.selection];
    if (selection.length === 0) {
      return false;
    }
    const dataMap = this.state.dataMap;
    let flag = false;
    selection.forEach(item => {
      const entry = dataMap.get(item);
      if (status.includes(entry.shipmentStatus)) {
        flag = true;
      } else {
        flag = false;
      }
      if (!("shipmentStatus" in entry)) {
        flag = true;
      }
    });
    return flag;
  }

  syncShipment = async () => {
    this.setState({ isSyncing: true });
    const resp = await retrieveShipment();
    //console.log("RESP:", resp);
    let [data, dataMap] = this.prepareData(resp);
    //console.log("DATA:", data);
    //console.log("DATAMAP:", dataMap);
    this.setState({ data, dataMap, isSyncing: false });
  }

  prepareData(resp) {
    const data = this.state.data;
    let dataMap = new Map(data.map(i => [i.shipmentId, i]));;
    resp.forEach(item => {
      let entry = dataMap.get(item.shipmentId);
      if (entry) {
        entry.shipmentName = item.shipmentName;
        //entry.shipmentStatus = STATUS_SHIPMENT[item.shipmentStatus];
        entry.shipmentStatus = item.shipmentStatus;
        entry.labelPrepType = item.labelPrepType;
        entry.areCasesRequired = item.areCasesRequired;
        entry.boxContentsSource = item.boxContentsSource;
        entry.msku = item.msku;
        entry.planName = item.planName;
        entry.estimatedFee = item.estimatedFee;
        entry.items = item.items;
      } else {
        item.status = item.shipmentStatus;
        data.push(item);
      }
    });
    dataMap = new Map(data.map(i => [i.shipmentId, i]));
		return [data, dataMap];
  }

  getTrackingColumns = () => {
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="shp_tracking_time" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(row.value, 10)/1000}</Moment></div>
        )
      },
      id: "timestamp",
      accessor: d => d.timestamp,
      width: 200,
      maxWidth: 250,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="shp_tracking_location" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "location",
      accessor: d => d.location,
      width: 200,
      maxWidth: 250,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="shp_tracking_description" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      id: "details",
      accessor: d => d.details,
      width: 400,
      maxWidth: 450,
    }];
  }

  getCartColumns = () => {
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><Glyphicon glyph="tag" aria-hidden="true" /></div>
      ),
      Cell: row => {
        return (
          <div>
            <Button id={"box"+row.index} bsStyle="danger" bsSize="xsmall" onClick={event => this.handleRemoveItemFromCartClick(event, row)} style={{ margin: "2px" }}><Glyphicon glyph="remove" aria-hidden="true" /></Button>
          </div>
        );
      },
      id: "itemId",
      accessor: d => d.shipmentId,
      maxWidth: 40,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="shp_shipmentId" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      Footer: () => {
        const selection = [...this.state.selection];
        return (
          <span>
            <FormattedMessage id="shp_oilPrice" />:{" "}
            {`${this.state.tisCurrency} $${(this.state.oilSurcharge && selection.length > 0) ? this.state.oilSurcharge : 0}`}
          </span>
        );
      },
      id: "shipmentId",
      accessor: d => d.shipmentId,
      width: 200,
      maxWidth: 250,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}><FormattedMessage id="shp_price" /></div>
      ),
      Cell: row => {
        return (
          <div style={{ margin: "5px" }}>{row.value}</div>
        )
      },
      Footer: () => {
        const selection = [...this.state.selection];
        return (
          <span>
            <strong><FormattedMessage id="shp_total" />:</strong>{" "}
            {`${this.state.tisCurrency} $${(this.state.tisTotalPrice) ? this.state.tisTotalPrice : 0}`}
          </span>
        );
      },
      id: "price",
      accessor: d => d.price,
      width: 200,
      maxWidth: 250,
    }];
  }

  showTrackingInfo = (row) => {
    let entry = [...this.state.data][row.index];
    let trackingInfo = _.has(entry, "trackingInfo.activities") ? entry.trackingInfo.activities : [];

    const { formatMessage } = this.props.intl;
    return (
      <Popover
        id="popover-trigger-hover-focus"
        title={formatMessage({ id: "shp_trackingInfo" })}
        style={{ maxWidth: "650px" }}
      >
        <div><strong><FormattedMessage id="shp_tracking_number" /></strong>: <span>{entry.trackingId} - <i>{entry.trackingInfo.service} <small>({entry.trackingInfo.weight})</small></i></span></div>
        <div><strong><FormattedMessage id="shp_tracking_eta" /></strong>: <span><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.trackingInfo.eta, 10)/1000}</Moment></span></div>
        <div><strong><FormattedMessage id="shp_tracking_destination" /></strong>: <span>{entry.trackingInfo.destination}</span></div>
        <ReactTable
          columns={this.getTrackingColumns()}
          data={trackingInfo}
          noDataText={formatMessage({ id: "g_noTracking" })}
          minRows={2}
          defaultPageSize={20}
          showPagination={false}
          className="-striped -highlight"
          previousText={formatMessage({ id: "g_previousText" })}
          nextText={formatMessage({ id: "g_nextText" })}
          loadingText={formatMessage({ id: "g_loadingText" })}
          pageText={formatMessage({ id: "g_pageText" })}
          ofText={formatMessage({ id: "g_ofText" })}
          rowsText={formatMessage({ id: "g_rowsText" })}
          pageJumpText={formatMessage({ id: "g_pageJumpText" })}
          rowsSelectorText={formatMessage({ id: "g_rowsSelectorText" })}
        />
      </Popover>
    );
  }

  showCartItems = () => {
    let data = [...this.state.data];
    const selection = [...this.state.selection];
    let reqItems = [];
    data.forEach((item) => {
      if (selection.includes(item.shipmentId)) {
        const obj = {
          shipmentId: item.shipmentId,
          price: item.estimate,
        }
        reqItems.push(obj);
      }
    });

    const { formatMessage } = this.props.intl;
    return (
      <Popover
        id="popover-trigger-hover-focus"
        title={formatMessage({ id: "shp_itemsInCart" })}
        style={{ maxWidth: "650px" }}
      >
        <ReactTable
          columns={this.getCartColumns()}
          data={reqItems}
          noDataText={formatMessage({ id: "g_cartEmpty" })}
          minRows={2}
          defaultPageSize={5}
          showPagination={false}
          className="-striped -highlight"
          previousText={formatMessage({ id: "g_previousText" })}
          nextText={formatMessage({ id: "g_nextText" })}
          loadingText={formatMessage({ id: "g_loadingText" })}
          pageText={formatMessage({ id: "g_pageText" })}
          ofText={formatMessage({ id: "g_ofText" })}
          rowsText={formatMessage({ id: "g_rowsText" })}
          pageJumpText={formatMessage({ id: "g_pageJumpText" })}
          rowsSelectorText={formatMessage({ id: "g_rowsSelectorText" })}
        />
        <Button
          className="pull-right"
          bsStyle="success"
          style={{ margin: "5px 0px 5px 0px" }}
          disabled={!this.validateCheckoutForm()}
          onClick={this.handleCheckoutClick}
        ><FormattedMessage id="g_checkout" /></Button>
      </Popover>
    );
  }

  handleErrorDismiss() {
    this.setState({ errorShow: false, error: {} });
  }

  handleErrorShow(error) {
    this.setState({ errorShow: true, error });
  }

  renderErrorAlert() {
    if (this.state.errorShow) {
      return (
        <ErrorModal
          error={this.state.error}
          handleErrorDismiss={this.handleErrorDismiss}
        />
      );
    }
  }

  async setShipmentName(e, row) {
    // one at a time
    const data = [...this.state.data];
    const entry = data[row.index];
    let info = {};
    info[entry.shipmentId] = {
      shipmentName: e.target.innerText,
    };
    const originalText = entry.shipmentName;
    //let dataMap = this.state.dataMap;
    // Set here, revert if error
    // If only set when success, it won't revert if error happens
    // This is due to the state not changed.
    entry.shipmentName = e.target.innerText;;
    this.setState({ data });
    const {formatMessage} = this.props.intl;
    await updateInboundShipment([entry.shipmentId], info).then(response => {
      response.data.forEach(async (item) => {
        //let entry = dataMap.get(item.shipmentId);
        //entry.shipmentName = item.shipmentName;
        //this.setState({ dataMap });
        this.deselect(item.shipmentId);
      });
    }).catch((error) => {
      entry.shipmentName = originalText;
      this.setState({ data });
      if (_.has(error, "response.data.data[0].message")) {
        let err = {
          code: error.response.data.data[0].code,
          message: error.response.data.data[0].message,
        };
        console.log("err:", err);
        if (err.code === "InvalidRequestException") {
          err.code = formatMessage({id:"shp_error_invalidRequestException"});
        }
        if (err.message.includes("locked status")) {
          err.message = formatMessage({id:"shp_error_shipmentInLockedStatus"});
        }
        this.handleErrorShow(err);
      } else {
        this.handleErrorShow(error);
      }
    });
  }

  handleFilterChange = async (selectedFilterOptions) => {
    let memberInfo = this.state.memberInfo;
    let filter = [];
    selectedFilterOptions.forEach(option => {
      filter.push(option.value);
    });
    memberInfo['shipmentFilter'] = filter;
    const body = {
      shipmentFilter: filter,
    };
    await updateMemberInfo(memberInfo.email, body);
    let data = await getShipmentList(filter);
    this.setState({ data, selectedFilterOptions, memberInfo });
  }

  getFilterOptions = () => {
    let options = [];
    const { formatMessage } = this.props.intl;
    _.values(STATUS_SHIPMENT).forEach(status => {
      options.push({
        value: status,
        label: formatMessage({id: `g_status_${status}`}),
      });
    });
    return options;
  }

  renderTable() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, columns, selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (state, rowInfo) => {
        if (!rowInfo) {
          return {};
        }
        const selected = this.isSelected(rowInfo.original._id);
        return {
          style: {
            backgroundColor: selected ? "lightgreen" : "inherit",
          }
        };
      }
    };
  
    const { formatMessage } = this.props.intl;
    return (
      <Grid style={{width:"100%"}}>
        <Row>
          <Col>{this.renderErrorAlert()}</Col>
        </Row>
        <Row>
          <Col sm={2} style={{ "padding": "0px" }}>
            <LoaderButton
              bsStyle="success"
              onClick={this.handleShipmentShippedClick}
              disabled={!this.validateShipmentShippedForm()}
              isLoading={this.state.isShipmentShippedLoading}
              text={formatMessage({ id: "shp_statusShipped" })}
              loadingText={formatMessage({ id: "shp_statusShippedMarking" })}
            />
            <LoaderButton
              bsStyle="danger"
              onClick={this.handleShipmentCancelledClick}
              disabled={!this.validateShipmentCancelledForm()}
              isLoading={this.state.isShipmentCancelledLoading}
              text={formatMessage({ id: "shp_statusCancelled" })}
              loadingText={formatMessage({ id: "shp_statusCancelledMarking" })}
            />
          </Col>
          <Col sm={6} style={{ "padding": "0px" }}>
            <Select
              value={this.state.selectedFilterOptions}
              isMulti
              name="colors"
              options={this.state.filterOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={this.handleFilterChange}
            />
          </Col>
          <Col sm={4} style={{ "padding": "0px" }}>
            <LoaderButton
              className="pull-right"
              bsStyle="success"
              onClick={this.handleCheckoutClick}
              disabled={!this.validateCheckoutForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text={(this.state.tisTotalPrice ? `${formatMessage({ id: "g_checkout" })} (${this.state.tisCurrency} $${this.state.tisTotalPrice})` : formatMessage({ id: "g_checkout" }))}
              loadingText={formatMessage({ id: "g_checkingOut" })}
            />
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              overlay={this.showCartItems()}
            >
              <Button className="pull-right"><FormattedMessage id="shp_itemsInCart" /> ({this.state.selection.length})</Button>
            </OverlayTrigger>{' '}
            <LoaderButton
              className="pull-right"
              bsStyle="primary"
              onClick={this.syncShipment}
              isLoading={this.state.isSyncing}
              text={formatMessage({ id: "shp_syncShipmentWithMWS" })}
              loadingText={formatMessage({ id: "shp_syncingShipmentWithMWS" })}
              style={{marginRight: "20px"}}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckboxTable
              ref={r => (this.checkboxTable = r)}
              data={this.props.isAuthenticated ? data : []}
              columns={columns}
              noDataText={formatMessage({ id: "g_noData" })}
              defaultPageSize={10}
              minRows={2}
              className="-striped -highlight"
              collapseOnDataChange={false}
              previousText={formatMessage({ id: "g_previousText" })}
              nextText={formatMessage({ id: "g_nextText" })}
              loadingText={formatMessage({ id: "g_loadingText" })}
              pageText={formatMessage({ id: "g_pageText" })}
              ofText={formatMessage({ id: "g_ofText" })}
              rowsText={formatMessage({ id: "g_rowsText" })}
              pageJumpText={formatMessage({ id: "g_pageJumpText" })}
              rowsSelectorText={formatMessage({ id: "g_rowsSelectorText" })}
              defaultSorted={[
                {
                  id: "dateCreated",
                  desc: true,
                }
              ]}
              SubComponent={row => {
                return this.renderCartonInfoInput(row);
              }}
              {...checkboxProps}
            />
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    const { html } = this.state;
    return (
      <div>
        {this.renderTable()}
        {html && html !== ""
            ? <div>{Parser(html)}</div>
            : <div></div>
        }
      </div>
    );
  }

  getColumns = () => {
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="g_status" /></div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        let key = entry.status.toUpperCase();
        if (key === "INTRANSIT") {
          key = "IN_TRANSIT";
        } else if (key === "CHECKEDIN") {
          key = "CHECKED_IN";
        }
        return (
          <div
            style={{
              color: COLOR_SHIPMENT[key],
              padding: "5px",
            }}
          >
            <FormattedMessage id={`g_status_${entry.status}`} />
            {this.showCountdown(row, "")}
          </div>
        );
      },
      accessor: "status",
      maxWidth: 100,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="g_name" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="shp_shipmentId" /></small></span>
        </div>
      ),
      Cell: (row) => {
        // shipmentName_shipmentId: `${item.shipmentName}|${item.shipmentId}`,
        /*
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{entry.shipmentName || ""}</span><br/>
            <span style={{ color: "#aeaeae" }}>{entry.shipmentId}</span>
          </div>
        );
        */
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <div
              style={{ backgroundColor: "#fafafa", width: "100%", "white-space": "pre-wrap" }}
              contentEditable
              suppressContentEditableWarning
              onBlur={e => {this.setShipmentName(e, row)}}
              onKeyPress={e => {
                if ((e.which >= 32 && e.which <= 126) || e.which === 13) {
                  if (e.which === 13) {
                    e.preventDefault();
                    e.target.blur();
                  } else if (e.target.innerText.length >= 50) {
                    e.preventDefault();
                  }
                  return true;
                } else {
                  e.preventDefault();
                  return false;
                }
              }}
            >{entry.shipmentName}</div>
            <div style={{ color: "#aeaeae" }}>{entry.shipmentId}</div>
            { "trackingId" in entry
              ? <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={this.showTrackingInfo(row)}
                >
                  <div style={{ color: "#0365c0", cursor: "pointer" }}><Glyphicon glyph="barcode" aria-hidden="true" />{entry.trackingId}</div>
                </OverlayTrigger>
              : ""
            }
          </div>
        );
      },
      accessor: "shipmentName",
      width: 250,
      maxWidth: 250,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="shp_shipmentPlan" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="shp_msku" /></small></span>
        </div>
      ),
      Cell: (row) => {
        // planName_msku: `${item.planName}|${item.msku}`
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{entry.planName || "--"}</span><br/>
            <span style={{ color: "#aeaeae" }}>{entry.msku || ""}</span>
          </div>
        );
      },
      style: {"whiteSpace": "unset"},
      accessor: "planName",
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="g_fbaFee" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_currency" /></small></span>
        </div>
      ),
      Cell: (row) => {
        //fbaFee_currency: `${item.fbaManualProcessingFee}|${this.state.amazonCurrency}`,
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            { isNaN(entry.fbaManualProcessingFee)
            ? ""
            : <CurrencyFormat quantity={parseFloat(entry.fbaManualProcessingFee)} currency="USD" />
            }
            <br/>
            <span style={{ color: "#aeaeae" }}>{isNaN(entry.fbaManualProcessingFee) ? "" : this.state.amazonCurrency}</span>
          </div>
        );
      },
      maxWidth: 120,
      accessor: "fbaFee",
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="shp_shipping" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_currency" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            { isNaN(entry.partneredEstimate)
            ? "--"
            : <CurrencyFormat quantity={parseFloat(entry.partneredEstimate)} currency="USD" />
            }
            <br/>
            <span style={{ color: "#aeaeae" }}>{isNaN(entry.partneredEstimate) ? "" : entry.partneredEstimateCurrency}</span>
          </div>
        );
      },
      maxWidth: 120,
      accessor: "partneredEstimate",
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#dd4e41" }}>
          <span><FormattedMessage id="shp_ourCharges" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_currency" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            { isNaN(entry.estimate)
              ? "--"
              : <span style={{ color: "#dd4e41" }}><strong><CurrencyFormat quantity={parseFloat(entry.estimate)} currency="TWD" /></strong></span>
            }
            <br/>
            <span style={{ color: "#aeaeae" }}>{isNaN(entry.estimate) ? "" : entry.estimateCurrency}</span><br />
            { this.isPaid(row) || this.isVoided(row) || entry.status === STATUS_SHIPMENT.CANCELLED
              ? ""
              : <Button
                  bsStyle={!this.isSelected(row.original._id) ? "success" : "danger"}
                  disabled={this.validateAddBox(row) || !this.validateBoxInfo(row) || !this.isEstimated(row) || !this.isConfirmed(row)}
                  onClick={event => this.handleAddToCartClick(event, row)}
                  bsSize="xsmall"
                  style={{
                    margin: "2px",
                  }}
                >{!this.isSelected(row.original._id) ? <Glyphicon glyph="plus" aria-hidden="true" /> : <Glyphicon glyph="remove" aria-hidden="true" />}</Button>
            }
          </div>
        );
      },
      maxWidth: 150,
      accessor: "estimate",
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="g_dateUpdated" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_dateCreated" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateUpdated, 10)/1000}</Moment></span><br/>
            <span style={{ color: "#aeaeae" }}><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateCreated, 10)/1000}</Moment></span>
          </div>
        );
      },
      accessor: "dateCreated",
      sortMethod: (a, b) => {
        if (parseInt(a, 10) > parseInt(b, 10)) {
          return 1;
        } else {
          return -1;
        }
      },
      width: 160,
      maxWidth: 160,
      sortable: true,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="shp_received" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="shp_shipped" /></small></span>
        </div>
      ),
      Cell: row => {
        // received_shipped: `${item.received}|${item.shipped}`
        const entry = [...this.state.data][row.index];
        const shipped = isNaN(entry.shipped) ? 0 : parseInt(entry.shipped);
        const received = isNaN(entry.received) ? 0 : parseInt(entry.received);
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span
              style={{
                color: received <= 3 ? "#fd3c33" : 
                       received <= 10 ? "#fece42" : "#4372e0",
                padding: "5px",
              }}
            >{received}</span><br/>
            <span style={{ color: "#aeaeae", padding: "5px" }}>{shipped}</span>
          </div>
        )
      },
      accessor: "received",
      maxWidth: 90,
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="shp_destination" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="shp_address" /></small></span>
        </div>
      ),
      Cell: row => {
        /*
        const shipTo = item.shipTo;
        let destination_address = "--";
        if (shipTo) {
          destination_address = `${shipTo.name}|${shipTo.destinationFulfillmentCenterId}|${shipTo.address1}|${shipTo.city}, ${shipTo.stateCode} ${shipTo.postalCode}, ${shipTo.countryCode}`;
        }
        */
        const entry = [...this.state.data][row.index];
        const shipTo = entry.shipTo;
        if (shipTo) {
          // Check static table again
          const amazonHubMap = this.state.amazonHubInfoMap;

          const name = shipTo.name || "--";
          const code = shipTo.destinationFulfillmentCenterId ? ` (${shipTo.destinationFulfillmentCenterId})` : "";
          const hub = amazonHubMap.get(shipTo.destinationFulfillmentCenterId ? shipTo.destinationFulfillmentCenterId : "");
          let line1 = shipTo.address1 || (hub ? hub.address1 : null) || "";
          const addr2 = shipTo.address2 || (hub ? hub.address2 : null) || null;
          line1 = addr2 ? `${line1}, ${line2}` : line1;
          const line2 = `${shipTo.city || (hub ? hub.city : null) || "--"}, ${shipTo.stateCode || (hub ? hub.stateCode : null) || "--"} ${shipTo.postalCode || (hub ? hub.postalCode : null) || "--"}, ${shipTo.countryCode || (hub ? hub.countryCode : null) || "--"}`;

          return (
            <div style={{ padding: "5px", "textAlign": "left" }}>
              <span><strong>{name}</strong>{code}</span><br/>
              <span style={{ color: "#aeaeae" }}>{line1}</span><br />
              <span style={{ color: "#aeaeae" }}>{line2}</span>
            </div>
          );
        } else {
          return (
            <div style={{ padding: "5px", "textAlign": "left" }}>
              <span>--</span>
            </div>
          );
        }
      },
      style: {"whiteSpace": "unset"},
      accessor: "destination",
      maxWidth: 300,
      sortable: false,
      filterable: false,
    }];
  }
};

// For formatMessage
ListShipment.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ListShipment);
