import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import './App.css';

import MessageBox from "./components/MessageBox";

import Routes from "./Routes";

import { Auth } from "aws-amplify";

import Flag from "react-flags";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      messageId: "",
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== "No current user") {
        //alert(e);
        console.log("e:", e);
        this.setState({ isAuthenticating: false, messageId: "app_error_userNotAuthenticated" });
        return;
      }
    }
    this.setState({ isAuthenticating: false });
  }

  componentDidUpdate(prevProps, prevState) {
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  selectLanguage = (eventKey, event) => {
    if (eventKey === "l_english") {
      // English
      this.props.childProps.setLanguage("en", "en_US");
    } else if (eventKey === "l_traditionalChinese") {
      // Traditional Chinese
      this.props.childProps.setLanguage("zh", "zh_TW");
    } else if (eventKey === "l_simplifiedChinese") {
      // Simplified Chinese
      this.props.childProps.setLanguage("zh", "zh_CN");
    } else {
      // Default
      this.props.childProps.setLanguage("en", "en_US");
    }
  }

  handleDismiss = () => {
    this.props.history.push("/login");
    this.setState({ messageId: "" });
  }

  linkOnClick = (linkId, messageId) => {
    this.props.history.push("/login");
    this.setState({ messageId: "" });
  }

  handleSyncAmazonInventoryClick = (event) => {
    this.props.history.push("/inventory/list");
  }

  handleSyncAmazonShipmentClick = (event) => {
    this.props.history.push("/shipment/list");
  }

  render() {
    const {formatMessage} = this.props.intl;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };
    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/"><FormattedMessage id="g_siteName" /></Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullLeft>
              {this.state.isAuthenticated
                ? <Fragment>
                    <LinkContainer to="/inventory/list">
                      <NavItem><FormattedMessage id="m_inventory" /></NavItem>
                    </LinkContainer>
                    <LinkContainer to="/shipment/list">
                      <NavItem><FormattedMessage id="m_shipment" /></NavItem>
                    </LinkContainer>
                    <LinkContainer to="/order/list">
                      <NavItem><FormattedMessage id="m_order" /></NavItem>
                    </LinkContainer>
                    {/*
                    <NavDropdown eventKey={1} title={formatMessage({id:"m_syncAmazon"})} id="sync-dropdown">
                      <MenuItem eventKey={1.1} onClick={(event) => this.handleSyncAmazonInventoryClick(event)}><FormattedMessage id="m_syncAmazonInventory"/></MenuItem>
                      <MenuItem eventKey={1.2} onClick={(event) => this.handleSyncAmazonShipmentClick(event)}><FormattedMessage id="m_syncAmazonShipment"/></MenuItem>
                    </NavDropdown>
                    */}
                  </Fragment>
                : ""
              }
            </Nav>
            <Nav pullRight>
              <Fragment>
                <NavDropdown eventKey={2} title={formatMessage({ id: "m_language"})} id="language-dropdown" onSelect={this.selectLanguage}>
                  <MenuItem eventKey={"l_english"}><Flag basePath="/img/flags" name="US" format="png" pngSize={16} shiny={true} alt="English" />{' '}<FormattedMessage id="m_language_english" /></MenuItem>
                  <MenuItem eventKey={"l_traditionalChinese"}><Flag basePath="/img/flags" name="TW" format="png" pngSize={16} shiny={true} alt="Traditional Chinese" />{' '}<FormattedMessage id="m_language_traditionalChinese" /></MenuItem>
                  <MenuItem eventKey={"l_simplifiedChinese"}><Flag basePath="/img/flags" name="CN" format="png" pngSize={16} shiny={true} alt="Simplified Chinese" />{' '}<FormattedMessage id="m_language_simplifiedChinese" /></MenuItem>
                </NavDropdown>
              </Fragment>
              {this.state.isAuthenticated
                ? <Fragment>
                    <NavDropdown eventKey={1} title={formatMessage({ id: "m_member"})} id="member-dropdown">
                      <LinkContainer to="/member/update">
                        <MenuItem eventKey={1.1}><FormattedMessage id="m_member_updateInfo" /></MenuItem>
                      </LinkContainer>
                      <LinkContainer to="/member/authToken">
                        <MenuItem eventKey={1.2}><FormattedMessage id="m_member_provideAuthToken" /></MenuItem>
                      </LinkContainer>
                      <LinkContainer to="/member/payment">
                        <MenuItem eventKey={1.3}><FormattedMessage id="m_member_paymentInfo" /></MenuItem>
                      </LinkContainer>
                    </NavDropdown>
                    <NavItem onClick={this.handleLogout}><FormattedMessage id="m_logout" /></NavItem>
                  </Fragment>
                : <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem><FormattedMessage id="m_signup" /></NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem><FormattedMessage id="m_login" /></NavItem>
                    </LinkContainer>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <MessageBox bsStyle="danger" handleDismiss={this.handleDismiss} messageId={this.state.messageId} linkId="m_login" linkOnClick={this.linkOnClick} />
        <Routes childProps={childProps} />
      </div>
    );
  }
}

// For formatMessage
App.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(withRouter(App));
