const zh_TW = {
  // Global
  g_siteName: "追蹤物流",
  g_siteSlogan: "低價，準時，便捷",

  g_error: "錯誤",
  g_queueEmpty: "空佇列",
  g_cartEmpty: "空購物車",
  g_noTracking: "無追蹤資訊",
  g_addToCart: "加入購物車",
  g_removeFromCart: "從購物車移除",
  g_generateCommercialInvoice: "商業發票",
  g_generatePackingList: "包裝清單",
  g_noData: "無資料",
  g_dateCreated: "創建日期",
  g_dateUpdated: "狀況更新日期",
  g_fbaFee: "雅馬遜物流費",
  g_currency: "幣種",
  g_checkout: "結帳",
  g_checkout_again: "再次結帳",
  g_checkingOut: "結帳中",
  g_status: "狀態",
  g_name: "名稱",
  g_success: "確認",
  g_info: "資訊",
  g_danger: "錯誤",
  g_warning: "警告",
  g_days: "天",
  g_hours: "小時",
  g_minutes: "分鐘",
  g_seconds: "秒",

  g_status_calculating: "計算中",
  g_status_incomplete: "資訊不完整",
  g_status_inactive: "未啟動",
  g_status_active: "啟動",
  g_status_new: "新建",
  g_status_working: "處理中",
  g_status_estimated: "已估價",
  g_status_confirmed: "已確認估價",
  g_status_voided: "已廢止估價",
  g_status_downloaded: "已下載",
  g_status_unpaid: "尚未付款",
  g_status_paid: "已付款",
  g_status_shipped: "已寄送",
  g_status_inTransit: "運送中",
  g_status_delivered: "已送達",
  g_status_error: "錯誤",
  g_status_cancelled: "已取消",
  g_status_closed: "已關閉",
  g_status_deleted: "已刪除",
  g_status_checkedIn: "已收貨",
  g_status_receiving: "收貨中",

  g_previousText: "上一頁",
  g_nextText: "下一頁",
  g_loadingText: "載入中",
  g_noDataText: "無資料",
  g_pageText: "頁",
  g_ofText: "之",
  g_rowsText: "列",
  g_pageJumpText: "跳至頁",
  g_rowsSelectorText: "每頁列數",

  g_length: "長",
  g_width: "寬",
  g_height: "高",
  g_deep: "深",

  // Menu
  m_inventory: "庫存物件",
  m_shipment: "寄送商品",
  m_order: "寄送訂單",
  m_member: "會員專區",
  m_member_updateInfo: "更新個人資訊",
  m_member_provideAuthToken: "提供雅馬遜授權碼",
  m_member_paymentInfo: "付款資訊",
  m_language: "語言",
  m_language_english: "英文",
  m_language_traditionalChinese: "繁體中文",
  m_language_simplifiedChinese: "簡體中文",
  m_language_japanese: "日文",
  m_language_korean: "韓文",
  m_login: "登入",
  m_logout: "登出",
  m_signup: "註冊",
  m_syncAmazon: "與雅馬遜同步",
  m_syncAmazonInventory: "雅馬遜庫存",
  m_syncAmazonShipment: "雅馬遜貨件",

  // Login/Logout/Signup
  l_email: "電子郵件",
  l_password: "密碼",
  l_login: "登入",
  l_loggingIn: "登入中",
  l_confirmPassword: "確認密碼",
  l_signup: "註冊",
  l_signingUp: "註冊中",
  l_signup_helpBlock: "請檢查郵件以取得認證碼",
  l_confirmationCode: "請輸入認證碼",
  l_verify: "認證",
  l_verifying: "認證中",
  l_firstName: "名字",
  l_lastName: "姓氏",
  l_phone: "電話或手機",

  l_error_invalidPasswordException: "密碼不符合規格，密碼必須有特殊字元。",
  l_error_usernameExistsException: "此郵件已經註冊過。",
  l_error_userNotFoundException: "用戶不存在。",
  l_error_notAuthorizedException: "用戶或密碼不正確。",
  l_error_codeMismatchException: "確認碼不正確，請重試。",
  l_error_dismiss: "確定",
  l_error_unknownError: "未知的錯誤",

  // Home
  hm_label_inventoryStatistics: "庫存狀態",
  hm_label_shipmentStatistics: "貨件狀態",
  hm_label_quantityStatistics: "出貨狀態",
  hm_label_quantityByMonthStatistics: "每月出貨狀態",
  hm_label_quantityByMonth: "每月出貨數量",
  hm_label_quantity: "出貨數量",
  hm_label_quantityReceived: "已收到",
  hm_label_quantityShipped: "已寄送",

  // Inventory
  inv_image: "圖片",
  inv_sku: "SKU",
  inv_condition: "狀況",
  inv_productName: "商品名稱",
  inv_asin: "ASIN",
  inv_inStockQuantity: "可售",
  inv_totalQuantity: "總共",
  inv_feePreview: "費用預覽",
  inv_price: "價格",
  inv_shipping: "配送費",
  inv_lowestPrice: "最低價",
  inv_includes: "包括",
  inv_createShipmentPlanfromSelectedItems: "創建發貨單",
  inv_creatingShipmentPlanfromSelectedItems: "創建發貨單",
  inv_itemsInQueue: "佇列中商品",
  inv_syncInventoryWithMWS: "與雅馬遜庫存同步",
  inv_syncingInventoryWithMWS: "與雅馬遜庫存同步中",
  inv_provideInformationForItemsToBeShipped: "請提供要寄送物品的資訊",
  inv_numberOfItems: "件數",
  inv_itemsPerBox: "每盒中件數",
  inv_itemsCondition: "物品狀況",
  inv_addToQueue: "加入佇列",
  inv_reset: "重置",
  inv_addItemsBeforeCreatingShipmentPlan: "在創建發貨單前請先加入物品到佇列",
  inv_createTooltip: "請先加入要寄送的商品",
  inv_amount: "總數",
  inv_condition_newItem: "新品",
  inv_condition_newWithWarranty: "保固新品",
  inv_condition_newOEM: "代工新品",
  inv_condition_newOpenBox: "開箱新品",
  inv_condition_usedLikeNew: "二手近新品",
  inv_condition_usedVeryGood: "二手狀況很好",
  inv_condition_usedGood: "二手狀況好",
  inv_condition_usedAcceptable: "二手狀況可接受",
  inv_condition_usedPoor: "二手狀況差",
  inv_condition_usedRefurbished: "二手翻新",
  inv_condition_collectibleLikeNew: "Collectible Like New",
  inv_condition_collectibleVeryGood: "收藏品狀況很好",
  inv_condition_collectibleGood: "收藏品狀況好",
  inv_condition_collectibleAcceptable: "收藏品狀況可接受",
  inv_condition_collectiblePoor: "收藏品狀況差",
  inv_condition_refurbishedWithWarranty: "保固翻新品",
  inv_condition_refurbished: "翻新品",
  inv_condition_club: "Club",

  // Shipment
  shp_shipmentId: "貨件編號",
  shp_shipmentPlan: "入庫計畫",
  shp_msku: "MSKU",
  shp_shipping: "雅馬遜寄送費", 
  shp_ourCharges: "我們的收費",
  shp_received: "已收到",
  shp_shipped: "已寄送",
  shp_destination: "目的地",
  shp_address: "地址",
  shp_itemsInCart: "購物車內容",
  shp_trackingInfo: "追蹤貨物資訊",
  shp_price: "價格",
  shp_total: "總額",
  shp_oilPrice: "燃油附加費",
  shp_boxUnit: "箱",
  shp_netWeight: "淨重",
  shp_grossWeight: "毛重",
  shp_itemWeightUnit: "公斤",
  shp_estimateTooltip: "雅馬遜費用預估大概會花<strong><font color='red'>50</font></strong>秒",
  shp_countdownTooltip_head: "您可以在",
  shp_countdownTooltip_tail: "內取消運送以避免被收費。",

  shp_provideCartonInformation: "請提供紙箱資訊",
  shp_shippingMethod: "寄送方法",
  shp_shippingCarrier: "貨運公司",
  shp_shippingService: "寄送服務",
  shp_amazonPartneredCarrier: "與雅馬遜合作的貨運公司",
  shp_spd: "小包裹運送",
  shp_spd_desc: "我要寄送獨立箱子",
  shp_ltl: "零擔運送",
  shp_ltl_desc: "我要運送小於150磅的棧板",
  shp_ftl: "整車運送",
  shp_ftl_desc: "我要運送大於150磅的棧板",

  shp_ups: "UPS",
  shp_ups_desc: "優比速",
  shp_fedex: "FedEx",
  shp_fedex_desc: "聯邦快遞",
  shp_usps: "USPS",
  shp_usps_desc: "美國郵政署",
  shp_dhl: "DHL",
  shp_dhl_desc: "DHL",

  shp_srv_dhl: "DHL",
  shp_srv_dhl_desc: "最快速，大約花兩天時間送達。",
  shp_srv_flight: "空運",
  shp_srv_flight_desc: "最划算，大約花三到五天送達。",
  shp_srv_ship: "海運",
  shp_srv_ship_desc: "最便宜，大約花三週送達。",

  shp_addBox: "加入紙箱",
  shp_estimateAmazonPrice: "1. 預估雅馬遜費用",
  shp_estimatingAmazonPrice: "預估中",
  shp_confirmAmazonPrice: "2. 確認雅馬遜費用",
  shp_confirmingAmazonPrice: "確認中",
  shp_voidAmazonPrice: "取消雅馬遜費用",
  shp_voidingAmazonPrice: "取消中",
  shp_downloadShippingLabel: "3. 下載寄件標籤",
  shp_downloadingShippingLabel: "下載中",

  shp_box: "紙箱",
  shp_cartonInfo: "紙箱資訊",
  shp_boxDimension: "紙箱長寬高",
  shp_boxWeight: "紙箱重量",
  shp_fomula: "可計價重量 x 單價 = 總價",
  shp_fomula2: "重量 x 單價 = 總價",
  shp_unit: "單位",
  shp_dimensionUnit: "長寬高單位",
  shp_weightUnit: "重量單位",
  shp_itemsInThisBox: "箱中的物品",
  shp_itemUnit: "商品單位",
  shp_addItemsToThisBox: "加入物品至箱中",
  shp_itemsInThisBox: "箱中物品",
  shp_perBox: "每箱",
  shp_amount: "總箱數",
  shp_addItems: "加入物品",
  shp_unitPrice: "單價",
  shp_totalPrice: "總價",

  shp_statusCancelled: "取消寄送",
  shp_statusShipped: "標為已寄送",
  shp_statusCancelledMarking: "取消中",
  shp_statusShippedMarking: "標示中",

  shp_syncShipmentWithMWS: "與雅馬遜貨件同步",
  shp_syncingShipmentWithMWS: "與雅馬遜貨件同步中",

  shp_error_invalidRequestException: "不合法的操作",
  shp_error_shipmentInLockedStatus: "貨件在鎖定狀態",

  shp_tracking_time: "時間",
  shp_tracking_location: "地點",
  shp_tracking_description: "說明",
  shp_tracking_number: "追蹤號碼",
  shp_tracking_eta: "預計抵達時間",
  shp_tracking_destination: "目的地",

  // Invoice
  ivc_downloadInvoice:"下載商業發票", 
  ivc_downloadingInvoice:"生成商業發票中",

  // Packing List
  ivc_downloadPackingList:"下載包裝清單", 
  ivc_downloadingPackingList:"生成包裝清單中",

  // Order
  ord_status: "狀態",
  ord_orderId: "訂單號碼",
  ord_contents: "內容",
  ord_grossChargeableWeight: "總重/可計價總重",
  ord_numOfPieces: "件數",
  ord_price: "總價",
  ord_hawbCarrier: "提單號碼",
  ord_destination: "目的地",
  ord_senderInformation: "寄件者資訊",
  ord_hubInformation: "轉運中心資訊",

  // Member
  mbr_save: "儲存",
  mbr_saving: "儲存中",
  mbr_reset: "重置",
  mbr_resetting: "重置中",
  mbr_generalInformation: "基本資訊",
  mbr_email: "帳號電子郵件",
  mbr_backupEmail: "備用電子郵件",
  mbr_password: "帳號密碼",
  mbr_lineId: "Line帳號",
  mbr_wechatId: "微信帳號",
  mbr_companyInformation: "公司資訊",
  mbr_company: "公司名稱",
  mbr_companyIdNumber: "統一編號",
  mbr_firstName: "負責人名",
  mbr_lastName: "負責人姓",
  mbr_cell: "手機號碼",
  mbr_phone: "電話號碼",
  mbr_fax: "傳真號碼",
  mbr_address: "公司地址",
  mbr_city: "城市",
  mbr_stateCode: "省份",
  mbr_zipCode: "郵遞區號",
  mbr_countryCode: "國家",

  mbr_amazonMarketplaceAuthenticationToken: "雅馬遜授權",
  mbr_sellerID: "賣家帳號",
  mbr_token: "授權碼",
  mbr_noteTitle: "關於授權碼",
  mbr_noteLine1: "雅馬遜授權碼必須每年更新，且這個步驟是必須的。請到賣家中心：",
  mbr_noteLine2: "設置 > 用戶權限 > 授權某個開發人員",
  mbr_noteLine3: "來取得授權碼。請用以下開發者訊息來申請授權碼：",
  mbr_developerName: "開發商名稱",
  mbr_developerID: "開發者ID",
  mbr_dismiss: "確定",
  mbr_close: "關閉",
  mbr_modalTitle: "授權碼儲存成功",
  mbr_modalLine1: "雅馬遜賣家ID與授權碼儲存成功",
  mbr_modalLine2: "授權碼必須每年更新。請用下面的開發者ID來申請授權碼。",

  mbr_paymentInformation: "付款資訊",
  mbr_creditCard: "信用卡號",
  mbr_creditCardName: "信用卡名稱",
  mbr_creditCardPostalCode: "信用卡郵遞區號",
  mbr_creditCardPhone: "信用卡電話",

  // App
  app_updateGeneralInfo: "請更新基本資訊",
  app_updateMwsInfo: "請提供您的賣家ID和授權碼",
  app_udpatePaymentInfo: "請提供付款資訊",

  app_error_userNotAuthenticated: "使用者尚未認證/登入",

  // Home
  hm_fixItNow: "現在就修正",

  // FrontPage
  fp_globalShippingTitle: "電商庫存快速入庫服務",
  fp_globalShippingContent: "我們的目標是幫助客戶把商品快速的運送到電商的庫存站點內，並且提供整合一站式的服務管理電商庫存",
  fp_smartInventoryTitle: "智慧型庫存管理",
  fp_smartInventoryContent: "我們可以同步雅馬遜的庫存，並且自動產生捕獲要求，我們會幫你整合國際物流與雅馬遜物流",
  fp_tryItOutTitle: "現在就試試？",
  fp_tryItOutContent: "如有任何問題歡迎與我們聯絡",
  fp_signUp: "馬上註冊",
  fp_login: "已有帳號？立即登入",
};

export default zh_TW;

