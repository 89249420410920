import React, { Component } from "react";

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Grid,
  Row,
  Col,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
  Image,
} from "react-bootstrap";

import CurrencyFormat from 'react-currency-formatter';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import DateFormat from "dateformat";

import { countries } from "country-data";

import {
  checkNullS,
  checkNullN,
  getStaticData,
  getShipmentList,
  getInventoryList,
  deleteKeyShipment,
  updateShipment,
  getShipment,
  makeid,
  partition,
  partitionN,
  getElementsStartsWithId,
  listMemberInfo,
} from "../utils";

import html2canvas from 'html2canvas';

import * as jsPDF from 'jspdf';

import "./ShipmentPackingList.css";

import CompanyBigImg from "../img/CompanyBig.jpg";
import CompanySmallImg from "../img/CompanySmall.jpg";
import InvoiceStampImg from "../img/InvoiceStamp.jpg";
import LoaderButton from "../components/LoaderButton";

let MAX_ENTRIES = 4;
let MAX_HEIGHT = 500;
let STAMP_HEIHGT = 100;
let flag = false;

class ShipmentPackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenerating: false,
    };
    console.log("props:", this.props);
  }

  componentDidMount() {
    const tagItems = getElementsStartsWithId("ITEM");
    const pdfHeight = this.computeElementHeight("PDF") - 30 - 90; // 30 for Grid padding, 90 for elemtns border 1px x2 x # of rows
    const headerHeight = this.computeElementHeight("HEADER");
    const titleHeight = this.computeElementHeight("TITLE");
    const totalHeight = this.computeElementHeight("TOTAL");
    const footerHeight = this.computeElementHeight("FOOTER");
    const contentHeight = this.computeElementHeight("CONTENT");
    const emptyHeight = this.computeElementHeight("EMPTY") || 36;
    
    let height = 0;
    let content = [];
    let segment = [];
    let counter = 0;
    MAX_HEIGHT = pdfHeight - headerHeight - titleHeight;
    tagItems.forEach((item, index) => {
      if (segment.length > 0) {
        MAX_HEIGHT = pdfHeight - headerHeight;
      }
      height += item.clientHeight;
      if (height <= MAX_HEIGHT) {
        counter++;
      } else {
        segment.push(counter);
        counter = 1;
        height = item.clientHeight;
      }
    });
    if (counter > 0) {
      if (MAX_HEIGHT - height >= totalHeight + footerHeight) {
        const padding = Math.floor((MAX_HEIGHT - height - totalHeight - footerHeight) / emptyHeight);
        segment.push(counter + padding);
      } else {
        let padding = Math.floor((MAX_HEIGHT - height) / emptyHeight);
        segment.push(counter + padding);
        MAX_HEIGHT = pdfHeight - totalHeight - footerHeight;
        padding = Math.floor(MAX_HEIGHT / emptyHeight);
        segment.push(padding);
      }
    }
    const heights = {
      pdfHeight,
      headerHeight,
      titleHeight,
      totalHeight,
      footerHeight,
      contentHeight,
      emptyHeight,
    };
    //this.setState({ heights: totalHeight, segment });
    this.setState({ heights, segment });
  }

  printPackingList = () => {
    this.setState({ isGenerating: true });
    const { entry } = this.props;
    const inputs = getElementsStartsWithId("PDF");
    const pdf = new jsPDF();
    inputs.forEach((input, index) => {
      html2canvas(input, {scale:1}).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'JPEG', 0, 0);
        if (index == inputs.length-1) {
          pdf.save(`PackingList-${entry.shipmentId}.pdf`);
          this.setState({ isGenerating: false });
        } else {
          pdf.addPage();
        }
      });
    });
  }

  computeElementHeight = (tag) => {
    const tagItems = getElementsStartsWithId(tag);
    //console.log(tag, tagItems);
    let heights = 0;
    tagItems.forEach((item, index) => {
      heights += item.clientHeight;
    });
    return heights;
  }

  displayUnit = (unit) => {
    if (unit === "centimeters") {
      return "cm";
    } else if (unit === "inches") {
      return "in";
    } else if (unit === "kilograms") {
      return "kg";
    } else if (unit === "pounds") {
      return "lb";
    }
    return "";
  }

  showItems = (entry, inventoryMap) => {
    const cartons = entry.cartons;
    let output = [];
    let i = 0;
    cartons.forEach((carton) => {
      const items = carton.items;
      items.forEach((item) => {
        const inventory = inventoryMap.get(item.sku);
        const dimension = (item.length > 0 && item.width > 0 && item.height > 0) ? `${item.length}x${item.width}x${item.height} ${this.displayUnit(carton.dunit)}` : "";
        output.push(
          <Row key={`CONTENT-{i}`} id={`CONTENT-{i}`} className="ShipmentPackingList-Row row-eq-height">
            <Col sm={1} className="ShipmentPackingList-Col content filled"
              style={{ borderLeft: "1px solid #aaa" }}
              id={`ITEM-{i}`}>
              {`#${i+1}`}
            </Col>
            <Col sm={5} className="ShipmentPackingList-Col content filled">SKU: {inventory.sku}<br/>{inventory.productName}</Col>
            <Col sm={2} className="ShipmentPackingList-Col content filled right">{item.quantityInCase * item.quantityShipped} {item.itemUnit}</Col>
            <Col sm={1} className="ShipmentPackingList-Col content filled right">{item.netWeight} {this.displayUnit(carton.wunit)}</Col>
            <Col sm={1} className="ShipmentPackingList-Col content filled right">{item.grossWeight} {this.displayUnit(carton.wunit)}</Col>
            <Col sm={2} className="ShipmentPackingList-Col content filled right">{dimension}</Col>
          </Row>
        );
        i++;
      });
    });
    return output;
  }

  renderPDF(index, length, content) {
    let output = [];
    let flag = (length === index + 1);
    console.log(`index: ${index}, length: ${length} (${flag})`);
    output.push(
      <Grid id={`PDF-${index}`} className="ShipmentPackingList-Grid">
        <Row id={`HEADER-${index}`} className="ShipmentPackingList-Row">
          <Col sm={4} smOffset={4} className="ShipmentPackingList-Col"><h4 className="title">PackingList</h4></Col>
          <Col sm={3} smOffset={1} className="ShipmentPackingList-Col right">{`Page ${index+1} of ${length}`}</Col>
        </Row>
        {index === 0 ? this.getTitleRow(index) : ""}
        {content}
        {flag ? this.getTotalRow(index) : ""}
        {flag ? this.getFooterRow(index) : ""}
      </Grid>
    );
    return output;
  }

  render() {
    const { entry, hubInfo, inventoryMap } = this.props;
    let content = [];
    const o = this.showItems(entry, inventoryMap);
    if (this.state.segment && flag) {
      let output = [];
      let segment = this.state.segment;
      let counter = 0;
      let padding = segment[0];
      /*
      segment.forEach((s) => {
        padding += s;
      });
      */
      o.forEach((item, index) => {
        counter++;
        padding--;
        if (counter <= segment[0]) {
          content.push(item);
        } else {
          output.push(content);

          counter = 0;
          segment = segment.slice(1, segment.length);
          padding = segment[0];
          content = [];
          content.push(item);
        }
      });
      if (content.length > 0) {
        if (segment.length > 1) {
          content.push(...this.genEmptyRow(padding));
          output.push(content);
          segment = segment.slice(1, segment.length);
          content = [];
          content.push(...this.genEmptyRow(segment[0]));
          output.push(content);
        } else {
          content.push(...this.genEmptyRow(padding));
          output.push(content);
        }
      }

      content = [];
      output.forEach((item, index) => {
        content.push(this.renderPDF(index, output.length, item));
      });
    } else {
      content = this.renderPDF(0, 1, [...o, this.genEmptyRow(1)]);
      flag = true;
    }

    const {formatMessage} = this.props.intl;
    return (
      <div>
        <div>
          <LoaderButton
            style={{ marginBottom: "10px", marginLeft: "37px" }}
            bsStyle="primary"
            bsSize="xsmall"
            onClick={this.printPackingList}
            isLoading={this.state.isGenerating}
            text={formatMessage({ id: "ivc_downloadPackingList" })}
            loadingText={formatMessage({ id: "ivc_downloadingPackingList" })}
          />
        </div>
        <div>
          {content}
        </div>
      </div>
    );
  }

  getTotalRow = (index) => {
    const { entry } = this.props;
    let totalPrice = 0;
    let totalUnitPcs = 0;
    let totalUnitSets = 0;
    let currency = "";
    let wunit = "";
    let totalNetWeight = 0;
    let totalGrossWeight = 0;
    let items = 0;
    entry.cartons.forEach(carton => {
      if (wunit === "") {
        wunit = carton.wunit;
      }
      carton.items.forEach(item => {
        if (currency === "") {
          currency = item.currency;
        }
        if (item.itemUnit === "pcs") {
          totalUnitPcs += item.quantityShipped * item.quantityInCase;
        } else {
          totalUnitSets += item.quantityShipped * item.quantityInCase;
        }
        totalPrice += item.totalPrice;
        totalNetWeight += item.netWeight;
        totalGrossWeight += item.grossWeight;
        items++;
      });
    });
    let unit = [];
    if (totalUnitPcs > 0) {
      unit.push(<span>{`${totalUnitPcs} pcs`}</span>);
    }
    if (totalUnitSets > 0) {
      unit.push(<span>{`,${totalUnitSets} sets`}</span>);
    }

    return (
      <Row id={`TOTAL-${index}`} className="ShipmentPackingList-Row">
        <Col sm={1} className="ShipmentPackingList-Col footer" style={{ "border-left": "1px solid #aaa" }}>Total</Col>
        <Col sm={5} className="ShipmentPackingList-Col footer">{items} items</Col>
        <Col sm={2} className="ShipmentPackingList-Col footer right">{unit}</Col>
        <Col sm={1} className="ShipmentPackingList-Col footer right">{totalNetWeight} {this.displayUnit(wunit)}</Col>
        <Col sm={1} className="ShipmentPackingList-Col footer right">{totalGrossWeight} {this.displayUnit(wunit)}</Col>
        <Col sm={2} className="ShipmentPackingList-Col footer" style={{ "border-right": "1px solid #aaa" }}></Col>
      </Row>
    );
  }

  getTitleRow = (index) => {
    const { entry, hubInfo, inventoryMap, shippingService, memberInfo } = this.props;
    let output = [];
    let arr = [];
    entry.cartons.forEach(carton => {
      carton.items.forEach(item => {
        arr.push(`${item.sku} x ${item.quantityShipped * item.quantityInCase}`);
      });
    });
    const invoiceOf = arr.join(", ");
    output.push(
      <Row id={`TITLE-1-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">No.</Col>
        <Col sm={2} className="ShipmentPackingList-Col underline">{entry.shipmentId}</Col>
        <Col sm={1} smOffset={5} className="ShipmentPackingList-Col">Date:</Col>
        <Col sm={2} className="ShipmentPackingList-Col underline">{DateFormat(new Date(), "yyyy/mm/dd")}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-2-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">PackingList of</Col>
        <Col sm={10} className="ShipmentPackingList-Col underline">{invoiceOf}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-3-${index}`} className="ShipmentPackingList-Row">
        <Col sm={3} className="ShipmentPackingList-Col" style={{ fontSize: "13px" }}>For account and risk of Messrs</Col>
        <Col sm={5} className="ShipmentPackingList-Col underline">{hubInfo.name}</Col>
        <Col sm={2} offset={2} className="ShipmentPackingList-Col">Marks &amp; Nos.</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-4-${index}`} className="ShipmentPackingList-Row">
        <Col sm={8} className="ShipmentPackingList-Col underline">{`${hubInfo.address1} ${hubInfo.address2 || ""}, ${hubInfo.city}, ${hubInfo.stateCode} ${hubInfo.postalCode}, ${countries[hubInfo.countryCode]['name']}`}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-7-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col" style={{ fontSize: "13px" }}>Sailing on or about</Col>
        <Col sm={6} className="ShipmentPackingList-Col underline">{DateFormat(new Date(), "yyyy/mm/dd")}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-5.1-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">Shipped by</Col>
        <Col sm={6} className="ShipmentPackingList-Col underline">{memberInfo ? memberInfo.company : ""}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-5.2-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">Per</Col>
        <Col sm={6} className="ShipmentPackingList-Col underline">{this.props.shippingService ? this.props.shippingService.provider : ""}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-6.1-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">From</Col>
        <Col sm={6} className="ShipmentPackingList-Col underline">{`${memberInfo.city}, ${memberInfo.stateCode}, ${countries[memberInfo.countryCode]['name']}`}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-6.2-${index}`} className="ShipmentPackingList-Row">
        <Col sm={2} className="ShipmentPackingList-Col">To</Col>
        <Col sm={6} className="ShipmentPackingList-Col underline">{`${hubInfo.city}, ${hubInfo.stateCode}, ${countries[hubInfo.countryCode]['name']}`}</Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-8-${index}`} className="ShipmentPackingList-Row">
        <Col><br/></Col>
      </Row>
    );
    output.push(
      <Row id={`TITLE-9-${index}`} className="ShipmentPackingList-Row">
        <Col sm={1} className="ShipmentPackingList-Col header" style={{ "border-left": "1px solid #aaa" }}>No.</Col>
        <Col sm={5} className="ShipmentPackingList-Col header">Description of Goods</Col>
        <Col sm={2} className="ShipmentPackingList-Col header">Quantity</Col>
        <Col sm={1} className="ShipmentPackingList-Col header">N.W.</Col>
        <Col sm={1} className="ShipmentPackingList-Col header">G.W.</Col>
        <Col sm={2} className="ShipmentPackingList-Col header">Measurement</Col>
      </Row>
    );
    return output;
  }

  getFooterRow = (index) => {
    let output = [];
    for (let i = 0; i < 0; i++) {
      output.push(
        <Row className="ShipmentPackingList-Row">
          <Col><br/></Col>
        </Row>
      );
    }
    output.push(
      <Row id={`FOOTER-${index}`} className="ShipmentPackingList-Row align-self-end">
        <Col sm={1} className="ShipmentPackingList-Col-Stamp">Signature</Col>
        <Col sm={3} className="ShipmentPackingList-Col-Stamp" style={{ borderBottom: "1px solid #aaa" }}></Col>
        <Col sm={3} className="ShipmentPackingList-Col-Stamp"><Image src={CompanyBigImg} style={{ width:"1.60in", height:"1.41in", opacity: "0.3" }} alt="Company Big Stamp" /></Col>
        <Col sm={2} className="ShipmentPackingList-Col-Stamp"><Image src={CompanySmallImg} style={{ wodth:"0.72in", height:"0.68in", opacity: "0.3" }} alt="Company Small Stamp" /></Col>
        <Col sm={3} className="ShipmentPackingList-Col-Stamp"><Image src={InvoiceStampImg} style={{ width:"1.95in", height:"1.31in", opacity: "0.3" }} alt="Invoice Stamp" /></Col>
      </Row>
    );
    return output;
  }

  genEmptyRow = (number) => {
    let output = [];
    for (let i = 0; i < number; i++) {
      output.push(
        <Row id={`EMPTY-{i}`} key={i} className="ShipmentPackingList-Row">
          <Col sm={1} className="ShipmentPackingList-Col content empty" style={{ borderLeft: "1px solid #aaa" }}></Col>
          <Col sm={5} className="ShipmentPackingList-Col content empty"></Col>
          <Col sm={2} className="ShipmentPackingList-Col content empty"></Col>
          <Col sm={1} className="ShipmentPackingList-Col content empty"></Col>
          <Col sm={1} className="ShipmentPackingList-Col content empty"></Col>
          <Col sm={2} className="ShipmentPackingList-Col content empty"></Col>
        </Row>
      );
    }
    return output;
  }
};

// For formatMessage
ShipmentPackingList.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ShipmentPackingList);
