import React, { Component } from "react";

import {
  Alert,
  Glyphicon
} from "react-bootstrap";

import {
  //  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';

class ErrorAlert extends Component {
  // Error Handling
  render() {
    const { formatMessage } = this.props.intl;
    if (this.props.error !== "") {
      let error = {
        content: formatMessage({ id: "g_error" }),
      };
      const code = `l_error_${this.props.error.charAt(0).toLowerCase()}${this.props.error.slice(1)}`;
      console.log("code:", code);
      error = {
        title: formatMessage({ id: "g_error" }),
        content: formatMessage({ id: code }),
      };
      return (
        <div className="Alert">
          <Alert bsStyle="danger" onDismiss={this.handleErrorDismiss}>
            {
              this.props.error !== ""
              ? <h4><Glyphicon bsSize="xsmall" glyph="exclamation-sign" aria-hidden="true" />{' '}{error.title}</h4>
              : ""
            }
            <p>
              {error.content}
            </p>
          </Alert>
        </div>
      );
    } else {
      return "";
    }
  }

  handleErrorDismiss = () => {
    if (this.props.handleErrorDismiss) {
      this.props.handleErrorDismiss();
    }
  }
};

// For formatMessage
ErrorAlert.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ErrorAlert);
