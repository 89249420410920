import React, { Component } from "react";
import "./FrontPage.css";

import { withRouter } from "react-router-dom";

import { 
  Glyphicon,
  Carousel,
  Col,
  Row,
  Image,
  Jumbotron,
  Button,
} from "react-bootstrap";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import MessageBox from "../components/MessageBox";

class FrontPage extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      index: 0,
      direction:null,
    };
  }

  componentDidMount() {
  }

  handleSelect = (selectedIndex, e) => {
    alert(`selected=${selectedIndex}, direction=${e.direction}`);
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  handleSignUp = () => {
    this.props.history.push("/signup");
  }

  handleLogin = () => {
    this.props.history.push("/login");
  }

  render() {
    return (
      <div>
        <Row style={{ padding: "50px 100px 50px 100px" }}>
          <Col sm={7}>
            <Jumbotron style={{ backgroundColor: "white"  }}>
              <h1><FormattedMessage id="fp_globalShippingTitle" /></h1>
              <br/>
              <p><FormattedMessage id="fp_globalShippingContent" /></p>
            </Jumbotron>
          </Col>
          <Col sm={5}>
            <Image src="/global_shipping.png" rounded responsive />
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#eeeeee", padding: "50px 100px 50px 100px" }}>
          <Col sm={5}>
            <Image src="/smart_inventory.png" rounded responsive />
          </Col>
          <Col sm={7}>
            <Jumbotron>
              <h1><FormattedMessage id="fp_smartInventoryTitle" /></h1>
              <br/>
              <p><FormattedMessage id="fp_smartInventoryContent" /></p>
            </Jumbotron>
          </Col>
        </Row>
        <Row style={{ padding: "50px 100px 50px 100px" }}>
          <Col sm={7}>
            <Jumbotron style={{ backgroundColor: "white"  }}>
              <h1><FormattedMessage id="fp_tryItOutTitle" /></h1>
              <br/>
              <p><FormattedMessage id="fp_tryItOutContent" /></p>
            </Jumbotron>
          </Col>
          <Col sm={5}>
            <div className="center-block" style={{ padding: "100px 50px 100px 50px" }}>
              <Button bsStyle="primary" bsSize="large" onClick={this.handleSignUp}><FormattedMessage id="fp_signUp" /></Button>{' '}
              <Button bsSize="large" onClick={this.handleLogin}><FormattedMessage id="fp_login" /></Button>
            </div>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#eeeeee", padding: "50px 100px 50px 100px" }}>
          <Col sm={12}>
            <ul class="list-inline">
              <li><Image src="/favicon-16x16.png" rounded responsive inline /></li>
              <li>© 2018 TrackItShipped, Inc.</li>
              <li className="pull-right"><button type="button" class="btn btn-link">Help</button></li>
              <li className="pull-right"><button type="button" class="btn btn-link">About</button></li>
              <li className="pull-right"><button type="button" class="btn btn-link">Terms</button></li>
              <li className="pull-right"><button type="button" class="btn btn-link">Privacy</button></li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
};

// For formatMessage
FrontPage.propTypes = {
  intl: intlShape.isRequired,
};

export default withRouter(injectIntl(FrontPage));
