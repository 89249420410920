import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import checkboxHOC from "react-table/lib/hoc/selectTable";
import CurrencyFormat from 'react-currency-formatter';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

import Moment from 'react-moment';
import 'moment-timezone';

import Parser from 'html-react-parser';
import 'react-notifications/lib/notifications.css';

import {
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import opay_payment from "opay_payment_nodejs";

import DateFormat from "dateformat";

import {
  makeid,
  getOrderList,
  updateOrder,
} from "../utils";

import {
  STATUS_ORDER,
  COLOR_ORDER,
} from "../constants";

//const opay_payment = require('opay_payment_nodejs');
const CheckboxTable = checkboxHOC(ReactTable);

class ListOrder extends Component {
  constructor(props) {
    super(props);
    const columns = this.getColumns();
    this.state = {
      data: [],
      columns,
      selection: [],
      selectAll: false,
      isLoading: false,
      errorShow: false,
      html: "",
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    let data = await getOrderList();
    this.buildRenderingData(data);
  }

  componentDidUpdate(prevProps, prevState) {
    const { html } = this.state;
    if (html && html !== "") {
      let form = document.getElementById("_form_aiochk");
      form.submit();
      //this.setState({ html: "" });
    }
  }

  loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  
  lookup = (callback) => {
    this.loadJSONP('http://ipinfo.io', 'sendBack');
    window.sendBack = (resp) => {
      const countryCode = (resp && resp.country) ? resp.country : '';
      callback(countryCode);
    }
  }

  validatePhone = async (status, value, countryData, number, id, row) => {
    let orders = [...this.state.data];
    let entry = orders[row.index];
    entry.shipperPhone = value;
    entry.shipperPhoneCountry = countryData.iso2.toUpperCase();
    entry.shipperPhoneValidation = status;
    this.setState({ data: orders });
    const orderId = entry.poid;
    let info = {
      shipperPhone: entry.shipperPhone,
      shipperPhoneCountry: entry.shipperPhoneCountry,
      shipperPhoneValidation: entry.shipperPhoneValidation,
    }
    //await updateOrder(orderId, info);
    return status;
  };

  buildRenderingData = (data) => {
    let resp = [];
    data.forEach(item => {
      const poid = item.poid || "";
      const contents = item.contents || "";
      const dateUpdated = item.dateUpdated || "";
      const dateCreated = item.dateCreated || "";
      const grossWeight = item.grossWeight || "";
      const chargeableWeight = item.chargeableWeight || "";
      const numOfPieces = item.numOfPieces || "";
      const hawb = item.hawb || "";
      const carrier = item.carrier || "";
      const destination = item.destination || "";
      const s_name = item.shipperName || "--";
      const s_phone = item.shipperPhone || "--";
      const s_address1 = item.shipperAddress1 || "--";
      const s_address2 = item.shipperAddress2 || " ";
      const s_city = item.shipperCity || "--";
      const s_state = item.shipperStateCode || "--";
      const s_zipCode = item.shipperPostalCode || "--";
      const s_country = item.shipperCountryCode || "--";
      const d_name = item.consigneeName || "--";
      const d_phone = item.consigneePhone || "--";
      const d_address1 = item.consigneeAddress1 || "--";
      const d_address2 = item.consigneeAddress2 || " ";
      const d_city = item.consigneeCity || "--";
      const d_state = item.consigneeStateCode || "--";
      const d_zipCode = item.consigneePostalCode || "--";
      const d_country = item.consigneeCountryCode || "--";
      let obj = {
        orderId_contents: `${poid}|${contents}`,
        dateUpdated_dateCreated: `${dateUpdated}|${dateCreated}`,
        grossWeight_chargableWeight_numOfPieces: `${grossWeight}|${chargeableWeight}|${numOfPieces}`,
        price: item.estimate,
        hawb_carrier_destination: `${hawb}|${carrier}|${destination}`,
        src_name_phone_address: `${s_name}|${s_phone}|${s_address1}|${s_address2}|${s_city}|${s_state}|${s_zipCode}|${s_country}`,
        dst_name_phone_address: `${d_name}|${d_phone}|${d_address1}|${d_address2}|${d_city}|${d_state}|${d_zipCode}|${d_country}`,
        ...item,
      }
      resp.push(obj);
    });
    this.setState({ data: resp });
  }

  // ReactTable Selection Functions
  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  select = (key) => {
    let selection = [...this.state.selection];
    const len = [...this.state.data].length;
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
    } else {
      selection.push(key);
      this.setState({ selection });
      if (len === selection.length) {
        const selectAll = true;
        this.setState({ selectAll });
      }
    }
    return selection;
  }

  deselect = (key) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex+1),
      ];
      this.setState({ selection });
      if (selection.length === 0) {
        const selectAll = false;
        this.setState({ selectAll });
      }
    } else {
    }
    return selection;
  }

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected = key => {
    return this.state.selection.includes(key);
  }

  logSelection = () => {
    console.log("Selection:", this.state.selection);
  }

  // Pay
  handleCheckoutClick = async (event, row) => {
    let entry = [...this.state.data][row.index];
    let create = new opay_payment();
    console.log("entry:", entry);
    console.log(entry.paymentParams);
    let base_param = entry.paymentParams[0];
    base_param.MerchantTradeNo = makeid(20); //請帶20碼uid, ex: f0a0d7e9fae1bb72bc93
    base_param.MerchantTradeDate = DateFormat(new Date(), "yyyy/mm/dd HH:MM:ss"); //ex: 2017/02/13 15:45:30
    let inv_param = {};

    // Update po
    entry.paymentParams.push(base_param);
    entry.paymentInvoiceParams.push(inv_param);
    entry.paymentTradeNo.push(base_param.MerchantTradeNo);
    let info = {
      paymentParams: entry.paymentParams,
      paymentInvoiceParams: entry.paymentInvoiceParams,
      paymentTradeNo: entry.paymentTradeNo,
    }
    console.log("poid:", entry.poid);
    console.log("info:", info);
    await updateOrder(entry.poid, info);

    let html = create.payment_client.aio_check_out_credit_onetime(base_param, inv_param);
    this.setState({ html });
  }

  validateCheckoutForm = (row) => {
    const entry = [...this.state.data][row.index];
    if ("paymentParams" in entry &&
        "TotalAmount" in entry.paymentParams &&
        entry.paymentParams.TotalAmount > 0) {
      return true;
    } else {
      if ("estimate" in entry &&
          entry.estimate > 0) {
        entry.paymentParams.TotalAmount = entry.estimate;
        return true;
      }
    }
    return false;
  }

  updateOrder = async (e, index, type) => {
    const orderId = [...this.state.data][index]['poid'];
    let info = {};
    info[e.target.id] = (type === "number") ? parseFloat(e.target.innerHTML) : e.target.innerHTML;
    await updateOrder(orderId, info);
  }

  // UI components
  createTextInput = (row, type, header, value, editable, color) => {
    color = color || "#000000";
    return (
      <span
        id={header}
        style={{ backgroundColor: "#fafafa", width: "100%", color: color }}
        contentEditable={editable}
        suppressContentEditableWarning="true"
        onBlur={e => {this.updateOrder(e, row.index, type)}}
        onKeyPress={e => {
          if ((type === "number") && ((e.which >= 48 && e.which <= 57) || e.which === 13)) {
            if (e.which === 13) {
              e.preventDefault();
              e.target.blur();
            } else if (e.target.innerHTML.length >= 32) {
              alert("ERROR: Too many numbers, limit = 32");
              e.preventDefault();
            }
            return true;
          } else if (type === "text") {
            if (e.which === 13) {
              e.preventDefault();
              e.target.blur();
            } else if (e.target.innerHTML.length >= 31) {
              e.preventDefault();
              alert("ERROR: Too many characters, limit = 32");
            }
          } else {
            e.preventDefault();
            return false;
          }
        }}
      >{value}</span>
    );
  }


  // Columns
  getColumns = () => {
    return [{
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}><FormattedMessage id="ord_status" /></div>
      ),
      Cell: row => (
        <div
          style={{
            padding: "5px",
          }}
        >
          <div
            style={{
              color: COLOR_ORDER[row.value.toUpperCase()],
            }}
          >
            <FormattedMessage id={`g_status_${row.value.toLowerCase()}`} />
          </div>
          {row.value.toLowerCase() === STATUS_ORDER['UNPAID']
            ? <Button
                bsStyle="success"
                bsSize="xsmall"
                style={{ margin: "5px 0px 5px 0px" }}
                disabled={!this.validateCheckoutForm(row)}
                onClick={event => this.handleCheckoutClick(event, row)}
              ><FormattedMessage id="g_checkout_again" /></Button>
            : ""
          }
        </div>
      ),
      accessor: "orderStatus",
      maxWidth: 100,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#00000" }}>
          <span><FormattedMessage id="ord_orderId" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="ord_contents" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const items = (row.value) ? row.value.split("|") : [];
        const orderId = items[0] || "--";
        const contents = items[1] || "--";
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{orderId}</span><br/>
            <span style={{ color: "#aeaeae" }}>{contents}</span>
          </div>
        );
      },
      accessor: "orderId_contents",
      width: 200,
      maxWidth: 200,
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#0365c0" }}>
          <span><FormattedMessage id="g_dateUpdated" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="g_dateCreated" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateUpdated, 10)/1000}</Moment></span><br/>
            <span style={{ color: "#aeaeae" }}><Moment unix format="YYYY-MM-DD HH:mm">{parseInt(entry.dateCreated, 10)/1000}</Moment></span>
          </div>
        );
      },
      accessor: "dateCreated",
      sortMethod: (a, b) => {
        if (parseInt(a, 10) > parseInt(b, 10)) {
          return 1;
        } else {
          return -1;
        }
      },
      width: 160,
      maxWidth: 160,
      sortable: true,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold", color: "#000000" }}>
          <span><FormattedMessage id="ord_grossChargeableWeight" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="ord_numOfPieces" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const items = (row.value) ? row.value.split("|") : [];
        const gross = items[0] || "--";
        const chargable = items[1] || "--";
        const numOfPieces = items[2] || "--";
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{gross}/{chargable}</span><br/>
            <span style={{ color: "#aeaeae" }}>{numOfPieces}</span>
          </div>
        );
      },
      style: {"whiteSpace": "unset"},
      accessor: "grossWeight_chargableWeight_numOfPieces",
			maxWidth: 200,
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="ord_price" /></span><br/>
        </div>
      ),
      Cell: (row) => {
        const price = row.value;
        if (price) {
          return (
            <div style={{ padding: "5px", "textAlign": "left" }}>
              <CurrencyFormat quantity={parseFloat(price)} currency="USD" /><br />
            </div>
          );
        } else {
          return (
            <div style={{ padding: "5px", "textAlign": "left" }}>
              --<br />
            </div>
          );
        }
      },
      maxWidth: 150,
      accessor: "price",
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="ord_hawbCarrier" /></span><br/>
          <span style={{ color: "#aeaeae", "fontWeight": "normal" }}><small><FormattedMessage id="ord_destination" /></small></span>
        </div>
      ),
      Cell: (row) => {
        const items = (row.value) ? row.value.split("|") : [];
        const hawb = items[0] || "--";
        const carrier = items[1] || "--";
        const destination = items[2] || "--";
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span>{hawb}/{carrier}</span><br/>
            <span style={{ color: "#aeaeae" }}>{destination}</span>
          </div>
        );
      },
      maxWidth: 200,
      accessor: "hawb_carrier_destination",
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="ord_senderInformation" /></span><br/>
        </div>
      ),
      Cell: (row) => {
        const entry = [...this.state.data][row.index];
        const editable = (entry.orderStatus === STATUS_ORDER.SHIPPED) ? false : true;
        const name = entry.shipperName || "--";
        const phone = entry.shipperPhone;
        const address1 = entry.shipperAddress1 || "--";
        const address2 = entry.shipperAddress2 || " ";
        const city = entry.shipperCity || "--";
        const state = entry.shipperStateCode || "--";
        const zipCode = entry.shipperPostalCode || "--";
        const country = entry.shipperCountryCode || "--";
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            {this.createTextInput(row, "text", "shipperName", name, editable)}<br/>
            {this.createTextInput(row, "text", "shipperAddress1", address1, editable, "#aeaeae")}{this.createTextInput(row, "text", "shipperAddress2", address2, editable, "#aeaeae")}, {this.createTextInput(row, "text", "shipperCity", city, editable, "#aeaeae")}<br/>
            {this.createTextInput(row, "text", "shipperStateCode", state, editable, "#aeaeae")} {this.createTextInput(row, "text", "shipperPostalCode", zipCode, editable, "#aeaeae")}, {this.createTextInput(row, "text", "shipperCountryCode", country, editable, "#aeaeae")}<br/>
            <span>
              <FormGroup controlId="phone" validationState={entry.shipperPhoneValidation ? "success" : "error"}>
                <IntlTelInput css={['intl-tel-input', 'form-control']}
                  name="phone"
                  id="phone"
                  disabled={!editable}
                  utilsScript={'libphonenumber.js'}
                  preferredCountries={['tw', 'jp', 'us', 'cn']}
                  defaultCountry={ 'auto' }
                  geoIpLookup={ this.lookup }
                  onPhoneNumberChange={(status, value, countryData, number, id) => {this.validatePhone(status, value, countryData, number, id, row)}}
                  onPhoneNumberBlur={(status, value, countryData, number, id) => {this.validatePhone(status, value, countryData, number, id, row)}}
                  style={{ width: "100%" }}
                  value={phone}
                />
                <FormControl.Feedback />
              </FormGroup>
            </span>
          </div>
        );
      },
      maxWidth: 250,
      accessor: "src_name_phone_address",
      sortable: false,
      filterable: false,
    }, {
      Header: () => (
        <div style={{ padding: "5px", "textAlign": "left", "fontWeight": "bold" }}>
          <span><FormattedMessage id="ord_hubInformation" /></span><br/>
        </div>
      ),
      Cell: (row) => {
        const items = (row.value) ? row.value.split("|") : [];
        const name = items[0] || "--";
        const phone = items[1] || "--";
        const address1 = items[2] || "--";
        const address2 = items[3] || " ";
        const city = items[4] || "--";
        const state = items[5] || "--";
        const zipCode = items[6] || "--";
        const country = items[7] || "--";
        return (
          <div style={{ padding: "5px", "textAlign": "left" }}>
            <span><strong>{name}</strong></span><br/>
            <span style={{ color: "#aeaeae" }}>{address1}{address2}, {city}</span><br/>
            <span style={{ color: "#aeaeae" }}>{state} {zipCode}, {country}</span><br/>
            <span style={{ color: "#aeaeae" }}><em>{phone}</em></span>
          </div>
        );
      },
      maxWidth: 250,
      accessor: "dst_name_phone_address",
      sortable: false,
      filterable: false,
    }];
  }

  // Main render functions
  renderTable() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, columns, selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (state, rowInfo) => {
        if (!rowInfo) {
          return {};
        }
        const selected = this.isSelected(rowInfo.original._id);
        return {
          style: {
            backgroundColor: selected ? "lightgreen" : "inherit",
          }
        };
      }
    };
    //<div dangerouslySetInnerHTML={{ __html: html }} />
    return (
      <div>
        <CheckboxTable
          ref={r => (this.checkboxTable = r)}
          data={this.props.isAuthenticated ? data : []}
          columns={columns}
          noDataText="No Data!"
          defaultPageSize={10}
          minRows={0}
          className="-striped -highlight"
          collapseOnDataChange={false}
          defaultSorted={[
            {
              id: "dateCreated",
              desc: true
            }
          ]}
          {...checkboxProps}
        />
      </div>
    );
  }

  render() {
    const { html } = this.state;
    return (
      <div>
        {this.renderTable()}
        {html && html !== ""
            ? <div>{Parser(html)}</div>
            : <div></div>
        }
      </div>
    );
  }
};

// For formatMessage
ListOrder.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ListOrder);
