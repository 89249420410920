export default {
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "track-it-shipped-transport-labels"
  },
  apiGateway: {
    REGION: "ap-northeast-1",
    URL: "https://4urjabduy6.execute-api.ap-northeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_eA2ZRQaTI",
    APP_CLIENT_ID: "4727avcm120d7ss0lubk8mv9d5",
    IDENTITY_POOL_ID: "ap-northeast-1:b6c38797-1adf-4b70-991c-816d75e0c605"
  },
  lambda: {
    REGION: "ap-northeast-1",
    ARN: "arn:aws:lambda:ap-northeast-1:733912137698:function",
  }
};
