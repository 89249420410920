import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Col,
  ControlLabel,
  FormControl,
  Panel,
} from "react-bootstrap";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import LoaderButton from "../components/LoaderButton";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

import CreditCardInput from 'react-credit-card-input';

import {
  listMemberInfo,
  updateMemberInfo,
  checkMemberInfoRequired,
} from "../utils";

class UpdatePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaveLoading: false,
      isResetLoading: false,
      memberInfo: {
        email: "",
        creditCardNumber: "",
        creditCardName: "",
        creditCardCVC: "",
        creditCardDate: "",
        creditCardPostalCode: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let memberInfo = await listMemberInfo();
      memberInfo = memberInfo[0];
      this.setState({ memberInfo });
    } catch (e) {
      console.log(e.message);
    };
  }

  loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  
  lookup = (callback) => {
    this.loadJSONP('http://ipinfo.io', 'sendBack');
    window.sendBack = (resp) => {
      const countryCode = (resp && resp.country) ? resp.country : '';
      callback(countryCode);
    }
  }

  async handleChange(event) {
    let memberInfo = this.state.memberInfo;
    memberInfo[event.target.id] = event.target.value;
    this.setState({ memberInfo });
  }

  async handleReset(event) {
    event.preventDefault();
    this.setState({ isResetLoading: true });
    let memberInfo = await listMemberInfo();
    memberInfo = memberInfo[0];
    this.setState({ memberInfo, isResetLoading: false });
  }

  async handleSave(event) {
    event.preventDefault();
    this.setState({ isSaveLoading: true });
    const memberInfo = this.state.memberInfo;
    const body = {
      creditCardNumber: memberInfo.creditCardNumber.replace(/\s/g, ''),
      creditCardExpiry: memberInfo.creditCardExpiry,
      creditCardCVC: memberInfo.creditCardCVC,
      creditCardName: memberInfo.creditCardName,
      creditCardPhone: memberInfo.creditCardPhone,
      creditCardPhoneValidation: memberInfo.creditCardPhoneValidation,
      creditCardPostalCode: memberInfo.creditCardPostalCode,
    };
    await updateMemberInfo(memberInfo.email, body);
    this.setState({ isSaveLoading: false });
  }

  handleCreditCardNumberChange = (event) => {
    let memberInfo = this.state.memberInfo;
    memberInfo.creditCardNumber = event.target.value;
    this.setState({ memberInfo });
  }

  handleCreditCardExpiryChange = (event) => {
    let memberInfo = this.state.memberInfo;
    memberInfo.creditCardExpiry = event.target.value;
    this.setState({ memberInfo });
  }

  handleCreditCardCVCChange = (event) => {
    let memberInfo = this.state.memberInfo;
    memberInfo.creditCardCVC = event.target.value;
    this.setState({ memberInfo });
  }

  validatePhone = (status, value, countryData, number, id) => {
    //console.log(status, value, countryData, number, id);
    let memberInfo = this.state.memberInfo;
    memberInfo.creditCardPhone = value;
    memberInfo.creditCardPhoneValidation = status;
    this.setState({ memberInfo });
    return status;
  };

  render() {
    const memberInfo = this.state.memberInfo;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form horizontal>
          <Col xs={8} xsOffset={2} md={8} mdOffset={2}>
          <Panel>
            <Panel.Heading><FormattedMessage id="mbr_paymentInformation" /></Panel.Heading>
            <Panel.Body>
              <FormGroup controlId="creditCard">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("creditCard")}<FormattedMessage id="mbr_creditCard" />
                </Col>
                <Col xs={8}>
                  <CreditCardInput
                    cardNumberInputProps={{ value: memberInfo.creditCardNumber, onChange: this.handleCreditCardNumberChange }}
                    cardExpiryInputProps={{ value: memberInfo.creditCardExpiry, onChange: this.handleCreditCardExpiryChange }}
                    cardCVCInputProps={{ value: memberInfo.creditCardCVC, onChange: this.handleCreditCardCVCChange }}
                    fieldClassName="input"
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="creditCardName">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("creditCardName")}<FormattedMessage id="mbr_creditCardName" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Name on the Card"
                    value={memberInfo.creditCardName}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="creditCardPostalCode">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("creditCardPostalCode")}<FormattedMessage id="mbr_creditCardPostalCode" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Postal Code"
                    value={memberInfo.creditCardPostalCode}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="phone" validationState={memberInfo.creditCardPhoneValidation ? "success" : "error"}>
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("phone")}<FormattedMessage id="mbr_creditCardPhone" />
                </Col>
                <Col xs={8}>
                  <IntlTelInput css={['intl-tel-input', 'form-control']}
                    name="phone"
                    id="phone"
                    utilsScript={'libphonenumber.js'}
                    preferredCountries={['tw', 'jp', 'us', 'cn']}
                    defaultCountry={ 'auto' }
                    geoIpLookup={ this.lookup }
                    onPhoneNumberChange={ this.validatePhone }
                    onPhoneNumberBlur={ this.validatePhone }
                    style={{ width: "100%" }}
                    value={memberInfo.creditCardPhone}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              <FormGroup controlId="generalButtons">
                <Col xsOffset={3} xs={8}>
                  {/*
                  <Button bsStyle="primary" onClick={this.handleSaveGeneral}>Save</Button>
                  <Button bsStyle="danger" onClick={this.handleResetGeneral}>Reset</Button>
                  */}
                  <LoaderButton
                    bsStyle="primary"
                    isLoading={this.state.isSaveLoading}
                    text={formatMessage({ id: "mbr_save" })}
                    loadingText={formatMessage({ id: "mbr_saving" })}
                    onClick={this.handleSave}
                  />
                  <LoaderButton
                    bsStyle="danger"
                    isLoading={this.state.isResetLoading}
                    text={formatMessage({ id: "mbr_reset" })}
                    loadingText={formatMessage({ id: "mbr_resetting" })}
                    onClick={this.handleReset}
                  />
                </Col>
              </FormGroup>
            </Panel.Body>
          </Panel>
          </Col>
        </Form>
      </div>
    );
  }
};

// For formatMessage
UpdatePayment.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(UpdatePayment);
