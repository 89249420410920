import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ErrorAlert from "../components/ErrorAlert";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

import "./Signup.css";

import { Auth } from "aws-amplify";
import config from "../config";

import createHmac from "create-hmac";

import {
  createCustomer,
} from "../utils";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phone: "",
      registeredPhone: "",
      phoneCountry: "",
      phoneValidatation: false,
      confirmPassword: "",
      confirmPasswordValidation: true,
      confirmationCode: "",
      newUser: null,
      error: "",
    };
  }

  loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  
  lookup = (callback) => {
    this.loadJSONP('http://ipinfo.io', 'sendBack');
    window.sendBack = (resp) => {
      const countryCode = (resp && resp.country) ? resp.country : '';
      callback(countryCode);
    }
  }

  validatePhone = (status, value, countryData, number, id) => {
    //console.log(status, value, countryData, number, id);
    this.setState({
      phone: value,
      registeredPhone: number.replace(/\s/g,''),
      phoneCountry: countryData.iso2.toUpperCase(),
      phoneValidation: status,
    });
    return status;
  };

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }
  
  handleChange = event => {
    let match = false;
    if (event.target.id === "confirmPassword") {
      match = event.target.value === this.state.password;
    } else if (event.target.id === "password") {
      match = event.target.value === this.state.confirmPassword;
    }
    this.setState({
      [event.target.id]: event.target.value,
      confirmPasswordValidation: match,
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          given_name: this.state.firstName,
          family_name: this.state.lastName,
          phone_number: this.state.registeredPhone,
        },
      });
      this.setState({
        newUser,
        error: "",
      });
    } catch (e) {
      //alert(e.message);
      console.log("e:", e);
      let newUser = null;
      if (e.code === "UsernameExistsException") {
        newUser = {};
      }
      this.setState({ error: e.code, newUser });
    }

    this.setState({ isLoading: false });
  }
  
  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password).then((user) => {
        const storage = user.pool.storage;
        const targetKey = `aws.cognito.identity-id.${config.cognito.REGION}`;
        let userId = "";
        let done = false;
        Object.keys(storage).forEach(async (key) => {
          if (!done && key.startsWith(targetKey)) {
            userId = storage[key];
            let hmac = createHmac('sha256', Buffer.from(this.state.email));
            hmac.update(this.state.password);
            const digest = hmac.digest();
            const output = Buffer.from(digest).toString('hex');
            // echo -n 'Passw0rd!' | openssl dgst -sha256 -hmac 'add@yahoo.com'
            let info = {
              userId,
              username: user.username,
              email: this.state.email,
              password: output,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phone: this.state.phone,
              phoneValidation: this.state.phoneValidation,
              timestamp: Date.now(),
            };
            await createCustomer(userId, info);
            done = true;
          }
        });
      }).catch((error) => {
        console.log("Error:", error);
      });
      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      //alert(e.message);
      console.log("e:", e);
      this.setState({ isLoading: false, error: e.code });
    }
  }

  renderConfirmationForm() {
    const {formatMessage} = this.props.intl;
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel><FormattedMessage id="l_confirmationCode" /></ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock><FormattedMessage id="l_signup_helpBlock" /></HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text={formatMessage({ id: "l_verify" })}
          loadingText={formatMessage({ id: "l_verifying" })}
        />
      </form>
    );
  }

  renderForm() {
    const {formatMessage} = this.props.intl;
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel><FormattedMessage id="l_email" /></ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel><FormattedMessage id="l_password" /></ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large" validationState={this.state.confirmPassword === "" ? null : this.state.confirmPasswordValidation ? "success" : "error"}>
          <ControlLabel><FormattedMessage id="l_confirmPassword" /></ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
          <FormControl.Feedback />
        </FormGroup>
        <FormGroup controlId="firstName" bsSize="large">
          <ControlLabel><FormattedMessage id="l_firstName" /></ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
          <ControlLabel><FormattedMessage id="l_lastName" /></ControlLabel>
          <FormControl
            autoFocus
            type="lastName"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="phone" bsSize="large" validationState={this.state.phone === "" ? null : this.state.phoneValidation ? "success" : "error"}>
          <ControlLabel><FormattedMessage id="l_phone" /></ControlLabel>
          {/*
          <FormControl
            autoFocus
            type="phone"
            value={this.state.phone}
            onChange={this.handleChange}
          />
          */}
          <IntlTelInput css={['intl-tel-input', 'form-control']}
            name="phone"
            id="phone"
            utilsScript={'libphonenumber.js'}
            preferredCountries={['tw', 'jp', 'us', 'cn']}
            defaultCountry={ 'auto' }
            geoIpLookup={ this.lookup }
            onPhoneNumberChange={ this.validatePhone }
            onPhoneNumberBlur={ this.validatePhone }
            style={{ width: "100%" }}
            value={this.state.phone}
          />
          <FormControl.Feedback />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text={formatMessage({ id: "l_signup" })}
          loadingText={formatMessage({ id: "l_signingUp" })}
        />
      </form>
    );
  }

  handleErrorDismiss = () => {
    this.setState({ error: "" });
  }

  render() {
    return (
      <div className="Signup">
        <ErrorAlert error={this.state.error} handleErrorDismiss={this.handleErrorDismiss} />
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
};

// For formatMessage
Signup.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Signup);
