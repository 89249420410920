export const STATISTICS_COLOR_ORDER = Object.freeze([
  "#ff6384",
  "#36a2eb",
  "#ffce56",
  "#0b9d58",
  "#846c9c",
  "#f48023",
  "#637b85",
  "#e5e5e7",
]);

export const STATUS_INVENTORY = Object.freeze({
  "INCOMPLETE": "incomplete",
  "INACTIVE": "inactive",
  "ACTIVE": "active",
});

export const STATUS_SHIPMENT = Object.freeze({
  "NEW": "new",
  "ESTIMATED": "estimated",
  "CONFIRMED": "confirmed",
  "VOIDED": "voided",
  "DOWNLOADED": "downloaded",

  "WORKING": "working",
  "SHIPPED": "shipped",
  "IN_TRANSIT": "inTransit",
  "DELIVERED": "delivered",
  "CHECKED_IN": "checkedIn",
  "RECEIVING": "receiving",
  "CLOSED": "closed",
  "CANCELLED": "cancelled",
  "DELETED": "deleted",
  "ERROR": "error",

  "UNPAID": "unpaid",
  "PAID": "paid",
});

export const STATUS_ORDER = Object.freeze({
  "UNPAID": "unpaid",
  "PAID": "paid",
  "SHIPPED": "shipped",
  "IN_TRANSIT": "inTransit",
  "DELIVERED": "delivered",
  "ERROR": "error",
});

export const COLOR_INVENTORY = Object.freeze({
  "INCOMPLETE": "#fece42",
  "INACTIVE": "#aeaeae",
  "ACTIVE": "#02c100",
});

export const COLOR_SHIPMENT = Object.freeze({
  "NEW": "red",
  "ESTIMATED": "#02c100",
  "CONFIRMED": "#02c100",
  "VOIDED": "#aeaeae",
  "DOWNLOADED": "#02c100",

  "WORKING": "#fece42",
  "SHIPPED": "#02c100",
  "IN_TRANSIT": "#02c100",
  "DELIVERED": "#0365c0",
  "CHECKED_IN": "#02c100",
  "RECEIVING": "#02c100",
  "CLOSED": "#02c100",
  "CANCELLED": "#aeaeae",
  "DELETED": "#aeaeae",
  "ERROR": "#dd4e41",

  "UNPAID": "#fece42",
  "PAID": "#02c100",
});

export const COLOR_ORDER = Object.freeze({
  "UNPAID": "#fece42",
  "PAID": "#02c100",
  "SHIPPED": "#02c100",
  "IN_TRANSIT": "#02c100",
  "DELIVERED": "#02c100",
  "ERROR": "#dd4e41",
});

