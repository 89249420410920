import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import config from "./config";

import { IntlProvider, addLocaleData } from "react-intl";
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import en_US from './locale/en_US';
import zh_TW from './locale/zh_TW';
import zh_CN from './locale/zh_CN';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [{
      name: "order",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "inventory",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "inventoryRetrieve",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "inventoryFeeEstimate",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "static",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "shipmentPlan",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "shipment",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "shipmentDeleteKey",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportQuery",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportEstimate",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "submitTransportEstimate",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportConfirm",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportVoid",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportGet",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportGenPackageLabels",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "transportGetPackageLabels",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "customer",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "statistics",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "update",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "shipmentRetrieve",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
      /*
    },{
      name: "customerQuery",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },{
      name: "productGet",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
      */
    }]
  }
});

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

addLocaleData([...en,...zh]);

export default class IndexWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: navigator.language,
      messages: this.chooseLocale()
    };
  }

  chooseLocale() {
    const token = navigator.language.toLowerCase().split('-');
    switch (token[0]) {
      case 'en':
        return en_US;
      case 'zh':
        if (token[1] === "tw") {
          return zh_TW;
        } else if (token[1] === "cn") {
          return zh_CN;
        } else {
          return zh_TW;
        }
      default:
        return en_US;
    }
  }

  setLanguage = (locale, messages) => {
    if (messages === "en_US") {
      messages = en_US;
    } else if (messages === "zh_TW") {
      messages = zh_TW;
    } else if (messages === "zh_CN") {
      messages = zh_CN;
    } else {
      messages = en_US;
    }
    this.setState({ locale, messages });
  }

  render() {
    const childProps = {
      setLanguage: this.setLanguage,
    };
    return (
      <IntlProvider
        locale={this.state.locale}
        messages={this.state.messages}>
        <Router>
          <App childProps={childProps}/>
        </Router>
      </IntlProvider>
    );
  }
};
