import React, { Component } from "react";

import './MessageBox.css';

import { Alert, Button, Glyphicon } from "react-bootstrap";
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

class MessageBox extends Component {
  // Error Handling
  render() {
    const type = (this.props.bsStyle) ? this.props.bsStyle : "danger";
    const icon = this.props.bsStyle === "success" ? "ok-sign" : this.props.bsStyle === "info" ? "info-sign" : this.props.bsStyle === "warning" ? "warning-sign" : "remove-sign";
    if (this.props.messageId !== "") {
      return (
        <div className="MessageBox">
          <Alert bsStyle={type} onDismiss={this.handleDismiss}>
            {
              <h4><Glyphicon bsSize="xsmall" glyph={icon} aria-hidden="true" />{' '}<FormattedMessage id={`g_${type}`} /></h4>
            }
            <p>
              <FormattedMessage id={this.props.messageId} />
              {this.props.linkId && this.props.linkId.length > 0
                ? <Button className="pull-right" bsStyle={type} bsSize="xsmall" onClick={this.handleOnClick}><FormattedMessage id={this.props.linkId} /></Button>
                : ""
              }
            </p>
          </Alert>
        </div>
      );
    } else {
      return "";
    }
  }

  handleDismiss = () => {
    if (this.props.handleDismiss) {
      this.props.handleDismiss(this.props.messageId);
    }
  }

  handleOnClick = () => {
    if (this.props.linkOnClick) {
      this.props.linkOnClick(this.props.linkId, this.props.messageId);
    }
  }
};

// For formatMessage
MessageBox.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(MessageBox);
