var _classCallCheck = require("/Users/john/dev/track-it-shipped/tis-app-web/node_modules/@babel/runtime/helpers/classCallCheck");

/**
 * Created by ying.wu on 2017/6/9.
 */
var version = require('./opay_payment/version.js');

var payment_client = require('./opay_payment/payment_client.js');

var query_client = require('./opay_payment/query_client.js');

var exec_grant_refund = require('./opay_payment/exec_grant_refund.js');

var OPayPayment = function OPayPayment() {
  "use strict";

  _classCallCheck(this, OPayPayment);

  this.version = new version();
  this.payment_client = new payment_client();
  this.query_client = new query_client();
  this.exec_grant_refund = new exec_grant_refund();
};

module.exports = OPayPayment;