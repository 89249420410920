import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "./Login.css";

import LoaderButton from "../components/LoaderButton";
import ErrorAlert from "../components/ErrorAlert";

import { Auth } from "aws-amplify";

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      error: "",
    };
  }

  validateForm() {
    return this.state.email.length > 0 &&
      this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(this.state.email, this.state.password);
      /*
      await Auth.signIn(this.state.email, this.state.password).then((user) => {
        const storage = user.pool.storage;
        const targetKey = `aws.cognito.identity-id.${config.cognito.REGION}`;
        let userId = "";
        Object.keys(storage).forEach(async (key) => {
          if (key.startsWith(targetKey)) {
            userId = storage[key];
            let hmac = createHmac('sha256', Buffer.from(this.state.email));
            hmac.update(this.state.password);
            const digest = hmac.digest();
            const output = Buffer.from(digest).toString('hex');
            // echo -n 'Passw0rd!' | openssl dgst -sha256 -hmac 'add@yahoo.com'
            let info = {
              userId,
              email: this.state.email,
              password: output,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phone: this.state.phone,
            };
            await createCustomer(userId, info);
          }
        });
      }).catch((error) => {
        console.log("Error:", error);
      });
      */
      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      //alert(e.message);
      console.log("e:", e);
      this.setState({ isLoading: false, error: e.code });
    }
  }

  handleErrorDismiss = () => {
    this.setState({ error: "" });
  }

  render() {
    const {formatMessage} = this.props.intl;
    return (
      <div className="Login">
        <ErrorAlert error={this.state.error} handleErrorDismiss={this.handleErrorDismiss} />
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel><FormattedMessage id="l_email" /></ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password"  bsSize="large">
            <ControlLabel><FormattedMessage id="l_password" /></ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text={formatMessage({ id: "l_login" })}
            loadingText={formatMessage({ id: "l_loggingIn" })}
          />
        </form>
      </div>
    );
  }
};

// For formatMessage
Login.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Login);
