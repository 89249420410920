var _classCallCheck = require("/Users/john/dev/track-it-shipped/tis-app-web/node_modules/@babel/runtime/helpers/classCallCheck");

/**
 * Created by ying.wu on 2017/6/12.
 */
var OPayPaymentVersion = function OPayPaymentVersion() {
  "use strict";

  _classCallCheck(this, OPayPaymentVersion);

  this.version = '1.0.2';
};

module.exports = OPayPaymentVersion;