import React, { Component } from "react";
import "./Home.css";

import MessageBox from "../components/MessageBox";
import FrontPage from "../containers/FrontPage";
import ErrorModal from "../components/ErrorModal";

import CircularProgressbar from 'react-circular-progressbar';

import CurrencyFormat from 'react-currency-formatter';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {Doughnut, Line, Bar, HorizontalBar} from 'react-chartjs-2';

import DateFormat from "dateformat";

import * as moment from "moment";

import _ from "lodash";

import {
  listMemberInfo,
  checkMemberInfoCompleted,
  getInventoryList,
  getStatisticsData,
  keyValueSwap,
} from "../utils";

import {
  STATUS_INVENTORY,
  STATUS_SHIPMENT,
  STATISTICS_COLOR_ORDER,
} from "../constants";

import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  Radio,
  Grid,
  Row,
  Col,
  Panel,
  InputGroup,
  ControlLabel,
  FormControl,
  Label,
  ListGroup,
  ListGroupItem,
  Tooltip,
  ProgressBar,
  OverlayTrigger,
  Glyphicon,
  Popover,
  Image,
} from "react-bootstrap";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      messageId: "",
      memberInfo: {},
      memberUpdated: {},
      statistics: {
        inventoryStatus: {
          calculating: 0,
        },
        shipmentStatus: {
          calculating: 0,
        },
        quantity: {
          quantityReceived: 0,
          quantityShipped: 0,
        },
        quantityByMonth: {
          calculating: {
            quantityReceived: 0,
            quantityShipped: 0,
          },
        },
      },
      STATUS_INVENTORY_REV: keyValueSwap(STATUS_INVENTORY),
      STATUS_SHIPMENT_REV: keyValueSwap(STATUS_SHIPMENT),
      errorShow: false,
    };

    this.handleErrorDismiss = this.handleErrorDismiss.bind(this);
    this.handleErrorShow = this.handleErrorShow.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let memberInfo = await listMemberInfo();
      memberInfo = memberInfo[0];
      
      let inventory = await getInventoryList();

      const statistics = await getStatisticsData();

      const res = checkMemberInfoCompleted(memberInfo);
      this.setState({ memberInfo, memberUpdated: res, isAuthenticating: false, inventory, statistics });
      return;
    } catch (e) {
      console.log(e);
      this.handleErrorShow(e.message);
    };
    this.setState({ isAuthenticating: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    //const statistics = await getStatisticsData();
    //console.log("statistics:", statistics);
    //this.setState({ statistics });
  }

  handleMessageBoxDismiss = (messageId) => {
    let { memberUpdated } = this.state;
    if (messageId in memberUpdated) {
      delete memberUpdated[messageId];
    }
    this.setState({ memberUpdated });
  }

  linkOnClick = (linkId, messageId) => {
    const lnk = {
      'app_updateGeneralInfo': "/member/update",
      'app_updateMwsInfo': "/member/authToken",
      'app_udpatePaymentInfo': "/member/payment",
    };
    this.props.history.push(lnk[messageId]);
  }

  // Error
  handleErrorDismiss = () => {
    this.setState({ errorShow: false, error: {} });
  }

  handleErrorShow = (error) => {
    this.setState({ errorShow: true, error });
  }


  // Render
  renderDashboard = () => {
    let output = [];
    output.push(
      <Grid id="dashboard" className="Home-Grid">
        <Row>
          <Col>{this.renderErrorAlert()}</Col>
        </Row>
        {this.renderStatusBar()}
        <Row className="Home-Row">
          <Col sm={12} className="Home-Col"><hr/></Col>
        </Row>
        {this.renderQuantityChart()}
      </Grid>
    );
    return output;
  }

  renderErrorAlert() {
    if (this.state.errorShow) {
      return (
        <ErrorModal
          error={this.state.error}
          handleErrorDismiss={this.handleErrorDismiss}
        />
      );
    }
  }

  renderQuantityChart = () => {
    const { formatMessage } = this.props.intl;
    const stat = this.state.statistics;
    let labels = [];
    _.keys(stat.quantity).forEach(item => {
      labels.push(formatMessage({id:`hm_label_${item}`}));
    });
    let values = _.values(stat.quantity);
    console.log("stat:", stat);
    console.log("labels:", labels);
    console.log("values:", _.values(stat.quantity));
    const quantityData = {
      labels: [formatMessage({id:'hm_label_quantity'})],
      datasets: [
        {
          label: [labels[1]],
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [values[1]],
        },{
          label: [labels[0]],
          backgroundColor: 'rgba(11,157,88,0.2)',
          borderColor: 'rgba(11,157,88,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(11,157,88,0.4)',
          hoverBorderColor: 'rgba(11,157,88,1)',
          data: [values[0]]
        }
      ]
    };
    /* blue color
    const quantityData = {
      labels: labels,
      datasets: [
        {
          label: labels,
          backgroundColor: 'rgba(54,162,235,0.2)',
          borderColor: 'rgba(54,162,235,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(54,162,235,0.4)',
          hoverBorderColor: 'rgba(54,162,235,1)',
          data: values,
        }
      ]
    };
    */
    
    labels = _.keys(stat.quantityByMonth);
    let quantityShipped = [];
    let quantityReceived = [];
    _.keys(stat.quantityByMonth).forEach(key => {
      quantityShipped.push(stat.quantityByMonth[key].quantityShipped);
      quantityReceived.push(stat.quantityByMonth[key].quantityReceived);
    });

    // patching months
    const latest = moment(labels[labels.length-1], "YYYY-MM");
    if (labels.length < 12) {
      let len = 12 - labels.length;
      let pos = labels.length;
      for (let i = 1; i <= len; i++) {
        //labels.unshift(latest.add(1, 'months').format("YYYY-MM"));
        labels.splice(pos + i - 1, 0, latest.add(1, 'months').format("YYYY-MM"));
        //quantityShipped.unshift(0);
        //quantityReceived.unshift(0);
        quantityShipped.splice(pos + i - 1, 0, 0);
        quantityReceived.splice(pos + i - 1, 0, 0);
      };
    } else {
      labels = labels.slice(0, 12);
      quantityShipped = quantityShipped.slice(0, 12);
      quantityReceived = quantityReceived.slice(0, 12);
    }
    const quantityByMonthData = {
      labels: labels,
      datasets: [
        {
          label: formatMessage({id:'hm_label_quantityShipped'}),
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: quantityShipped,
        },{
          label: formatMessage({id:'hm_label_quantityReceived'}),
          backgroundColor: 'rgba(11,157,88,0.2)',
          borderColor: 'rgba(11,157,88,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(11,157,88,0.4)',
          hoverBorderColor: 'rgba(11,157,88,1)',
          data: quantityReceived,
        }
      ]
    };
    let output = [];
    output.push(
      <Row id="statusbar" className="Home-Row">
        <Col sm={4} className="Home-Col" style={{height:"300px"}}>
          <Bar
            data={quantityData}
            width={100}
            height={50}
            options={{
              maintainAspectRatio: false
            }}
          />
          <h2><FormattedMessage id="hm_label_quantity" /></h2>
        </Col>
        <Col sm={8} className="Home-Col" style={{height:"300px"}}>
          <Bar
            data={quantityByMonthData}
            width={100}
            height={50}
            options={{
              maintainAspectRatio: false
            }}
          />
          <h2><FormattedMessage id="hm_label_quantityByMonth" /></h2>
        </Col>
      </Row>
    );
    return output;
  }

  renderStatusBar = () => {
    const { formatMessage } = this.props.intl;
    const stat = this.state.statistics;
    let labels = [];
    _.keys(stat.inventoryStatus).forEach(item => {
      labels.push(formatMessage({id:`g_status_${item}`}));
    });
    const inventoryStat = {
      labels: labels,
    	datasets: [{
    		data: _.values(stat.inventoryStatus),
    		backgroundColor: STATISTICS_COLOR_ORDER.slice(0, stat.inventoryStatus.length),
    		hoverBackgroundColor: STATISTICS_COLOR_ORDER.slice(0, stat.inventoryStatus.length),
    	}]
    };
    labels = [];
    _.keys(stat.shipmentStatus).forEach(item => {
      labels.push(formatMessage({id:`g_status_${item}`}));
    });
    const shipmentStat = {
      labels: labels,
    	datasets: [{
    		data: _.values(stat.shipmentStatus),
    		backgroundColor: STATISTICS_COLOR_ORDER.slice(0, stat.shipmentStatus.length),
    		hoverBackgroundColor: STATISTICS_COLOR_ORDER.slice(0, stat.shipmentStatus.length),
    	}]
    };
    let output = [];
    output.push(
      <Row id="statusbar" className="Home-Row">
        <Col sm={6} className="Home-Col">
          <Doughnut data={inventoryStat} />
          <h2><FormattedMessage id="hm_label_inventoryStatistics" /></h2>
        </Col>
        <Col sm={6} className="Home-Col">
          <Doughnut data={shipmentStat} />
          <h2><FormattedMessage id="hm_label_shipmentStatistics" /></h2>
        </Col>
      </Row>
    );
    return output;
  }

  render() {
    let out = [];
    let { memberUpdated } = this.state;
    Object.keys(memberUpdated).forEach((key) => {
      const value = memberUpdated[key];
      if (!value) {
        out.push(<MessageBox key={key} bsStyle="info" handleDismiss={this.handleMessageBoxDismiss} messageId={key} linkId="hm_fixItNow" linkOnClick={this.linkOnClick} />);
      }
    });
    return (
      <div className="Home">
        {this.props.isAuthenticated ? out : ""}
        {this.props.isAuthenticated
          ? <div className="lander">
              {this.renderDashboard()}
            </div>
          : <FrontPage />
        }
      </div>
    );
  }
};

// For formatMessage
Home.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Home, {withRef: true});
