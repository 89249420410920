import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Col,
  ControlLabel,
  FormControl,
  Panel,
} from "react-bootstrap";

import LoaderButton from "../components/LoaderButton";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import {
  listMemberInfo,
  updateMemberInfo,
  checkMemberInfoRequired,
} from "../utils";

class UpdateMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaveGeneralLoading: false,
      isResetGeneralLoading: false,
      isSaveCompanyLoading: false,
      isResetCompanyLoading: false,
      country: "",
      region: "",
      memberInfo: {
        email: "",
        backupEmail: "",
        password: "",
        lineId: "",
        wechatId: "",
        company: "",
        companyIdNumber: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        stateCode: "",
        postalCode: "",
        countryCode: "",
      },
    };
    this.handleResetGeneral = this.handleResetGeneral.bind(this);
    this.handleSaveGeneral = this.handleSaveGeneral.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResetCompany = this.handleResetCompany.bind(this);
    this.handleSaveCompany = this.handleSaveCompany.bind(this);
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let memberInfo = await listMemberInfo();
      memberInfo = memberInfo[0];
      this.setState({ memberInfo });
    } catch (e) {
      console.log(e.message);
    };
  }

  loadJSONP = (url, callback) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = `${url + (url.indexOf('?') + 1 ? '&' : '?')}callback=${callback}`;
    ref.parentNode.insertBefore(script, ref);
    script.onload = () => {
      script.remove();
    };
  };
  
  lookup = (callback) => {
    this.loadJSONP('http://ipinfo.io', 'sendBack');
    window.sendBack = (resp) => {
      const countryCode = (resp && resp.country) ? resp.country : '';
      callback(countryCode);
    }
  }

  validateCell = (status, value, countryData, number, id) => {
    //console.log(status, value, countryData, number, id);
    let memberInfo = this.state.memberInfo;
    memberInfo.cell = value;
    memberInfo.cellCountry = countryData.iso2.toUpperCase();
    memberInfo.cellValidation = status;
    this.setState({ memberInfo });
    return status;
  };

  validatePhone = (status, value, countryData, number, id) => {
    //console.log(status, value, countryData, number, id);
    let memberInfo = this.state.memberInfo;
    memberInfo.phone = value;
    memberInfo.phoneCountry = countryData.iso2.toUpperCase();
    memberInfo.phoneValidation = status;
    this.setState({ memberInfo });
    return status;
  };

  validateFax = (status, value, countryData, number, id) => {
    //console.log(status, value, countryData, number, id);
    let memberInfo = this.state.memberInfo;
    memberInfo.fax = value;
    memberInfo.faxCountry = countryData.iso2.toUpperCase();
    memberInfo.faxValidation = status;
    this.setState({ memberInfo });
    return status;
  };

  async handleChange(event) {
    let memberInfo = this.state.memberInfo;
    memberInfo[event.target.id] = event.target.value;
    this.setState({ memberInfo });
  }

  async handleResetGeneral(event) {
    event.preventDefault();
    this.setState({ isResetGeneralLoading: true });
    let memberInfo = await listMemberInfo();
    memberInfo = memberInfo[0];
    this.setState({ memberInfo, isResetGeneralLoading: false });
  }

  async handleSaveGeneral(event) {
    event.preventDefault();
    this.setState({ isSaveGeneralLoading: true });
    const memberInfo = this.state.memberInfo;
    //password: memberInfo.password,
    const body = {
      backupEmail: memberInfo.backupEmail,
      lineId: memberInfo.lineId,
      wechatId: memberInfo.wechatId,
    };
    await updateMemberInfo(memberInfo.email, body);
    this.setState({ isSaveGeneralLoading: false });
  }

  async handleResetCompany(event) {
    event.preventDefault();
    this.setState({ isResetCompanyLoading: true });
    let memberInfo = await listMemberInfo();
    memberInfo = memberInfo[0];
    this.setState({ memberInfo, isResetCompanyLoading: false });
  }

  async handleSaveCompany(event) {
    event.preventDefault();
    this.setState({ isSaveCompanyLoading: true });
    const memberInfo = this.state.memberInfo;
    //password: memberInfo.password,
    const body = {
      company: memberInfo.company,
      companyIdNumber: memberInfo.companyIdNumber,
      firstName: memberInfo.firstName,
      lastName: memberInfo.lastName,
      cell: memberInfo.cell,
      phone: memberInfo.phone,
      fax: memberInfo.fax,
      cellValidation: memberInfo.cellValidation,
      phoneValidation: memberInfo.phoneValidation,
      faxValidation: memberInfo.faxValidation,
      address1: memberInfo.address1,
      address2: memberInfo.address2,
      city: memberInfo.city,
      stateCode: memberInfo.stateCode,
      postalCode: memberInfo.postalCode,
      countryCode: memberInfo.countryCode,
    };
    await updateMemberInfo(memberInfo.email, body);
    this.setState({ isSaveCompanyLoading: false });
  }

  render() {
    const memberInfo = this.state.memberInfo;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form horizontal>
          {/*<Col xs={8} xsOffset={2} md={8} mdOffset={2}>*/}
          <Col xs={6}>
          <Panel>
            <Panel.Heading><FormattedMessage id="mbr_generalInformation" /></Panel.Heading>
            <Panel.Body>
              <FormGroup controlId="email">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("email")}<FormattedMessage id="mbr_email" />
                </Col>
                <Col xs={8}>
                  <FormControl type="email" placeholder="Email"
                    value={memberInfo.email}
                    disabled
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="backupEmail">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("backupEmail")}<FormattedMessage id="mbr_backupEmail" />
                </Col>
                <Col xs={8}>
                  <FormControl type="email" placeholder="Backup Email"
                    value={memberInfo.backupEmail}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="password">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("password")}<FormattedMessage id="mbr_password" />
                </Col>
                <Col xs={8}>
                  <FormControl type="password" placeholder="Password" />
                </Col>
              </FormGroup>
              <FormGroup controlId="lineId">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("lineId")}<FormattedMessage id="mbr_lineId" />
                </Col>
                <Col xs={8}>
                  <FormControl type="email" placeholder="Line ID"
                    value={memberInfo.lineId}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="wechatId">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("wechatId")}<FormattedMessage id="mbr_wechatId" />
                </Col>
                <Col xs={8}>
                  <FormControl type="email" placeholder="WeChat ID"
                    value={memberInfo.wechatId}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="generalButtons">
                <Col xsOffset={3} xs={8}>
                  {/*
                  <Button bsStyle="primary" onClick={this.handleSaveGeneral}>Save</Button>
                  <Button bsStyle="danger" onClick={this.handleResetGeneral}>Reset</Button>
                  */}
                  <LoaderButton
                    bsStyle="primary"
                    isLoading={this.state.isSaveGeneralLoading}
                    text={formatMessage({ id: "mbr_save" })}
                    loadingText={formatMessage({ id: "mbr_saving" })}
                    onClick={this.handleSaveGeneral}
                  />
                  <LoaderButton
                    bsStyle="danger"
                    isLoading={this.state.isResetGeneralLoading}
                    text={formatMessage({ id: "mbr_reset" })}
                    loadingText={formatMessage({ id: "mbr_resetting" })}
                    onClick={this.handleResetGeneral}
                  />
                </Col>
              </FormGroup>
            </Panel.Body>
          </Panel>
          </Col>
          <Col xs={6}>
          <Panel>
            <Panel.Heading><FormattedMessage id="mbr_companyInformation" /></Panel.Heading>
            <Panel.Body>
              <FormGroup controlId="company">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("company")}<FormattedMessage id="mbr_company" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Company Name" value={memberInfo.company}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="companyIdNumber">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("companyIdNumber")}<FormattedMessage id="mbr_companyIdNumber" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Company Name" value={memberInfo.companyIdNumber}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="firstName">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("firstName")}<FormattedMessage id="mbr_firstName" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="First Name of Contact" value={memberInfo.firstName}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="lastName">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("lastName")}<FormattedMessage id="mbr_lastName" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Last Name of Contact" value={memberInfo.lastName}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="cell" validationState={(!("cell" in memberInfo) || memberInfo.cell === "") ? null : memberInfo.cellValidation ? "success" : "error"}>
                <Col componentClass={ControlLabel} xs={3}>
                  <FormattedMessage id="mbr_cell" />
                </Col>
                <Col xs={8}>
                  <IntlTelInput css={['intl-tel-input', 'form-control']}
                    utilsScript={'libphonenumber.js'}
                    preferredCountries={['tw', 'jp', 'us', 'cn']}
                    defaultCountry={ 'auto' }
                    geoIpLookup={ this.lookup }
                    onPhoneNumberChange={ this.validateCell }
                    onPhoneNumberBlur={ this.validateCell }
                    style={{ width: "100%" }}
                    value={memberInfo.cell}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              <FormGroup controlId="phone" validationState={(!("phone" in memberInfo) || memberInfo.phone === "") ? null : memberInfo.phoneValidation ? "success" : "error"}>
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("phone")}<FormattedMessage id="mbr_phone" />
                </Col>
                <Col xs={8}>
                  <IntlTelInput css={['intl-tel-input', 'form-control']}
                    utilsScript={'libphonenumber.js'}
                    preferredCountries={['tw', 'jp', 'us', 'cn']}
                    defaultCountry={ 'auto' }
                    geoIpLookup={ this.lookup }
                    onPhoneNumberChange={ this.validatePhone }
                    onPhoneNumberBlur={ this.validatePhone }
                    style={{ width: "100%" }}
                    value={memberInfo.phone}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              <FormGroup controlId="fax" validationState={(!("fax" in memberInfo) || memberInfo.fax === "") ? null : memberInfo.faxValidation ? "success" : "error"}>
                <Col componentClass={ControlLabel} xs={3}>
                  <FormattedMessage id="mbr_fax" />
                </Col>
                <Col xs={8}>
                  <IntlTelInput css={['intl-tel-input', 'form-control']}
                    utilsScript={'libphonenumber.js'}
                    preferredCountries={['tw', 'jp', 'us', 'cn']}
                    defaultCountry={ 'auto' }
                    geoIpLookup={ this.lookup }
                    onPhoneNumberChange={ this.validateFax }
                    onPhoneNumberBlur={ this.validateFax }
                    style={{ width: "100%" }}
                    value={memberInfo.fax}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>
              <FormGroup controlId="address1">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("address1")}<FormattedMessage id="mbr_address" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Address Line 1" value={memberInfo.address1}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="address2">
                <Col componentClass={ControlLabel} xs={3}>
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Address Line 2" value={memberInfo.address2}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="city">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("city")}<FormattedMessage id="mbr_city" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="City Name" value={memberInfo.city}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="state">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("stateCode")}<FormattedMessage id="mbr_stateCode" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="State / Province" value={memberInfo.stateCode}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="zipCode">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("postalCode")}<FormattedMessage id="mbr_zipCode" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Zip Code" value={memberInfo.postalCode}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="country">
                <Col componentClass={ControlLabel} xs={3}>
                  {checkMemberInfoRequired("countryCode")}<FormattedMessage id="mbr_countryCode" />
                </Col>
                <Col xs={8}>
                  <FormControl type="text" placeholder="Country" value={memberInfo.countryCode}
                    onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="companyButtons">
                <Col xsOffset={3} xs={8}>
                  {/*
                  <Button bsStyle="primary" onClick={this.handleSaveGeneral}>Save</Button>
                  <Button bsStyle="danger" onClick={this.handleResetGeneral}>Reset</Button>
                  */}
                  <LoaderButton
                    bsStyle="primary"
                    isLoading={this.state.isSaveCompanyLoading}
                    text={formatMessage({ id: "mbr_save" })}
                    loadingText={formatMessage({ id: "mbr_saving" })}
                    onClick={this.handleSaveCompany}
                  />
                  <LoaderButton
                    bsStyle="danger"
                    isLoading={this.state.isResetCompanyLoading}
                    text={formatMessage({ id: "mbr_reset" })}
                    loadingText={formatMessage({ id: "mbr_resetting" })}
                    onClick={this.handleResetCompany}
                  />
                </Col>
              </FormGroup>
            </Panel.Body>
          </Panel>
          </Col>
        </Form>
      </div>
    );
  }
};

// For formatMessage
UpdateMember.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(UpdateMember);
