import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ListInventory from "./containers/ListInventory";
import ListShipment from "./containers/ListShipment";
import ShipmentInvoice from "./containers/ShipmentInvoice";
import ListOrder from "./containers/ListOrder";
import UpdateMember from "./containers/UpdateMember";
import UpdateAuthToken from "./containers/UpdateAuthToken";
import UpdatePayment from "./containers/UpdatePayment";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/inventory/list" exact component={ListInventory} props={childProps} />
    <AppliedRoute path="/shipment/list" exact component={ListShipment} props={childProps} />
    <AppliedRoute path="/shipment/invoice" exact component={ShipmentInvoice} props={childProps} />
    <AppliedRoute path="/order/list" exact component={ListOrder} props={childProps} />
    <AppliedRoute path="/member/update" exact component={UpdateMember} props={childProps} />
    <AppliedRoute path="/member/authToken" exact component={UpdateAuthToken} props={childProps} />
    <AppliedRoute path="/member/payment" exact component={UpdatePayment} props={childProps} />
    { /* Finally, catch all unmatched routes. */ }
    <Route component={NotFound} />
  </Switch>
