import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Button,
  Glyphicon,
  Alert,
} from "react-bootstrap";

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.handleCreateFixIssue = this.handleCreateFixIssue.bind(this);
  }

  handleCreateFixIssue() {
    if (this.props.error.message.includes("Invalid seller id")) {
      this.props.history.push("/member/authToken");
    }
  }

  createFixIssue(error) {
    if (this.props.error.message.includes("Invalid seller id")) {
      return (
        <Button style={{ margin: "0px 10px 0px 10px" }} className="pull-right" bsStyle="danger" bsSize="xsmall" onClick={this.handleCreateFixIssue}>
          <Glyphicon glyph="wrench" />{' '}Fix it!
        </Button>
      );
    }
  }

  render() {
    return (
      <Alert bsStyle="danger">
        <span style={{ padding: "0px 5px 0px 10px" }}><Glyphicon glyph="exclamation-sign" /></span>
        <span style={{ padding: "0px 10px 0px 5px" }}>
        {this.props.error.message}
        </span>
        {this.props.error.code
        ? <span style={{ padding: "0px 5px 0px 10px" }}>
            <small>({this.props.error.code})</small>
          </span>
        : <span></span>
        }
        <Button className="pull-right" bsStyle="danger" bsSize="xsmall" onClick={this.props.handleErrorDismiss}>
          <Glyphicon glyph="remove" />
        </Button>
        {this.createFixIssue()}
      </Alert>
    );
  }
};

export default withRouter(ErrorModal);
